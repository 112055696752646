@import "../setup/settings";
@import "webFormRequest";

.page[data-action="Search-Show"] {
    .footer-links {
        @include breakpoint (large up) {
            padding: rem-calc(0 40);
        }
    }
    .footer .grid-container {
        margin: 0;
        width: 100%;
        padding: 0;
        .footer-contacts {
            @include breakpoint (large up) {
                padding: rem-calc(0 40);
            }
        }
    }
}
.footer {
    @include breakpoint(medium up) {
        z-index: 1;
    }
    background-color: $white;
    position: relative;
    margin-top: rem-calc(48);
    & .chat-container {
        cursor: pointer;
    }
    .footer-contacts {
        @include breakpoint (large up) {
            gap: rem-calc(64);
        }
    }

    .clz-lover,
    .clz-gift,
    .clz-green{
        .clz-call{
            img{
                transform: translateX(rem-calc(0));
                transition: 250ms linear;
            }
        }
        &:hover{
            .clz-call{
                img{
                    transform: translateX(rem-calc(8));
                    transition: 250ms linear;
                }
            }
        }
    }

    &-links{
        .new-footer-link-footer{
            &:hover {
                text-decoration: underline;
            }
        }
    }

    & .close-button {
        position: absolute;
        top: rem-calc(10);
        right: rem-calc(10);
        font-size: 2rem;
    }

    & .languageChooserMobileTrim{
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(80px);
        position: fixed;
        z-index: 999999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-overlay {
        width: 100%;
        left: 0;
        padding-top: rem-calc(9);
        &.country-overlay {
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(5px);
            position: fixed;
            z-index: 999999;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: unset !important;
            padding: 0;

            @include breakpoint (medium down) {
                background-color: transparent;
                backdrop-filter: unset;
                height: unset;
                top: unset;
            }
            .footer-overlay-container {
                padding-left: rem-calc(40);
                width: 90%;
                height: 90%;
                max-width: 90%;
                max-height: 90%;
                background-color: #FFFFFF;
                margin: auto;
                @include breakpoint (large down){
                    padding: 0;
                }
                @include breakpoint(medium down) {
                    width: 100vw;
                    height: 100vh;
                    max-width: 100%;
                    max-height: 100%;
                    padding: 0;
                }
            }
        }

        &-container {
            @include breakpoint(medium down) {
                padding: rem-calc(22 0);
                padding-bottom: rem-calc(0);
                padding-right: rem-calc(0);
            }
            .arrowIcon svg {
                transform: rotate(180deg);
            }
            .box-overlay-countries-world,
            .box-overlay-countries {
                border-bottom: 1px solid #F2EDED;
                background-color: $white;
                padding-top: rem-calc(36);
                width: 71.2%;
                overflow: auto;
                height: calc(100% - #{rem-calc(40)});
                padding-bottom: rem-calc(38);
                @include breakpoint(large down) {
                    width: 71.5%;
                    padding-left: rem-calc(32);
                }
                @include breakpoint(medium down) {
                    padding: 0;
                    width: 100%;
                    border-top: 0;
                    border-right: 0;
                }

            }
            .box-overlay-countries {
                border-bottom: none;
                padding-bottom: rem-calc(40);
                overflow: unset;
            }
            .box-overlay-countries-world {
                width: 100%;
            }
            .title-overlay-choose-country {
                position: relative;
                @include breakpoint(large up) {
                    margin-right: rem-calc(50);
                }
                @include breakpoint(medium down) {
                    font-size: rem-calc(20);
                    line-height: rem-calc(26);
                    padding: rem-calc(24 16 0);
                    width: 100%;
                }
                h4 {
                    font-family: $body-font-family;
                    font-weight: 600;
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                    margin-bottom: rem-calc(36);
                    @include breakpoint (small down){
                        margin-bottom: rem-calc(8);
                        width: 90%;
                    }
                }
                h6 {
                    font-family: $body-font-family;
                    font-weight: 600;
                    font-size: rem-calc(16);
                    line-height: rem-calc(22);
                    margin-bottom: rem-calc(35);
                    @include breakpoint (medium down) {
                        margin-bottom: rem-calc(24);

                    }
                }
            }
            .online-store-container{
                // margin-top: rem-calc(-50);
                @include breakpoint (medium down) {
                    margin-top: 0;
                    text-align: center;
                }
                .online-store-element {
                    color: rgba(0, 0, 0, 0.7);
                    display: flex;
                    align-items: center;
                    gap: rem-calc(4);
                    @include breakpoint (small down){
                        padding: rem-calc(0 16);
                    }
                    svg {
                        opacity: 0.7;
                    }
                    &_text{
                        font-size: rem-calc(10);
                        line-height: rem-calc(18);
                        font-weight: 400;
                    }
                }
            }
        }
        .footer-inner-content-privacy {
            font-size: rem-calc(14);
            letter-spacing: rem-calc(0.53);

            .keep-touch-title{
                font-family: $secondary-font;
                padding-bottom: rem-calc(8);
                font-size: rem-calc(16);
                line-height: rem-calc(22);
                font-weight: 600;
            }

            .keep-touch-text {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                letter-spacing: rem-calc(0.5);
                font-weight: 400;
                .fontLittle{
                    font-weight: 400;
                }
            }

            .cc-keep-touch-description{
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                font-weight: 400;
            }

            #submitNewsletter {
                background-color: transparent;
                width: rem-calc(48);
                height: rem-calc(48);
                top: rem-calc(4);
            }
            #newsletter-Email {
                outline: none !important;
            }
        }

        & .footer-inner-content {
            margin-top: 0;

            ul {
                @include breakpoint(large up) {
                    column-count: 4;
                    padding: rem-calc(0 25);
                    &:last-child {
                        border-right: 0;
                        padding-right: 0;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                }
                @include breakpoint(medium down) {
                    &:last-child {
                        li {
                            &:last-child {
                                .country-container-internal, .language-container-internal {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            @include breakpoint(large up) {
                margin-right: rem-calc(50);
            }
            @include breakpoint(large down) {
                margin-right: rem-calc(32);
            }
            @include breakpoint(small down) {
                margin-top: rem-calc(32);
                margin-right: 0;
            }
            .country-container-internal{
                display: flex;
                align-items: center;
                gap: rem-calc(8);
                min-height: rem-calc(24);
                padding: rem-calc(5 0);
                &:hover{
                    background-color: #F8F8F8;
                }
                .icon{
                    width: rem-calc(24);
                }
                .cc-selected-store-icon{
                    svg{
                        width: rem-calc(14);
                        margin: rem-calc(0 4 0 6)
                    }
                }
            }
        }

        .online-store-container {
            padding-top: rem-calc(49);
            @include breakpoint (small down){
                padding-top: 0;
                width: 100%;
                padding-bottom: rem-calc(24);
            }
        }

        & .keep-in-touch-panel {
            background-color: $neutral;
            padding: rem-calc(40 40 0);
            margin-top: rem-calc(100);
            @include breakpoint (small down){
                padding: rem-calc(56 16 110 16);
                margin-top: 0;
            }
        }

        & .footer-input-group {
            margin-top: rem-calc(32);
            margin-bottom: rem-calc(35);
            &-label{
                max-width: calc(100% - #{rem-calc(48)});
                top: calc(100% - #{rem-calc(36)});
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        & .lang-selector {
            @include breakpoint(medium down) {
                width: 100vw;
                padding: rem-calc(24 16 0);
                top: rem-calc(62);
                height: 100vh;
                background-color: white;
            }
            @include breakpoint(large up) {
                border: rem-calc(1) solid #000000;
            }
            h4 {
                font-weight: 600;
                font-size: rem-calc(20);
                line-height: rem-calc(26);
                margin-bottom: rem-calc(24);
            }
            & .country-selector li {
                padding: rem-calc(8 0 8 16);
                @include breakpoint (medium down) {
                    padding: rem-calc(8 0 8 8);
                }
            }
        }

        & .country-selector {
            overflow-y: auto;
            overflow-x: hidden;
            height: calc(100% - #{rem-calc(90)});

            @include breakpoint(medium down) {
                -webkit-overflow-scrolling: touch;
                margin-bottom: 0;
                margin-top: 0;
                height: 100%;
                .no-list-style{
                    padding: rem-calc(16 16 24);
                }
            }

            &-legenda {
                border-top: rem-calc(1) solid #cccccc;
                padding-top: rem-calc(24);
                padding-bottom: rem-calc(24);
                margin-right: rem-calc(10);
                @include breakpoint(small down) {
                    padding-top: rem-calc(12);
                    padding-bottom: rem-calc(12);
                    margin-right: 0;
                }
            }

            & li a {
                display: inline;
                font-weight: 400;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }
        }

        & .country-selector li {
            font-weight: 400;
            font-size: rem-calc(16);
            line-height: rem-calc(22);
            break-inside: avoid-column;
            padding-bottom: rem-calc(6);
            @include breakpoint (small down){
                padding-bottom: rem-calc(8);
            }

            .cc-country-line{
                display: flex;
                align-items: center;
            }

            &.force-hover-footer {
                .cc-country-line{
                    font-weight: 600;
                }
            }
            @include breakpoint(medium down) {
                padding: 0;
                margin-bottom: rem-calc(8);
                line-height: rem-calc(24);
                .language-container-internal {
                    margin-bottom: rem-calc(16);
                    @include breakpoint (medium down) {
                        margin-bottom: 0;
                    }
                }
                label {
                    &.checked {
                        background-color: #F8F8F8;
                    }
                    span {
                        font-size: rem-calc(12);
                        font-weight: 400;
                        line-height: rem-calc(18);
                    }
                }
            }
            i {
                float: right;
                svg {
                    height: rem-calc(24);
                    width: rem-calc(24);
                    position: initial;
                }
            }

            & .placeholder {
                min-width: rem-calc(24);
                height: rem-calc(24);
            }

            &:last-of-type {
                border-bottom: 0;
            }

            label {
                margin-bottom: 0;
            }
            &.checked {
                background-color: #F8F8F8;
            }
        }
    }

    &-box {
        @include breakpoint(medium down) {
            padding-left: 0;
            padding-bottom: 0;
            padding-right: 0;
            padding-top: rem-calc(40);
        }
        padding: rem-calc(48 0 20 0);

        &-small {
            font-size: rem-calc(16);
            line-height: rem-calc(22);
            padding-top: rem-calc(24);
            padding-bottom: rem-calc(9);
            border-bottom: 1px solid;
            cursor: pointer;
            @include breakpoint(medium down) {
                border-right: none;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &-sides {
            padding-left: rem-calc(16);
            padding-right: rem-calc(16);

            @include breakpoint(medium up) {
                padding-left: rem-calc(40);
                padding-right: rem-calc(40);
            }
        }

        &-left {
            padding-left: rem-calc(16);

            @include breakpoint(medium up) {
                padding-left: rem-calc(40);
            }
        }

        &-right {
            padding-right: rem-calc(16);

            @include breakpoint(medium up) {
                padding-right: rem-calc(40);
            }
        }
    }
    /*
    &-action-box {
        border-top: 1px solid $border-gray;

        &:nth-child(3),
        &:nth-child(4) {
            border-bottom: 1px solid $border-gray;
        }

        &:nth-child(odd) {
            border-right: 1px solid $border-gray;
        }

        @include breakpoint(medium up) {
            border-top: 0;
            border-bottom: 0;

            &:nth-child(3),
            &:nth-child(4) {
                border-top: 0;
                border-bottom: 0;
            }

            &:not(:last-child) {
                border-right: 1px solid $border-gray;
            }
        }
    }*/

    &-contacts {
        background-color: $more-light-gray;
        .form-footer-center-small-general {
            @include breakpoint(medium down) {
                justify-content: center;
            }
        }
        .input-group {
            margin-bottom: 0;
        }
        .footer-box {
            @include breakpoint(medium down) {
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
                &:last-child {
                    border-top: 1px solid #FFFFFF;
                }
            }
            a.title-box-contacts{
                display: block;
            }
            .title-box-contacts {
                font-family: $secondary-font;
                text-transform: uppercase;
                font-size: rem-calc(16);
                line-height: rem-calc(22);
                letter-spacing: rem-calc(-0.02em);
                margin-bottom: rem-calc(10);
                font-weight: 600;
                font-style: normal;
                &.strong {
                    @include breakpoint(small down) {
                        font-size: rem-calc(20);
                        font-weight: $global-weight-dark;
                    }
                }
            }
            .text-box-contacts {
                font-size: rem-calc(14);
                letter-spacing: rem-calc(0.4);
                line-height: rem-calc(23);
            }
            .input-newsletter {
                outline-width: 0;
                height: rem-calc(50);
            }
            .button-arrow-right {
                padding: 0;
                svg {
                    width: rem-calc(6);
                    height: rem-calc(10);
                }
            }
            #submitNewsletter {
                background-color: transparent;
            }

            .store-locator-footer-container {
                // From form.scss (Woosmap cancel the styling defined)
                #submitFindStore {
                    border-radius: 50%;
                    width: rem-calc(26);
                    height: rem-calc(26);
                    line-height: rem-calc(26);
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: transparent;

                    i {
                        vertical-align: super;
                    }
                }
            }
            .label-inside {
                &.focused {
                    .input-label {
                        top: rem-calc(-7);
                    }
                }
                .input-label {
                    top: calc(100% - 32px);
                    font-family: $body-font-family;
                    font-size: rem-calc(14);
                    font-style: normal;
                    font-weight: $global-weight-normal;
                    line-height: rem-calc(22);
                }
            }
        }

        .form-footer-center-small {
            @include breakpoint(medium down) {
                justify-content: left;
            }
        }
        .box-hide-for-small-general {
            @include breakpoint (medium down) {
                display: none;
            }
        }
    }
    /*
    &-bottom {
        .cell {
            padding-bottom: rem-calc(24);
        }
        padding: rem-calc(24 0);

        .cell {
            padding-bottom: 0;
        }
    }
    */

    &-links {
        .grid-container {
            @include breakpoint (medium down) {
                padding: 0 !important;
            }
        }
        .footer-link-boxes {
            letter-spacing: rem-calc(0.5);
            line-height: rem-calc(20);
            @include breakpoint (large down) {
                font-size: rem-calc(10);
            }
            @include breakpoint (medium down) {
                font-size: rem-calc(12);
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
                border-bottom: 1px solid #F2EDED;
                &.small-order-1 {
                    ::after {
                        display: none;
                    }
                }
            }
            &-country{
                @include breakpoint (large up) {
                    max-width: rem-calc(303.5);
                    min-width: rem-calc(303.5);
                }
            }

            .content-asset{
                height: 100%;
            }

            &.legal-cell {
                display: flex;
                justify-content: center;
            }

            .footer-links-category, .footer-links-category a {
                padding: rem-calc(10 0);
                display: block;
                font-weight: 500;
                //text-align: center;
                @include breakpoint (medium down) {
                    padding: rem-calc(8 0);
                    // border-top: rem-calc(1) solid $border-gray;
                    text-align: left;
                    font-size: rem-calc(14);
                    letter-spacing: rem-calc(0.1);
                    margin: rem-calc(0 20);
                }
                @include breakpoint (small down) {
                    border-bottom: rem-calc(1) solid $border-gray;
                    font-size: rem-calc(14);
                }
                @include breakpoint (large up) {
                    //margin-bottom: rem-calc(15);
                    text-transform: uppercase;
                    font-size: rem-calc(14);
                }
                &.links-display-none-general-footer {
                    @include breakpoint (medium down) {
                        display: none;
                    }
                }
            }
            .footer-links-section {
                a {
                    font-weight: 400;
                    display: block;
                }
                &.links-display-none-general-footer {
                    @include breakpoint (medium down) {
                        display: none;
                    }
                }
            }
            &.links-display-none-general-footer {
                @include breakpoint (medium down) {
                    display: none;
                }
            }
        }
    }
    /*
    .footer-contents-top {
        .title {
            font-weight: 500;
            font-size: rem-calc(14);
        }
        font-weight:400;
        font-size: rem-calc(12);
    }
    */
    /*
    &-links-top {
        @include breakpoint (large up) {
            padding-top: rem-calc(80);
            padding-bottom: rem-calc(80);
        }
    }
    */
    /*
    &-links-pre-home {
        @include breakpoint (large up) {
            padding: rem-calc(42 0);
        }
        @include breakpoint (medium down) {
            padding-bottom: rem-calc(30);
        }
        .footer-link-boxes {
            font-size: rem-calc(13);
            letter-spacing: rem-calc(0.6);
            line-height: rem-calc(22);
            @include breakpoint (medium down) {
                padding-left: rem-calc(17);
                padding-top: rem-calc(55);
                &:first-child, &:nth-child(2) {
                    padding-top: 0;
                }
            }
            .footer-links-category {
                display: block;
                font-weight: 500;
                @include breakpoint (large up) {
                    margin-bottom: rem-calc(15);
                    font-size: rem-calc(16);
                }
                @include breakpoint (medium down) {
                    font-size: rem-calc(16);
                    line-height: rem-calc(40);
                    letter-spacing: rem-calc(0.91);
                }
                &.prehome-display-none {
                    display: none;
                }
            }
            .footer-links-section {
                a {
                    font-weight: 500;
                    display: block;
                    @include breakpoint (medium down) {
                        font-size: rem-calc(14);
                        line-height: rem-calc(37);
                        letter-spacing: rem-calc(1.17);
                    }
                }
                .todays-promo {
                    margin-top: rem-calc(15);
                }
                .prehome-display-none {
                    display: none;
                }
            }
        }
        .prehome-display-none {
            display: none;
        }
    }
    */
    /*
    .prehome-country-selector {
        border-top: 1px solid $border-gray;
        .box-footer-legal-right {
            @include breakpoint(medium down) {
                display: none;
            }
        }
        .link-boxes-prehome {
            text-align: center;
            justify-content: flex-end;
            @include breakpoint(large up) {
                height: 100%;
            }
            @include breakpoint(medium down) {
                width: 100%;
                border-bottom: 1px solid $border-gray;
            }
            
            .prehome-footer-card-box {
                display: inline-block;
                @include breakpoint(large up) {
                    height: 100%;
                    max-width: rem-calc(196);
                    border-left: 1px solid $border-gray;
                    padding: rem-calc(8 0);
                    &:first-child {
                        border-left: 0;
                    }
                }
                @include breakpoint(medium down) {
                    padding: rem-calc(14 18);
                    &:first-child {
                        border-right: 1px solid $border-gray;
                    }
                }
                .img-box-card {
                    width: rem-calc(18);
                    height: rem-calc(18);
                    margin-right: rem-calc(8);
                    display: inline-block;
                    @include breakpoint(medium down) {
                        margin: auto;
                        display: block;
                        margin-bottom: rem-calc(7);
                    }
                    img {
                        @include breakpoint(large up) {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                }

                a {
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: 500;
                    line-height: rem-calc(15);
                    letter-spacing: rem-calc(0.5);
                    text-transform: uppercase;
                    font-size: rem-calc(12);
                    @include breakpoint(large down) {
                        font-size: rem-calc(10);
                    }
                    @include breakpoint (medium down) {
                        font-size: rem-calc(12);
                    }
                }
            }
            
        }
    }
    */
    /*
    .prehome-footer-bottom {
        @include breakpoint(large up) {
            .follow-us-box {
                padding-right: rem-calc(30);
            }
            .follow-us-title {
                display: inline-block;
                text-transform: uppercase;
                font-size: rem-calc(12);
                line-height: rem-calc(14);
                font-weight: 500;
                margin-right: rem-calc(30);
            }
            .icon-footer-follow-mobile {
                margin-right: rem-calc(30);
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    */
    /*
    .footer-contacts-pre-home {

        .footer-contacts {
            background-color: $white;
        }
        // border-top: 1px solid $border-gray;
        .form-footer-center-small-general {
            @include breakpoint(medium down) {
                justify-content: left;
            }
        }
        .visible-small-only-prehome-footer {
            display: block;
        }
        .visible-only-general-footer {
            display: none;
        }
        .footer-box {
            margin: 0;
            padding-top: rem-calc(29);
            padding-bottom: rem-calc(10);
            @include breakpoint(medium down) {
                padding: rem-calc(18);
                padding-bottom: 0;
                &:first-child {
                    border-bottom: 1px solid $border-gray;
                }
                .title-box-contacts {
                    font-size: rem-calc(17);
                    text-align: left;
                    line-height: rem-calc(24);
                }
            }
        }
        .box-hide-for-small-general {
            @include breakpoint (medium down) {
                display: block;
            }
        }
    }
    */
    /*
    &-actions {
        a {
            i {
                margin-right: rem-calc(12);

                @include breakpoint(small down) {
                    display: block;
                    margin-right: 0;
                    margin-bottom: rem-calc(6);
                }
            }
        }
    }*/

    &-change-country-social {
        border-top: 1px solid #F2EDED;

        a {
            display: block;
            position: relative;
        }
        span {
            font-size: rem-calc(14);
            font-weight: 500;
            @include breakpoint(large up) {
                //max-width: rem-calc(100);
                display: block;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                // white-space: nowrap;
            }
        }


    }
    .icon-toggle-top {
        width: rem-calc(16);
        height: rem-calc(8);
        position: absolute;
        right: rem-calc(15);
        &.small-right {
            @include breakpoint(medium down) {
                right: 0;
            }
        }
        &-revert{
            transform: rotate(180deg);
            height: auto;
        }
    }
    /*
    .box-footer-legal-right {
        text-align: right;
        .footer-box-small-legal-company {
            padding: rem-calc(12 30);
            display: inline-block;
            text-align: center;
            @include breakpoint(large up) {
                width: 44%;
                height: 100%;
                padding: rem-calc(12 0);
            }
            @include breakpoint(medium down) {
                display: block;
                text-align: center;
                border-bottom: 1px solid $border-gray;
            }
            &:first-child {
                @include breakpoint(large up) {
                    border-right: 1px solid $border-gray;
                }
            }
            a {
                font-weight: 500;
                text-transform: uppercase;
                font-size: rem-calc(12);
                @include breakpoint(large down) {
                    font-size: rem-calc(10);
                }
                @include breakpoint(medium down) {
                    font-size: rem-calc(12);
                }
            }
            &.company {
                @include breakpoint(large up) {
                    width: 56%;
                    float: left;
                }
            }
        }
    }
    */
    /*
    .country-width-row {
        @include breakpoint(large up) {
            width: 77%;
            // border-right: 1px solid $border-gray;
            position: relative;
        }
        @include breakpoint(large down) {
            width: 71%;
        }
        @include breakpoint(medium down) {
            width: 100%;
        }
    }
    */

    /*
    &-social {
        min-height: rem-calc(68);

        a {
            &:not(:last-of-type) {
                margin-right: rem-calc(16);
            }
        }
    }*/

    .icon-country {
        margin-right: rem-calc(8);
    }

    .icon-toggle {
        @include triangle(rem-calc(6), $black);

        position: absolute;
        top: 50%;
        transform: translateY(50%);
        right: rem-calc(24);
    }
    .icon-footer-follow-mobile {
        @include breakpoint(medium down) {
            padding-right: rem-calc(30);
        }
    }

    // &-actions,
    &-change-country-social,
    &-bottom {
        p {
            margin-bottom: 0;
        }
    }

    @include borders($border-gray, medium up);
    /*
    .card-box-footer {
        border-right: rem-calc(1) solid $border-gray;
        border-left: rem-calc(1) solid $border-gray;
        justify-content: center;
        .card-box {
            text-align: center;
            @include breakpoint(medium down) {
                padding: rem-calc(15 0);
            }
            &:first-child {
                margin-bottom: rem-calc(40);
                @include breakpoint(medium down) {
                    border-right: rem-calc(1) solid $border-gray;
                    margin-bottom: 0;
                }
            }
            img {
                width: rem-calc(18);
                margin-bottom: rem-calc(13);
            }
            .card-box-title {
                text-transform: uppercase;
                font-size: rem-calc(12);
                line-height: rem-calc(15);
                letter-spacing: rem-calc(0.5);
                font-weight: 500;
                margin-bottom: 0;
                display: block;
                @include breakpoint(large down) {
                    font-size: rem-calc(10);
                }
                @include breakpoint(medium down) {
                    font-size: rem-calc(12);
                }
            }
        }
    }
    */
    .follow-us-footer-box {
        // padding: rem-calc(35 0 40 200);
        .follow-us-title {
            font-family: $secondary-font;
            text-transform: uppercase;
            font-size: rem-calc(16);
            line-height: rem-calc(22);
            letter-spacing: rem-calc(-0.02em);
            font-weight: 600;
            margin-bottom: rem-calc(36);
            @include breakpoint(medium down) {
                font-size: rem-calc(12);
            }
        }
        a {
            &:not(:last-of-type) {
                margin-right: rem-calc(25);
            }
        }
        @include breakpoint (large down) {
            a {
                &:not(:last-of-type) {
                    margin-right: rem-calc(9);
                }
            }
        }
    }
    .footer-company-info {
        padding: rem-calc(24 0);

        p {
            font-weight: 400;
            font-size: rem-calc(10);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.5);
            text-align: center;
        }
    }
    .footer-close-button {
        position: absolute;
        top: rem-calc(40);
        right: rem-calc(40);
        @include breakpoint (medium down) {
            top: rem-calc(25);
            right: rem-calc(16);
        }
        svg {
            width: rem-calc(24);
            height: rem-calc(24);
        }
    }
    .payments-method-footer {
        img {
            width: rem-calc(625);
            height: auto;
        }
        padding-right: rem-calc(25);
    }
    /*
    .customer-service-footer {
        background: $more-light-gray;
        padding: rem-calc(36);

        &__icon {
            @include breakpoint (small down) {
                float: left;
                margin-right: rem-calc(10);
            }

            @include breakpoint (medium up) {
                float: left;
                margin-right: rem-calc(10);
            }
        }
        &__link {
            display: flex;
            width: 50%;
            margin: 0 auto;
            @include breakpoint (small down) {
                display: inline
            }
            .cell {
                @include breakpoint (small down) {
                    text-align: left;
                }
            }

            &:not(:last-child) {
                margin-bottom: rem-calc(26);
            }
        }

        &__title {
            @include breakpoint (small down) {
                font-size: rem-calc(14);
                text-transform: uppercase;
                font-weight: 700;
                padding: rem-calc(0 5);
            }
            @include breakpoint (medium up) {
                //float: left;
                text-align: left;
                font-size: rem-calc(14);
                text-transform: uppercase;
                font-weight: bolder;
                padding-left: rem-calc(28);
            }
        }
        &__content {
            @include breakpoint (medium up) {
                white-space: nowrap;
                //float: left;
                padding-left: rem-calc(28);
                text-align: left;
                font-size: rem-calc(12);
                //margin-left: rem-calc(30);
                margin-bottom: rem-calc(4);
                text-transform: uppercase;
            }
            @include breakpoint (small down) {
                font-size: rem-calc(10);
                padding-left: rem-calc(28);
                margin-bottom: rem-calc(20);
                font-weight: 600;
                text-transform: uppercase;
            }
            flex: 1;
            //margin-left: rem-calc(10);
            //margin-bottom: rem-calc(4);

            h5 {
                text-decoration: underline;
            }

            a {
                font-weight: $global-weight-normal;
                text-decoration: underline;
                border: none;

                &:hover {
                    text-decoration: none;
                }
            }
            .phone-number {
                text-decoration: none;
                font-weight: 500;
            }
        }

        &--horizontal,
        &:not(.customer-service-links--vertical) {
            display: flex;
            flex-wrap: wrap;

            &>.content-asset {
                &:not(:last-child) {
                    margin-bottom: rem-calc(0);
                }

                @include breakpoint (medium up) {
                    width: calc(50% - #{rem-calc(26)});

                    &:nth-child(2n - 1) {
                        margin-right: rem-calc(52);
                    }

                    &:nth-last-child(1),
                    &:nth-last-child(2) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    */
    .footer-legal-area {
        display: inline;
        padding-top: rem-calc(16);
        &__title {
            display: inline;
            font-size: rem-calc(14);
            a {
                display: inline;
                font-size: rem-calc(14);

            }
        }

        &__link, &__link-last {
            font-size: rem-calc(12);
            display: inline;
            padding: rem-calc(0 11);
            a {
                display: inline;
                font-size: rem-calc(12);

            }

        }
        &__link {
            border-right: 1px solid $medium-gray;
        }

        &__link-last {
            font-size: rem-calc(12);
            display: inline;
            padding: rem-calc(0 34 0 11);
        }
    }
    .footer-icons-mobile {
        @include breakpoint (small down) {
            padding: rem-calc(24 0 24 87.5);
        }
    }

    .footer-contacts {
        .arrowIcon svg {
            // -webkit-transform: rotate(-90deg);
            // -ms-transform: rotate(-90deg);
            // transform: rotate(-90deg);
            width: rem-calc(24);
            height: rem-calc(24);
        }
    }

    .new-footer {
        text-align: left;
        padding: rem-calc(24 0);

        @include breakpoint (medium down) {
            width: auto;
            padding: rem-calc(22 0);
            position: relative;
            /*
            &-footer-accordion-content {
                display: none;
                
                &.new-footer-accordion-visible {
                    display: block;
                }
            }
            */
        }
        /*
        &.footer-loyalty{
            background-image: linear-gradient(to right, #f0f2fa, #f5f1f8, #f8f1f5, #f9f1f2, #f8f2f0);
            padding: rem-calc(30);
            height: 100%;

            @include breakpoint (medium down){
                height: auto;
                padding: rem-calc(15 20);
                margin: rem-calc(10 0 0);
                border-bottom: none;

                & .new-footer-footer-accordion{
                    &::after{
                        display: none;
                    }
                }
                & .new-footer-footer-accordion-content{
                    display: block;
                }
            }

            p{
                font-size: rem-calc(14);
                margin: rem-calc(20) 0 rem-calc(25);

                @include breakpoint (medium down){
                    padding-right: rem-calc(30);
                }
            }
        }
        */

        &-footer-accordion {
            font-size: rem-calc(16);
            font-weight: 600;
            line-height: rem-calc(22);

            &-loyalty{
                color: #8D9CD0;
            }

            @include breakpoint (small down) {
                position: relative;

            }
        }
        &-link-footer {
            font-weight: 400;
            font-size: rem-calc(14);
            margin-top: rem-calc(8);
            line-height: rem-calc(24);
            display: block;

            &:first-child{
                margin-top: rem-calc(24);
            }

            @include breakpoint (medium down) {
                margin-top: rem-calc(10);
            }
        }
    }
    .accordion.accordion-page {
        .accordion-item {
            .accordion-title {
                :after{
                    width: rem-calc(16);
                    height: rem-calc(16);
                }
                &.inherit-footer {
                    line-height: inherit;
                    padding: 0;
                    border: 0;
                    font-size: inherit;
                    font-family: inherit;
                }
            }
        }
    }
}

.scrollbar-custom {
    //margin-right: rem-calc(5);
    overflow-y: auto;
    overflow-x: hidden;
    // padding-right: rem-calc(0.938em);


    &::-webkit-scrollbar {
        width: rem-calc(5);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $black;
        border-radius: rem-calc(0);
        height: rem-calc(200);
    }

}
.page[data-action="Reviews-Show"]{
    .footer-bottom-checkout {
        @include breakpoint (medium down){
            margin-bottom: 0;
        }
    }
}
.footer-bottom-checkout {
    padding: rem-calc(16 45 26);
    border-top: 1px solid $neutral;
    position: relative;
    background: $white;
    @include breakpoint (medium down){
        padding: rem-calc(16 45);
        border-bottom: 1px solid $neutral;
        margin-bottom: rem-calc(72);
    }
    p {
        margin-bottom: 0;
        line-height: rem-calc(18);
        font-size: rem-calc(10);
        font-weight: 400;
    }
}

ul.localities-container {
    border-bottom: 1px solid black;
    z-index: 1061;
}
/*
.footer-bottom-fix {
    margin-bottom: -150px;
    @include breakpoint(large down) {
        margin-bottom: 0;
    }
}
*/
.footer-country {
    padding-right: rem-calc(88);
}

.page[data-action="OrderStatus-Status"] {
    .footer-checkout{
        @include breakpoint (medium up){
            position: fixed;
            bottom: 0;
            width: 100%;
        }
    }
}

.footer-checkout-style-margin {
    @include breakpoint(small down) {
        margin-bottom: rem-calc(72);
    }
}

.dc-conversion-text{
    margin-top: rem-calc(-48);
}

/*
.only-visible-prehome-links {
    .link-boxes-prehome {
        display: none;
    }
}
*/
.overlay-footer-language {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    bottom: rem-calc(155);
    right: 0;
    left: 0;
    height: 100%;
    z-index: 801;
    display: none;
}
.boolean-field {
    margin-bottom: rem-calc(17);
    input[type="checkbox"]:disabled ~ .checkbox-input:after {
        background-color: #000000;
    }
}
.footer-contacts-background {
    background-color: $neutral;
    .grid-container {
        @include breakpoint (medium down) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}
.footer-container-nopadding-mobile {
    @include breakpoint(medium down) {
        padding: 0;
    }
}
/*
.wrapper-gray-footer-prehome {
    // background: $more-light-gray;
    border-top: 1px solid $border-gray;
}
*/

hr {
    width: 91%;
    height: 1px;
    background: $white;
    margin-bottom: rem-calc(17);
    margin-top: 0;
    margin-left: 0
}
/*
.mobileHrWidth {
    @include breakpoint(small down) {
        width: 77% !important;
        margin-left: rem-calc(18);
    }
}
*/

.mobilePadding {
    @include breakpoint(small down) {
        padding: rem-calc(0 18) !important;
    }
    // &.mobileMarginBottom {
    //     @include breakpoint(small down){
    //         margin-bottom: rem-calc(80);
    //     }
    // }
}

.mobileWidth {
    @include breakpoint(small down) {
        width: 100% !important;
    }
}

.buttonDim {
    width: 164px;
    height: 40px;
    @include breakpoint(small down) {
        width: 100% !important;
    }
}


.img-popup-newsletter {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: rem-calc(500);
    @include breakpoint(small down) {
        width: 100%;
        object-fit: cover;
        object-position: top;
        height: rem-calc(195) !important;
    }
}

.fix-position {
    @include breakpoint(large up) {
        max-height: 80vh;
        overflow: hidden;
    }
}
.fixed-height{
    height: 90vh;
}

.fixed-height-ww {
    height: 76vh;
    @include breakpoint(large up) {
        height: 100%;
    }
}

select {
    background-image: url('../images/ico-arrow-down-new.svg');
    background-size: rem-calc(16);
    border: none;
    background-color: transparent;
    &:focus {
        outline: none;
        border-width: rem-calc(0 0 1 0);
    }
}

.marginMandatory {
    margin-bottom: rem-calc(30);
}
.marginNews, .marginPromoProfile, .marginPrivacy {
    margin-bottom: rem-calc(24);
    // @include breakpoint (medium down) {
    //     margin-bottom: rem-calc(17);
    // }
}

.marginPromoProfile {
    margin-bottom: rem-calc(24)
}

.marginPrivacy {
    margin-bottom: rem-calc(24);
}

.bottomButton {
    bottom: rem-calc(-34px);
}

.fontMr {
    font-size: rem-calc(16) !important;
    line-height: rem-calc(22);
}
.fontLittle {
    font-size: rem-calc(14) !important;
}

.imgPopUpDimensions {
    @include breakpoint(small down) {
        width: 100%;
        height: 210px;
    }

}
.privacy-text {
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(24);
}
.customWidth {
    width: 70%;
    @include breakpoint(large up) {
        max-width: rem-calc(285.5);
    }
    @include breakpoint(small down) {
        width: 100% !important;
    }
}

.mrMrs {
    margin: 0;
    font-size: rem-calc(14) !important;
    @include breakpoint(small down) {
        line-height: rem-calc(20);
        margin-right: rem-calc(0) !important;
        padding: rem-calc(0 16 23);
    }
}


.checkboxMarginMobileDesktop {
    @include breakpoint (large up) {
        width: 40%;
    }
}

.checkboxMarginMobileDesktopTargeted {
    @include breakpoint (large up) {
        width: 40%;
    }

    // @include breakpoint(small down){
    //     margin-left: rem-calc(18) !important;
    //     width: 40% !important;
    //     margin-left: rem-calc(-338) !important;
    //     margin-top:  rem-calc(55) !important;
    // }
}


.mandatoryField {
    margin-top: rem-calc(-12) !important;
}

.selectMobileLabelCountry {
    border-bottom: rem-calc(1) solid black;
    background-color: transparent;
    margin-bottom: rem-calc(40);
    @include breakpoint(small down) {
        width: 100% !important;
    }
}

.mobileLabelCountry {
    @include breakpoint(small down) {
        width: 100% !important;
    }
}

.labelMail {
    padding: rem-calc(26 0 15 0);
    @include breakpoint(small down) {
        width: 100% !important;
    }
}
/*
.labelCountry {
    width: 200px;
    border: 1px solid black;
}
*/


#parsley-id-multiple-dwfrm_profile_customer_newspromotions {
    .form-error.is-visible {
        @include breakpoint (medium down) {
            position: absolute;
            white-space: nowrap;
            right: rem-calc(17);
            padding-top: rem-calc(18);
        }
    }
}
.form-error.is-visible {
    margin-top: rem-calc(-0.65);
    text-align: left;
}

/*
.mailCountryFlex {
    display: flex;
    height: 36px !important;
}
*/

.cc-email-margin {
    margin-bottom: rem-calc(22);
}

.cc-error-position{
    position: relative;
    top: rem-calc(7);
}

.newsletterFormTitle {
    font-family: $secondary;
    @include breakpoint(small down) {
        padding-left: rem-calc(16);
    }
}

.newsletterImg {
    width: 100%;
    @include breakpoint(small down) {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: top;
    }

}

.newsletter-popup {
    // Modal
    &.tingle-modal {
        background: rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(80px);
        @include breakpoint(small down) {
            overflow-y: hidden;
        }


        .tingle-modal-box {
            max-width: 80vw;
            max-height: 80vh;
            background: rgba(255, 255, 255, 0.75);
            backdrop-filter: blur(80px);

            @include breakpoint(medium down) {
                max-width: 100vw;
                max-height: 100vh !important;
                width: 100%;
                margin: 0;
                height: 100%;
            }

            @include breakpoint(small down) {
                background: rgba(255, 255, 255, 0.9);
            }

            &__content {
                padding: 0;
            }
        }

        .close {
            font-weight: 300;
            top: rem-calc(35) !important;
            right: rem-calc(40) !important;
            @include breakpoint(medium down) {
                top: rem-calc(20) !important;
                right: rem-calc(20) !important;
            }
            position: absolute;

        }
    }

    // &__popUpPic {
    //     width: 339px !important;
    //     height: 508px;
    //     margin-right: 25px;
    //     background-color:white !important;
    // }

    // &__content-cell {
    //     width: 50% !important;
    //     @include breakpoint(medium down) {
    //         width: 100% !important;
    //     }
    // }

    &__content {
        @include breakpoint(small down) {
            margin-left: 0px;
        }

        html.flexbox & {
            &::-webkit-scrollbar {
                display: none;
            }
            width: 50%;
            position: absolute;
            overflow-y: scroll;
            padding: rem-calc(0 40);
            height: 100%;
            display: flex;
            flex-direction: column;
            @include breakpoint (medium down) {
                padding: 0;
            }
            @include breakpoint (small down) {
                width: 100%;
            }
        }

        @include breakpoint (small down) {
            padding: 0;
        }

        .title {
            margin-bottom: rem-calc(8);
            @include breakpoint (medium up) {
                margin-top: rem-calc(16);
                margin-bottom: rem-calc(16);
            }

            @include breakpoint (small down) {
                margin-top: rem-calc(24) !important;
                font-size: rem-calc(16);
            }

            html.flexbox & {
                margin-top: rem-calc(40);
                margin-bottom: rem-calc(8);
                @include breakpoint(medium down) {
                    padding-right: rem-calc(78);
                }
            }
        }

        form {
            /*
            .mrmrsDescription {
                display: none;
                margin: 0;

                p {
                    margin: 0;
                }
            }
            */

            .mrmrsChoice {
                padding-top: rem-calc(16);
                @include breakpoint(small down) {
                    margin-top: rem-calc(32);
                    padding: 0;
                }
            }

            .boolean-field {
                &:last-of-type {
                    padding-right: 0;
                }
            }

            .button {
                margin-bottom: rem-calc(40);
                font-size: rem-calc(14);
                width: 100%;
                margin-left: 0;
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(20);
                }
            }

            input[type="text"],
            input[type="email"],
            select {
                &.is-valid-input {
                    border-color: $success;
                }
            }

            .mandatory-hint {
                display: none;
                margin: 0;

                p {
                    margin: 0;
                }
            }
        }

        @include breakpoint(xlarge down) {
            .title {
                padding-right: rem-calc(20);
            }
        }
        .input-error-group {
            @include breakpoint (medium down) {
                padding-right: rem-calc(35);
            }
            text-align: right;
        }
        #parsley-id-multiple-directMarketingAgreement {
            .form-error.is-visible {
                position: absolute;
                white-space: nowrap;
                left: rem-calc(40);
                @include breakpoint (medium down) {
                    left: rem-calc(17);
                    padding-top: rem-calc(12);
                }
            }
        }
    }

    &__picture {
        background: $light-gray;

        img {
            display: block;
            width: 100%;

            // Modernizr
            html.objectfit & {
                height: 100%;
                object-fit: cover;
                padding: 16px;
                background-color: white;
            }
        }
    }
}

.written-email-label {
    margin: rem-calc(20 20 0);
    font-size: rem-calc(16);
    font-weight: $global-weight-dark;
    font-family: $body-font-family;
    @include breakpoint(small down) {
        font-size: rem-calc(14);
        margin: unset;
        margin-bottom: rem-calc(8);
    }
    .written-email {
        font-weight: $global-weight-medium;
    }
  }
  .selected-country-label {
    margin: rem-calc(16 20);
    font-size: rem-calc(16);
    font-weight: $global-weight-dark;
    font-family: $body-font-family;
    @include breakpoint(small down) {
        font-size: rem-calc(14);
        margin: unset;
        margin-bottom: rem-calc(8);
    }
    .selected-country {
        font-weight: $global-weight-medium;
    }
  }
  .registrationFormStepOne {
    .cc-newsletter-required-field-label {
        margin-top: rem-calc(24);
        display: flex;
        justify-content: flex-end;
        font-family: $body-font-family;
        font-size: rem-calc(14);
        font-style: normal;
        font-weight: $global-weight-normal;
        line-height: rem-calc(20);
        color: $dark-gray;
    }
  }
  .registrationFormStepTwo {
    &.already-registered-edit {
        .background-edit {
            background: $white;
            width: 99% ;
            padding: rem-calc(16);
            margin-top: rem-calc(16);
            @include breakpoint (small down) {
              width: calc(100% - #{rem-calc(34)});
              margin-left: rem-calc(16);
            }
          }
    }
  }

.mrmrsChoice {
    .boolean-field.is-invalid-input {
        .radio-input::before {
            border: rem-calc(1) solid $error-red;
        }
        ~ .boolean-field .radio-input::before {
            border: rem-calc(1) solid $error-red; // Example style for all subsequent labels
        }
    }
}



  .edit-info {
    position: absolute;
    right: 6.5%;
    top: 23.5%;
    @include breakpoint (small down) {
      right: 7%;
      top: 26.5%;
    }
    cursor: pointer;
    .img-edit-info {
      width: rem-calc(20);
      height: rem-calc(20);
    }
  }
  .blank-space {
    flex: 0 0 auto;
    min-height: 0;
    min-width: 0;
    
    width: 90%;
    position: absolute;
    bottom: 12%;
    margin: rem-calc(0 4 36 4);
    @include breakpoint (xxlarge only) {
        bottom: 8%;
        left: 5%;
    }
    @include breakpoint (small down) {
        bottom: 6%;
        width: calc(100% - #{rem-calc(34)});
        margin-right: unset;
        margin-left: rem-calc(6);
    }
  }
  .already-registered-text {
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    font-weight: $global-weight-medium;
    font-family: $body-font-family;
  }
  .already-used-email {
    color: #53AD44;
    margin-left: rem-calc(20);
    margin-bottom: rem-calc(26);
    @include breakpoint (small down) {
        margin-left: unset;
        margin-bottom: rem-calc(4);
    }
  }
  .more-information {
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    font-weight: $global-weight-medium;
    font-family: $body-font-family;
    .btn-modal-link {
      text-decoration: underline;
      font-weight: $global-weight-dark;
    }
  }
  .already-registered-button {
    &.already-registered-continue {
        .newsletter-continue {
            position: absolute;
            width: 86%;
            left: 8.1%;
            bottom: 0;
            @include breakpoint (xxlarge only) {
                width: 89%;
                left: 5.5%;
            }
            @include breakpoint (small down) {
                left: unset;
                width: calc(100% - #{rem-calc(34)});
            }
          }
    }
  }
  .already-registered-head {
    &.already-registered-top {
        .already-registered-title {
            @include breakpoint (small down) {
                margin-bottom: rem-calc(26);
            }
          }
    }
  }
  
// .............footer Customer support ............
.tingle-modal-form-request {

    .close-button-modal {
        img {
            width: rem-calc(14);
        }
    }

    .form-request-title {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(30);
        @include breakpoint (medium down) {
            font-size: rem-calc(22);
        }
    }

    #form-contact {
        @include breakpoint (medium down) {
            padding-bottom: rem-calc(100);
        }
    }
    /*
    .cell-for-required-field-info {
        display: flex;
        align-items: flex-end;
        margin-top: rem-calc(-18);
        @include breakpoint (medium down) {
            margin-top: rem-calc(0);
        }
    }*/
    .more-info-text-form {
        margin-bottom: 0;
    }
    /*
    .link-margin-right-form {
        margin-right: rem-calc(10);
    }*/
    @media screen and (max-width: 767px) {
        &.tingle-modal {
            &.tingle-modal--visible {
                // visibility: hidden;
                .tingle-modal-box {
                    visibility: visible;
                    width: 100%;
                    margin-top: rem-calc(63);
                    height: 100vh;
                    max-height: inherit;
                    box-shadow: none;
                    .tingle-modal-box__content {
                        height: calc(100vh - 63px);
                    }
                }
            }
        }
    }
}

.seo-description-full {
    padding: rem-calc(70) rem-calc(100);
        @include breakpoint (small down) {
            padding: rem-calc(30) rem-calc(25);
        }
    h3 {
        font-size: rem-calc(32);
    }
    background-color: #F9F9F9;
}

/*
.special-border {
    @include breakpoint (medium up) {
        &:not(:last-of-type) {
            border-right: #e5e5e5 solid 1px;
        }
    }
    @include breakpoint (small down) {
        width: 60% !important;
        margin: 0 auto;
        &:not(:last-of-type) {
            &:after {
                content: '';
                border-bottom: #e5e5e5 solid 1px;
                position: absolute;
                width: 30%;
                right: 40%;
            }
        }
        margin-bottom: rem-calc(20);
        margin-top: rem-calc(20);
        &:first-of-type {
            margin-top: rem-calc(50);
        }
        .content-asset {
            padding-bottom: rem-calc(10);
        }
    }
}
*/


.contact-form {
    .text-area-web-form-request {
        padding-right: rem-calc(70);
        resize: none;
        &::-webkit-scrollbar {
            display: none;
        }
      }
    .spacer.large {
        @include breakpoint (medium down) {
            height: 1rem;
        }
    }
}
#recaptcha {
    @include breakpoint (medium down) {
        margin-bottom: rem-calc(16);
    }
}

#recaptcha iframe {
    @media only screen and (min-width : 320px) {
        max-width: 100%;
        transform: scale(0.92);
        -webkit-transform: scale(0.92);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
    @media only screen and (min-width : 360px) {
        max-width: 100%;
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
    @media only screen and (min-width : 412px) {
        max-width: 100%;
        transform: scale(1.23);
        -webkit-transform: scale(1.23);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }

    @media only screen and (min-width : 375px) {
        max-width: 100%;
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }

    @media only screen and (min-width : 700px) {
        max-width: 100%;
        transform: scale(1.03);
        -webkit-transform: scale(1.03);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }

}
.padding-qr-code {
    padding: 30px 0px 30px 0px !important;
}
.clz-lover {
    background-color: $loyalty;
    .clz {
        &-icon {
            width: rem-calc(24);
            height: rem-calc(24);
        }
    }
}
.clz-gift {
    background-color: $almond;
    color: $black;
    .clz {
        &-icon {
            width: rem-calc(24);
            height: rem-calc(24);
        }
        &-call{
            a {
                color: $black;
            }
            img {
                filter: invert(1);
            }
        }
    }
}
.clz-green {
    background-color: $green;
    .clz {
        &-icon {
            width: rem-calc(24);
            height: rem-calc(24);
        }
    }
}
.clz-lover, .clz-green {
    color: $white;
    .clz {
        &-call{
            a {
                color: $white;
            }
        }
    }
}
.clz-lover, .clz-gift, .clz-green {
    font-family: $secondary-font;
    display: flex;
    flex-direction: column;
    padding: rem-calc(40);
    width: rem-calc(455.33);
    height: rem-calc(240);
    @include breakpoint (medium down) {
        padding: rem-calc(24 24 24 16);
        width: 100%;
        height: rem-calc(180);
    }
    .clz {
        &-icon {
            float: left;
        }
        &-container {
            display: flex;
            align-items: center;
            margin-bottom: rem-calc(16);
        }
        &-title {
            text-transform: uppercase;
            font-style: normal;
            font-weight: 600;
            font-size: rem-calc(20);
            display: flex;
            align-items: flex-end;
            letter-spacing: rem-calc(-0.02em);
            padding-left: rem-calc(12);
            margin-bottom: 0;
            @include breakpoint (medium down) {
                font-size: rem-calc(16);
                padding-left: rem-calc(14);
            }
        }
        &-text {
            font-family: $body-font-family;
            font-weight: 400;
            font-size: rem-calc(16);
            line-height: rem-calc(22);
            margin-bottom: auto;
        }
        &-call {
            text-transform: uppercase;
            font-weight: 600;
            font-size: rem-calc(16);
            line-height: rem-calc(22);
            letter-spacing: rem-calc(-0.02em);
            a {
                font-weight: 600;
            }
            img {
                padding-left: rem-calc(24);
            }
        }
        &-line {
            width: 49px;
            float: right;
            height: 0px;
            border: 1px solid #FFFFFF;
            background-color: #FFFFFF;
        }
    }
}
.footer-asset {
    padding: 0;
}
/* The slider */
.sliderToggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #CFCECF;
    -webkit-transition: .4s;
    transition: .4s;
    width: rem-calc(48);
    height: rem-calc(24);
    @include breakpoint (medium down) {
        width: rem-calc(80);
        height: rem-calc(40);
    }
}
.sliderToggle:before {
    position: absolute;
    content: "";
    height: rem-calc(16);
    width: rem-calc(16);
    left: rem-calc(4);
    top: rem-calc(4);
    background-color: #FFFFFF;
    box-shadow: 2px 3px 6px rgba($color: #000000, $alpha: 0.16);
    transition: .4s;
    border-radius: 50%;
    @include breakpoint (medium down) {
        height: rem-calc(30);
        width: rem-calc(30);
        left: rem-calc(5);
        top: rem-calc(5);
    }
}
input:checked + .sliderToggle:before {
    transform: translateX(rem-calc(24));
    @include breakpoint (medium down) {
        transform: translateX(rem-calc(40));
    }
}
input:checked + .sliderToggle{
    background-color: #010001;
}
.sliderToggle.round {
    border-radius: 34px;
}
.sliderToggle.loyalty-highlight{
    z-index: 0;
    &::after{
        position: absolute;
        content: "";
        height: rem-calc(46);
        width: rem-calc(46);
        left: rem-calc(-10);
        bottom: rem-calc(-10);
        border-radius: 50%;
        background-color:rgba($color: $loyalty, $alpha: 0.7);
        z-index: -1;
        animation: pulse-ring 1.25s cubic-bezier(0.2, 0.5, 0.3, 1) infinite;
        @include breakpoint (medium down) {
            height: rem-calc(96);
            width: rem-calc(96);
            left: rem-calc(-10);
            bottom: rem-calc(-10);
        }
    }
}
/* The switch - the box around the slider */
.switchToggle {
    position: relative;
    display: inline-block;
    width: rem-calc(47);
    height: rem-calc(28);
}
.text-end{
    text-align-last: right;
}
/* Select - Added left space in the combobox */
select.added-left-space-box:not([multiple]) {
    option {
        padding: rem-calc(8 16);
    }
    option:checked {
        background-color: #F8F8F8;
    }
}
.boolean-field.modal-newsletter, .boolean-field.modal-newsletter{
    .radio-input::before{
        border-radius: rem-calc(4);
    }
    .radio-input::after{
        border-radius: rem-calc(4);
    }
}
.contactType, .contactSubType {
    @include breakpoint(medium down) {
        border-bottom: rem-calc(1) solid black;
    }
}

.cc-newsletter-reqired-text {
	line-height: rem-calc(20);
	font-size: rem-calc(12);
    margin-bottom: rem-calc(16);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}