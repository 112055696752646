.card {
    position: relative;
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(42);
    border: 1px solid $neutral;
    padding-top: rem-calc(12);
    background: $white;

    &.ghost {
        padding-bottom: rem-calc(12);
    }

    &.reduce-width-desktop {
        width: 60%;
        @include breakpoint (medium down) {
            width: auto;
            margin-top: auto;
        }
        @include breakpoint (small down) {
            width: auto;
            margin-top: auto;
        }
    }
    /*
    &.reduce-margin-top-desktop {
        margin-top: rem-calc(-28);
        @include breakpoint (medium down) {
            margin-top: auto;
        }
        @include breakpoint (small down) {
            margin-top: auto;
        }
    }
    */

    @include breakpoint (small down) {
        margin-top: 0;
        margin-bottom: rem-calc(10);
        padding-top: rem-calc(12);
    }

    &.only-border-bottom {
        border: none;
        border-bottom: 1px solid $light-gray;
    }

    &.small-margin {
        margin-top: rem-calc(10);
        margin-bottom: rem-calc(10);
        padding-top: 0;
    }

    &-chevron {
        &.margin-top-chevron {
            margin-top: rem-calc(-12);
        }
    }

    &--no-header {
        padding-top: 0;
    }
    /*
    &--no-shadow {
        box-shadow: none;
    }
    */

    &-header {
        padding: rem-calc(16 36 0 36);


        &.return-border-bottom {
            border-bottom: 1px solid #E8E8E8;
        }

        .ghost & {
            padding: rem-calc(16 36);
        }

        & h5 {
            font-weight: $global-weight-bold;
        }
    }

    &-body {
        padding: rem-calc(16);

        .remove-btn {
            position: absolute;
            top: rem-calc(4);
            right: rem-calc(12);
            font-size: rem-calc(24);
            font-weight: 300;
            cursor: pointer;
        }
    }

    h5.section-title {
        font-weight: $global-weight-dark;
    }

    p.smaller-text {
        font-size: rem-calc(14);
    }

    .form-section {
        border-bottom: 1px solid $neutral;

        &.no-border {
            border-bottom: none;
        }
    }

    &-footer {
        padding: rem-calc(0 24 18);
    }

    &-button {
        position: absolute;
        bottom: 0;
        right: rem-calc(24);
        transform: translateY(60%);
    }
}

/*
.leading-lines {
    position: relative;
    overflow: hidden;
    margin-bottom: rem-calc(16);
    border-bottom: 1px solid $border-gray;

    label,
    p {
        margin-bottom: rem-calc(6);
    }
}
*/

// Card shadow exceptions
.page-checkout,
.receipt {
    .card {
        box-shadow: none;
    }
}

.cell.small-10.payments-refund-method {
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: rem-calc(20);
    @include breakpoint (small down) {
        margin-bottom: rem-calc(15);
    }
}

.cell.small-9.margin-top-subtitle {
    margin-top: rem-calc(8);
    @include breakpoint (small down) {
        margin-top: rem-calc(6);
        font-size: rem-calc(10);
    }
}
