$notification-colors: (
    'success': $success,
    'warning': $warning,
    'alert': $alert,
    'loyalty': $loyalty
);

$alert-colors: (
    'neutral': (
        'color': #000000,
        'background': #F5F5F5
    ),
    'success': (
        'color': #07852B,
        /* 'background': #F8F8F8 */
    ),
    'error': (
        'color': #EE0000,
    )
);

.feedback {
    padding: rem-calc(8 16);
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    font-weight: $global-weight-medium;
    border: none;

    p {
        margin: 0;
        font-size: rem-calc(14);
        line-height: rem-calc(20);
    }
    a {
        color: inherit;
    }

    @each $state, $values in $alert-colors {
        /* Notifications state colors */
        &--#{$state}  {
            @each $property, $value in $values {
                #{$property}: $value;
            }
        }
    }
    &.giftkit{
        padding: rem-calc(16);
    }

}

.notification {
    padding: rem-calc(8) 0;
    color: gray(1);
    font-size: 0;
    font-weight: $global-weight-normal;

    svg {
        display: inline-block;
        vertical-align: middle;
    }

    p {
        display: inline-block;
        vertical-align: middle;
        max-width: calc(100% - 32px);
        margin: 0 0 0 rem-calc(8);
        font-size: rem-calc(12);
        line-height: rem-calc(18);
    }

    @each $state, $hex in $notification-colors {
        /* Notifications state colors */
        &--#{$state}  {
            color: $hex;

            a {
                color: $hex;
            }
        }
    }
}
