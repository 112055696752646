.componentFourProductPrice{
    h2 {
        text-align: center;
    }
    .price{
        text-align: center;
    }
}
.componentSixProductPriceCta{
    text-align: left;
    margin-bottom: rem-calc(70);
    
}


.hp-banner-only-text{
    .wrapper-banner-only-text{
        background-color: $more-light-gray;
        text-align: center;
        padding-top: rem-calc(22);
        padding-bottom: rem-calc(22);
        
        p {
            &.title {
                font-size: rem-calc(30);
                line-height: rem-calc(35);
                display: inline;
                padding: 0;
                margin: 0;
                font-weight: 500;
                @include breakpoint(small down) {
                    display: block;
                    text-align: center;
                    font-size: rem-calc(22);
                    line-height: rem-calc(28);
                    margin-bottom: rem-calc(5);
                }
        
            }
            &.subtitle {
                font-size: rem-calc(18);
                line-height: rem-calc(26);
                display: inline;
                padding: 0;
                margin: 0;
                @include breakpoint(small down) {
                    display: block;
                    text-align: center;
                    font-size: rem-calc(18);
                    line-height: rem-calc(20);
                    margin-bottom: rem-calc(5);
                }
            }
        
        }
        a.link {
            font-size: rem-calc(14);
            line-height: rem-calc(26);
            text-transform: uppercase;
            display: inline;
            padding: 0;
            margin: 0;
            font-weight: 400;
            text-decoration: underline;
            @include breakpoint(small down) {
                display: block;
                text-align: center;
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                

            }
        }
        img{
            max-height: rem-calc(45);
            margin-right: rem-calc(20);
        }
    }
}