dl {
    display: flex;
    flex: 1 1;
    max-width: rem-calc(480);
    word-break: break-word;
    margin-bottom: 0.5rem;

    @include breakpoint (small down) {
        display: block;
        margin-bottom: 1rem;
    }

    dt,
    dd {
        flex: 1 1;

        &.large {
            flex: 3 1;
        }
    }

    dt {
        color: $medium-gray;

        @include breakpoint (small down) {
            margin-bottom: 0;
        }
    }

    dd {
        font-weight: $global-weight-bold;
        padding-left: rem-calc(12);

        @include breakpoint (small down) {
            padding-left: 0;
        }
    }
}
