.page[data-action='Newsletter-Contact'] {
    .grid-container:not(:first-child) {
        @include breakpoint(large up) {
            padding: 0;
        }
    }
}
.web-form-request-page,
.web-form-request-page-world,
.web-form-request-modal {
    .tingle-modal-box {
        @include breakpoint(medium down) {
            width: 100%;
            height: 100%;
            max-width: 100vw;
            max-height: 100vh;
        }
        @include breakpoint(large up) {
            min-height: 50vh;
            max-width: rem-calc(520);
            max-height: 85vh;
        }
        background-color: rgba(255, 255, 255, 0.75);
        backdrop-filter: blur(80px);
    }
    .tingle-modal-box__content {
        overflow-y: scroll;
        @include breakpoint(large up) {
            padding: rem-calc(40);
        }

        padding: rem-calc(24 16);
        height: 100%;
        display: flex;
        background-color: initial;
        flex-direction: column;
        .contact-form,
        .contact-form-world {
            flex-grow: 1;
            .contact-form-step-one {
                display: flex;
                flex-direction: column;
                height: 100%;
                .container-confirm-button {
                    margin-top: auto;
                    .contact-form-next-step {
                        @include breakpoint(medium down) {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .close-button-modal {
            img {
                width: rem-calc(15);
            }
            @include breakpoint(medium down) {
                position: absolute;
                top: rem-calc(24);
                margin: 0;
                right: rem-calc(16);
            }
            @include breakpoint(large up) {
                top: rem-calc(40);
                right: rem-calc(40);
            }
        }
        .form-request-title {
            margin-bottom: rem-calc(8);
            padding-right: rem-calc(55);
            line-height: rem-calc(26);
            font-size: rem-calc(16);
            font-weight: bold;
            text-transform: uppercase;
            @include breakpoint(medium down) {
                padding-right: rem-calc(32);
            }
        }
        .form-request-paragraph {
            font-size: rem-calc(14);
            margin-bottom: rem-calc(40);
            padding-right: rem-calc(55);
        }
    }
    .contact-form-step-one {
        display: flex;
        flex-direction: column;
        .stepOne-option {
            min-height: rem-calc(55);
            margin-bottom: rem-calc(25); //103-15-55
            @include breakpoint(medium down) {
                margin-top: rem-calc(25);
                margin-bottom: rem-calc(17);
            }
        }
    }

    .contact-form-step-two {
        margin-top: rem-calc(8);
        .label-inside input:disabled {
            border-color: $black;
            background: transparent;
            ~ .input-label {
                color: $black;
            }
        }
    }
    .wfr {
        &-contactDescription {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            position: relative;
            &-counter {
                bottom: rem-calc(23);
                position: absolute;
                right: 0;
            }
        }
        &-countrySelector {
            position: relative;
            margin-bottom: rem-calc(18);
            .form-control-label {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }
            .chosen-single {
                height: rem-calc(36);
                padding-bottom: rem-calc(8);
                padding-top: rem-calc(2);
                > span {
                    line-height: rem-calc(25);
                }
            }
            .errors-list {
                position: absolute;
                top: 100%;
                .form-error.is-visible {
                    margin: 0;
                }
            }
        }

        &-privacyLabel {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            margin-bottom: rem-calc(24);
            margin-top: rem-calc(9);
        }
    }
    .contact-form-recap {
        background-color: $neutral;
        padding: rem-calc(24);
        margin-bottom: rem-calc(24);
        &-head {
            @include flexGenerator(row, space-between, center);
            margin-bottom: rem-calc(16);
            &-text {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }
        }
        ul {
            margin-bottom: 0;
            li {
                list-style-type: none;
                position: relative;
                &::before {
                    content: '';
                    background-color: $black;
                    height: rem-calc(2);
                    left: rem-calc(-21);
                    margin: rem-calc(9.5);
                    position: absolute;
                    width: rem-calc(2);
                }
            }
        }
        &-type {
            margin-bottom: rem-calc(8);
        }
    }
    .faq-section-wrapper {
        background-color: $neutral;
        .accordion.accordion-page {
            background-color: $neutral;
            border-bottom: rem-calc(1) solid gray(4);
            .accordion-item {
                .accordion-title {
                    background-color: $neutral;
                }
                .accordion-content {
                    background-color: $neutral;
                }
            }
        }
    }
    .chosen-container.chosen-container-active {
        color: $black;
    }
}

.web-form-request-page,
.web-form-request-page-world {
    @include breakpoint(large up) {
        padding: rem-calc(40 0 0);
        margin: auto;
        max-width: rem-calc(423);
    }
    @include breakpoint(medium down) {
        padding-top: rem-calc(32);
        h3 {
            margin-bottom: rem-calc(16);
        }
        .form-request-paragraph {
            margin-bottom: rem-calc(40);
        }
    }

    .faq-section-wrapper {
        background-color: gray(4);
        .accordion.accordion-page {
            background-color: gray(4);
            border-color: $neutral;
            .accordion-item {
                border-color: $neutral;
                .accordion-title {
                    background-color: gray(4);
                }
                .accordion-content {
                    background-color: gray(4);
                }
            }
        }
    }
}

.web-form-request-modal {
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(80px);

    .no-robot {
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 0;
        margin-top: rem-calc(15);
    }
    .min-height-unset {
        min-height: unset;
    }
}
#web_form_request_questions_chosen {
    margin-bottom: rem-calc(40);
}
.new-web-form-img {
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    @include breakpoint(large up) {
        top: rem-calc(71);
    }
}
.contact-form,
.contact-form-world {
    .chosen-container {
        .chosen-drop {
            display: none;
        }
        &.chosen-container-active {
            .chosen-drop {
                display: block;
                z-index: 1;
                .chosen-results {
                    background: $white;
                    max-height: rem-calc(150);
                    li {
                        font-size: rem-calc(16);
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: $black;
                    }
                }
            }
        }
    }
}
.tingle-modal-form-request.tingle-modal.tingle-modal--visible
    .tingle-modal-box
    .tingle-modal-box__content {
    @include breakpoint(medium down) {
        padding: 0;
    }
}
.no-robot {
    font-size: rem-calc(10);
    line-height: rem-calc(15);
    font-weight: 400;
    color: $light-salmon-pink;
    margin: rem-calc(10 0 20 12);
}
.more-info-text {
    padding-top: rem-calc(24);
}
.button-padding {
    padding-top: rem-calc(32);
}

.contact-form-success {
    .request-result-message-title {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(15);
        span {
            margin-right: rem-calc(20);
        }
    }
    .request-result-message-description {
        margin-bottom: rem-calc(40);
    }
}
.contact-form-error {
    .request-result-message-title {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(15);
    }
}

.order-status-new-form {
    .close-button-modal {
        img {
            width: rem-calc(15);
        }
        @include breakpoint(medium down) {
            position: absolute;
            left: unset;
            margin: 0;
            right: 0;
        }
    }
    .tingle-modal-box {
        max-width: rem-calc(520);
        padding: rem-calc(25 19);
        @include breakpoint(medium down) {
            padding: rem-calc(24 16);
            height: 100vh;
            margin-top: 0 !important;
            form,
            .form-group {
                height: 98%;
            }
            .form-group {
                display: flex;
                flex-direction: column;
                .order-status-button {
                    margin-top: auto;
                    button {
                        width: 100%;
                    }
                }
            }
        }
        .subtitle {
            font-family: $secondary-font;
            font-size: rem-calc(16);
            font-weight: 600;
            line-height: rem-calc(22.41);
            margin-bottom: rem-calc(8);
            text-transform: uppercase;
        }
        .form-request-paragraph-no-margin {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
            padding-right: rem-calc(50);
        }
        .order-status-paragraph {
            margin-bottom: rem-calc(40);
        }
    }

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea,
    select {
        border: none;
        border-bottom: 1px solid $black;
        padding-top: rem-calc(8);

        &:focus {
            border: none;
            border-bottom: 1px solid $black;
            outline: none;
        }
    }

    input[readonly] {
        background-color: transparent;
    }

    .label {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
    }

    .form-control-label {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
    }

    .close-button {
        left: unset;
        right: rem-calc(40);
        top: rem-calc(40);

        @include breakpoint(medium down) {
            top: rem-calc(24);
            right: rem-calc(16);
        }
    }
}

.order-status-page {
    .subtitle {
        font-family: $secondary-font;
        font-size: rem-calc(20);
        font-weight: 400;
        line-height: rem-calc(26);
        margin-bottom: rem-calc(24);
        text-transform: unset;

        @include breakpoint(medium down) {
            font-size: rem-calc(16);
            line-height: rem-calc(20);
            margin-bottom: rem-calc(16);
        }
    }
    .form-request-paragraph-no-margin {
        font-size: rem-calc(14);
        line-height: rem-calc(20);
    }
    .order-status-paragraph {
        margin-bottom: rem-calc(40);
    }
    @include breakpoint(large up) {
        padding: rem-calc(40 0 0 0);
        max-width: rem-calc(423);
        margin: 0 auto;
    }
    @include breakpoint(medium down) {
        padding: rem-calc(0 16);
        padding-top: rem-calc(32);
    }
    .form-group {
        display: flex;
        flex-direction: column;
        .container-confirm-button {
            margin-top: auto;
        }
        @include breakpoint(medium down) {
            min-height: calc(100vh - 240px);
            .container-confirm-button {
                button {
                    width: 100%;
                }
            }
        }
    }

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea,
    select {
        border: none;
        border-bottom: 1px solid $dark-gray;
        padding-top: rem-calc(8);

        &:focus {
            border: none;
            border-bottom: 1px solid $black;
            outline: none;
        }
    }

    input[readonly] {
        background-color: transparent;
    }

    .label {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
    }

    .form-control-label {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
    }

    .button {
        width: 100%;
    }
}

.order-error-container__info {
    .__info__title {
        font-family: $secondary-font;
        font-size: rem-calc(20);
        font-weight: 400;
        line-height: rem-calc(26);
        margin-bottom: rem-calc(24);
        text-transform: unset;

        @include breakpoint(medium down) {
            font-size: rem-calc(16);
            line-height: rem-calc(20);
            margin-bottom: rem-calc(16);
        }
    }
    .order-error-container__info__label {
        font-size: rem-calc(14);
        line-height: rem-calc(20);
    }
    .order-error-container__info__buttons {
        margin-top: rem-calc(18);
    }
    .order-error-container__info__button {
        line-height: rem-calc(20);
        font-size: rem-calc(14);
    }
    // @include breakpoint (large up){
    //     padding: rem-calc(40 0 0 131);
    // }
    @include breakpoint(medium down) {
        padding: rem-calc(32 16 0 16);
    }
}
.page[data-action='OrderStatus-Status'] {
    .conversion-rate-content {
        position: sticky;
    }
}

// properties for fixing the footer behaviour

.page[data-action='Newsletter-Contact'],
.page[data-action='OrderStatus-Status'],
.page[data-action='Order-Details'] {
    @include breakpoint(large up) {
        padding-bottom: rem-calc(69);
        .footer-checkout {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

.faq-section-wrapper {
    margin: rem-calc(0 0 24 0);
    padding: rem-calc(16);
    .description {
        margin-bottom: rem-calc(24);
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        letter-spacing: rem-calc(0.1);
    }
    background-color: gray(11);
    .accordion.accordion-page {
        margin-bottom: 0;
        background-color: gray(11);
        .accordion-item {
            border-top: rem-calc(1) solid gray(4);
            letter-spacing: rem-calc(0.13);
            &.active {
                .accordion-title::after {
                    right: 0;
                    padding-bottom: rem-calc(8);
                    font-size: rem-calc(30);
                }
            }
            .accordion-title {
                padding: rem-calc(16 32 16 0);
                background-color: gray(11);
                border-top: 0;
                font-size: rem-calc(14);
                letter-spacing: inherit;
                line-height: rem-calc(20);
                &::after {
                    right: 0;
                    font-size: rem-calc(24);
                }
            }
            .accordion-content {
                padding: 0;
                background-color: gray(11);
                font-size: rem-calc(13);
                letter-spacing: inherit;
                p {
                    font-size: inherit;
                }
            }
        }
    }
}

.continue-to-form {
    font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
    font-size: rem-calc(16);
    line-height: rem-calc(22);
    font-weight: 600;

    @include breakpoint(medium down) {
        margin-bottom: rem-calc(24);
    }
}

.contact-link {
    text-decoration: underline;
    font-weight: inherit;
    &:hover {
        cursor: pointer;
    }
}
