.cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

svg.big {
    width: rem-calc(30);
    height: rem-calc(30);
}
svg.small {
    width: rem-calc(20);
    height: rem-calc(20);
}
svg.verysmall {
    width: rem-calc(14);
    height: rem-calc(14);
}
svg.xsmall {
    width: rem-calc(10);
    height: rem-calc(10);
}
svg.xxsmall {
    width: rem-calc(6);
    height: rem-calc(6);
}

@keyframes loading {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.page[data-action='Search-Show'],
.page[data-action='Product-Show'],
.cm-product-filmstrip {
    img.loading,
    img.lazyload,
    img.slick-loading,
    img[loading='lazy']:not([src$='.png']):not([src$='.svg']) {
        animation: loading 1.2s ease-in-out infinite;
        background-image: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
        background-size: 400%;
        color: transparent;
    }
}

.image-container {
    position: relative;
    overflow: hidden;

    &.ratio-1-1 {
        padding-top: 100%;
    }
}
