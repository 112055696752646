svg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -0.063em;
    width: 100%;
    height: 100%;
    fill: currentColor;

    &:not(:root) {
        overflow: visible;
    }
}

.button,
a {
    i {
        display: inline-block;
        vertical-align: middle;
    }
}

.button, a, i {
    .ico-center-check{
        width: rem-calc(40);
        height: rem-calc(40);
        padding: rem-calc(4);
    }
    
    svg {
        width: 1.25em;
        height: 1.25em;

        &.outline {
            stroke-width: rem-calc(2);
            stroke: currentColor;
        }

        &.hollow {
            fill: transparent;
        }
    }

    &:hover {
        svg {
            &.hollow {
                fill: currentColor;
            }
        }
    }
}

i {
    display: inline-block;
    font-weight: normal;
    font-style: normal;

    svg {
        &.tiny {
            width: rem-calc(10);
            height: rem-calc(10);
        }

        &.small {
            width: rem-calc(16);
            height: rem-calc(16);
        }

        &.medium {
            width: rem-calc(20);
            height: rem-calc(20);
        }

        &.large {
            width: rem-calc(24);
            height: rem-calc(24);
        }

        &.huge {
            width: rem-calc(30);
            height: rem-calc(30);
        }
    }
}
