@import "../../mixins/helpers";

.nLComponent {
    @include flexGenerator(row, flex-start, center);
    flex-wrap: wrap;
    background-color: #F9F9F9;
    height: 29.282vw;
    &-image {
        padding: rem-calc(30 0);
        height: 29.282vw;
        .content-asset {
            width: 100%;
            height: 100%;
            display: flex;
        }
        img {
            margin-left: auto;
            height: 100%;
            width: auto;
        }
    }
    &-form {
        @include flexGenerator(column, center, center);
        padding: rem-calc(0 122);
        &-title {
            font-size: rem-calc(24);
            letter-spacing: .1px;
            line-height: rem-calc(28);
            margin-bottom: rem-calc(29);
        }
        &-description {
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            margin-bottom: rem-calc(0);
        }
        .arrowIcon svg {
            transform: rotate(-90deg);
        }
    }
    @include breakpoint (medium down) {
        height: unset;
        &-image {
            padding: 0;
            margin-bottom: rem-calc(34);
            width: 100%;
            height: auto;
            img {
                width: 100%;
                height: auto;
                margin: 0;
            }
        }
        &-form {
            padding: rem-calc(0 16);
            margin-bottom: rem-calc(47);
            &-title {
                text-align: center;
            }
            &-description {
                text-align: center;
            }
        }
    }
}
// newsletter landing page
.page[data-action="Newsletter-Show"] {
    .main-header {
        .grid-container {
            padding: 0;
            .minicart {
                @include breakpoint (large up) {
                    padding-right: rem-calc(24);
                }
                @include breakpoint (small down) {
                    margin-right: rem-calc(24);
                }
            }
            @include breakpoint (medium down) {
                padding-left: rem-calc(16);
            }
        }
    }
    .page-content-wrapper {
        padding: 0;
    }
    .checkboxErrorContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    #newspromotions-errors {
        @include breakpoint (medium down) {
            width: 70vw;
            margin: rem-calc(12 -2 0 0);
            display: flex;
            justify-content: end;
        }
        @include breakpoint (small down) {
            margin: rem-calc(24 -30 0 0);
        }
    }
    .input-error-group {
        @include breakpoint (large up) {
            height: rem-calc(50);
        }
        @include breakpoint (medium down) {
            height: rem-calc(70);
        }
    }
}
.newsletter-landing-container {
    display: flex;
    margin: 0;
    padding: 0;
    @include breakpoint (medium down) {
        flex-direction: column;
    }
    .newsletterLandingImg {
        width: 50vw;
        aspect-ratio: 1/1;
        object-fit: fill;
        @include breakpoint (medium down) {
            display: none;
        }
    }
    .newsletter-landing-form {
        display: flex;
        flex-direction: column;
        padding-top: rem-calc(40);
        width: 50vw;
        @include breakpoint (medium down) {
            width: 100%;
            .newsletterImg {
                @include breakpoint (medium down) {
                    display: none;
                }
            }
        }
        .newsletterFormTitle, .registerNewsletter {
            width: 35.61vw;
            margin: 0 rem-calc(130);
            @include breakpoint (medium down) {
                width: 100%;
                margin: 0;
            }
        }
        .newsletterFormTitle {
            .title {
                margin: 0 0 rem-calc(24) 0;
                font-weight: 400;
                font-size: rem-calc(20);
                line-height: rem-calc(26);
                @include breakpoint (medium down) {
                    margin: 0 0 rem-calc(16) 0;
                    font-size: rem-calc(16);
                    line-height: rem-calc(20);
                }
            }
        }
        .registerNewsletter {
            label.newsletterEmailLabel ~ div[id^="parsley-id"] {
                display: none;
            }
            .buttonDim {
                width: 35.61vw;
                @include breakpoint (medium down) {
                    width: 100%;
                }
            }
            .input-error-group {
                margin: auto 0;
                @include breakpoint(small down) {
                    padding-right: rem-calc(32);
                }
                .switchToggle {
                    float: right;
                }
            }
            .mobileLabelCountry,.marginNews {
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(40);
                }
            }
            .sliderToggle {
                left: rem-calc(-5);
            }
            .privacyPolicyText, .personalizationText, .privacy-text {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
            }
                #grayOnDisableDescription {
                    color: #bebebe;
                }
            .privacy-text {
                @include breakpoint (medium down) {
                    margin: rem-calc(40 0);
                }
            }
            .mrmrsChoice {
                margin: rem-calc(40 0 16 0);
            }
        }
    }
}

.cc-mailing-label {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-weight: $global-weight-normal;
    font-size: rem-calc(16);
    line-height: rem-calc(19);
    letter-spacing: 0;
    color: $dark-gray;
}

.label-inside {
    .cc-mailing-label {
        position: absolute;
        left: 0;
        bottom: calc(100% - 40px);
        transition: all 0.2s ease-in-out;
        pointer-events: none;
    }

    &.focused {
        .cc-mailing-label {
            font-size: rem-calc(12);
            color: $black;
            transform: none;
            left: 0;
            top: 0;
            bottom: auto;
            pointer-events: auto;
        }
        }
    }