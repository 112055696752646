.nav {
    margin: 0;
    &-tabs {
        list-style-type: none;
        display: flex;
    }
    &-item {
        flex: 1 1;
    }
}

.tab-bar-grid {
    @include breakpoint (large up) {
        justify-content: center;
    }
}

.tab-bar {
    @include flexGenerator(row);
    margin-bottom: rem-calc(36);
    margin-left: 0;
    margin-top: rem-calc(25);
    overflow-y: auto;
    /* @include breakpoint (large up) {
        justify-content: center !important;
    } */
    &-grid{
        margin-top: rem-calc(80);
        @include breakpoint (small down) {
            margin-top: rem-calc(39);
            padding-left: rem-calc(16);
        }
        @media screen and (min-width: 1024px){
            padding: rem-calc(0 80);
        }
        @media screen and (min-width: 1280px){
            padding: rem-calc(0 112);
        }
        @media screen and (min-width: 1366px){
            padding: rem-calc(0 120);
        }
        .category-description{
            line-height: rem-calc(22);
            @include breakpoint (medium up){
                max-width: rem-calc(257);
            }
        }
        .category-title-div{
            @include breakpoint (medium down){
                padding-right: rem-calc(16);
            }
        }
        @include breakpoint (medium up){
            visibility: hidden;
        }
    }
    &.tabs-noscroll {
        padding-bottom: 0;
        margin-bottom: 0;
        margin-top: 0;
        &::-webkit-scrollbar {
            display: none;
        }
        @include breakpoint (medium up) {
            margin-bottom: rem-calc(72);
        }
        @include breakpoint (small down) {
            margin-bottom: rem-calc(40);
            margin-top: rem-calc(24);
            padding-right: rem-calc(16)
        }
    }
    &__item {
        @include fixWidth(100px);
        height: 100%;
        @include flexGenerator(column, center, center);
        &-a:not(:last-child){
            margin-right: rem-calc(24);
            &.bigger-swatches{
                margin-right: rem-calc(8);
            }
            @include breakpoint (small down) {
                margin-right: rem-calc(8);
            }
        }
        &--has-image {
            justify-content: flex-start;
            &.bigger{
                max-width: rem-calc(200);
                width: rem-calc(200);
                height: rem-calc(280);
                &:hover{
                    .categoryText{
                        text-decoration: unset;
                    }
                }
                @include breakpoint (medium down){
                    height: rem-calc(210);
                    width: rem-calc(150);
                }
                img.categoryImage {
                    height: rem-calc(280);
                    width: rem-calc(200);
                    @include breakpoint (medium down){
                        height: rem-calc(210);
                        width: rem-calc(150);
                    }
                }
                &.tab-bar__item--selected{
                    img.categoryImage{
                        opacity: 0.6;
                    }
                }
            }
            img.categoryImage {
                display: inline-block;
                border-radius: rem-calc(10);
                height: rem-calc(150);
                width: rem-calc(100);
                margin-bottom: rem-calc(16);
            }
        }
        .categoryText {
            text-align: center;
            font-size: rem-calc(14);
            line-height: rem-calc(20);
            &.for-bigger-swatches{
                position: absolute;
                top: 0;
                left: 0;
                padding: rem-calc(16);
                text-decoration: unset;
                text-transform: uppercase;
                font-weight: 600;
                font-size: rem-calc(18.6);
                text-align: left;
                line-height: rem-calc(26);
                max-width: rem-calc(200);
                font-family: 'SpeziaExtended';
                @include breakpoint (medium down){
                    font-size: rem-calc(13);
                    max-width: rem-calc(150);
                }
            }
        }
        &--selected,
        &:active,
        &:hover,
        &:focus {
            .categoryText {
                text-decoration: underline;
            }
        }
        &.bigger{
            position: relative;
            max-width: rem-calc(200);
            width: rem-calc(200);
            height: rem-calc(280);
            @include breakpoint (medium down){
                height: rem-calc(210);
                width: rem-calc(150);
            }
            &:focus, &:hover{
                .for-bigger-swatches{
                    text-decoration: none;
                }
            }
        }
    }
}


/* TABS PAGE  */
.container-tabs {
    width: 100%;
    ul {
        padding: 0;
        margin: 0;
        &.tabs {
            margin: rem-calc(0 0 35 0);
            padding: 0;
            list-style: none;
           // background-image: url('../images/pixel-grey.png');
            background-position: bottom;
            background-repeat: repeat-x;
            li {
                background: none;
                color: $medium-gray;
                display: inline-block;
                border-bottom: rem-calc(4) solid transparent;
                padding: rem-calc(5 10 0 10);
                font-weight: 500;
                font-size: rem-calc(15);
                cursor: pointer;
                margin-right: rem-calc(5);
                @include breakpoint(small down) {
                    margin-right: rem-calc(0);
                }
                &:last-child {
                    margin-right: 0;
                }
                &.current {
                    background: none;
                    color: $black;
                    border-bottom: rem-calc(4) solid $black;
                    padding: rem-calc(5 10);
                }
            }
            &.tabs-center {
                text-align: center;
            }
        }
    }
    .tab-content {
        display: none;
        background: none;
        padding: rem-calc(15 0);
        &.current {
            display: inherit;
        }
    }
}