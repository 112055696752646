

	.bras{
		width: 100%;
		text-align: center;
		padding: 3% 0;
	}
	
	.bras-title{
		width: 100%;
		text-align: center;
        font-size: rem-calc(22); 
        line-height: rem-calc(32); 
        margin: 0 auto 1%;
        
        @include breakpoint (small down) {
            font-size: rem-calc(22); 
            line-height: rem-calc(28); 
            }
    }
    .wrapper-bg-bras-title{
    background-color: $bg-bra-gray;
}
	.bras-subtitle{
		width: 100%;
		text-align: center;
        font-size: rem-calc(14); 
        line-height: rem-calc(20); 
		margin: 0 auto 2%;
	}
	
	.bras .bras-table{
		width: 75%;
		margin: auto;
	}
	
	.bras .bras-table .filters{
        margin: rem-calc(50) auto rem-calc(35) auto;
        white-space: normal;
        overflow: initial;
        @include breakpoint (small down) {     
        overflow-x: auto;
        white-space: nowrap;      
        margin: rem-calc(28 0 35 18);

}
	}
	
	.bras .bras-table #still{
		text-align: left;
		display: inline-table;
		margin: auto;
	}
	
	.bras .bras-table .filters .btn{
		border: rem-calc(1) solid #cccccc;
		text-align: center;
        font-size: rem-calc(13);
        line-height:  rem-calc(19);
		padding: rem-calc(8 20);
		margin:rem-calc(10 7);
		opacity: 1;
        background-color: transparent;
        @include breakpoint (small down) { 
        margin:rem-calc(7 5);
        }
	}

	
	.bras .bras-table .filters .active-filter{
		background-color: $medium-gray;
		color: $black;
	}
	
	.bras .bras-table .filters .active-filter:after{
		content: "x";
		display: inline-block;
		color: $black;
		font-size: rem-calc(20);
        margin-left: rem-calc(8);
        line-height: rem-calc(10);
	}
	
	.bras .bras-table .no-bras{
	  opacity: 0.2!important;
	}
	
	.bras .bras-table .bras-box{
		text-align: center;
		width: 14.28%;
		display: inline-block;
		float: left;
	}
	
	.bras .bras-table .bras-box p{
		text-transform: capitalize;
		font-size: rem-calc(12);
		width: 100%;
		text-align: center;
        margin: 0 auto 0;
        min-height: rem-calc(38);
	}
	
	.bras .bras-table .bras-box.active-box{
		opacity:1;
	}
	
	.bras .bras-table .bras-box a.disabled{
		cursor: auto;
	}
    
    @include breakpoint (large down) {     
		.bras .bras-table{
			width: 100%;
        }
        .bras .bras-table .bras-box{
			width: 20%;
			transition: 0.2s linear;		
		}
		
	}
	
	@media (max-width:800px){
		
		.bras .bras-table .bras-box p{
			font-size: rem-calc(10);
		}
		
	}
    @include breakpoint (medium down) {     
		
		#PromoSidetab-Label{
			display: none;
		}
		
		.bras{
			padding: 8% 0;
		}
		
		.bras-title{
			margin-bottom: 2%;
		}
		
		.bras-subtitle{
			margin-bottom: 5%;
		}
		
		.bras .bras-table .bras-box{
			width: 33.33%;
			transition: 0.2s linear;
			min-height: rem-calc(145);
		}
		
		.bras .bras-table .bras-box.no-bras{
		  	display: none;
			transition: 0.2s linear;
		}
		
		.bras .bras-table .filters .btn{
			padding: rem-calc(7 10);
			
		}
		
		.bras .bras-table .bras-box p{
			font-size: rem-calc(11);
			padding: rem-calc(0 5);
    		letter-spacing: 0.1em;
		}
		
	}
	
