.accordion {

  &.accordion-page {
    margin-left: 0;
    background: $white;
    list-style-type: none;

    &[disabled] .accordion-title {
      cursor: not-allowed;
    }

    .accordion-item {
      ul,
      li {
        display: block;
        list-style-type: none;
        border-bottom: 0;
        margin-left: 0;
        padding: 0;
      }

      ul {
        padding-bottom: rem-calc(20);
      }

      &:first-child> :first-child {
        border-radius: 0 0 0 0;
      }

      &:last-child> :last-child {
        border-radius: 0 0 0 0;
      }

      &:last-child.active {
        .accordion-title {
          border-bottom: 0;
        }
      }

      &:last-child {
        .accordion-title {
          border-bottom: rem-calc(1) solid color(neutral);
        }
      }

      .accordion-title {
        position: relative;
        display: block;
        padding: rem-calc(24 30 24 0);
        border: 0;
        border-top: 1px solid color(neutral);
        border-bottom: 0;
        background-color: $white;
        transition: all .5s cubic-bezier(.25, .46, .45, .94);
        font-family: $secondary-font;
        font-weight: $global-weight-semibold;
        font-size: rem-calc(20);
        line-height: rem-calc(24);

        &:before {
            display: none;
        }

        &:after {
            content: '' !important;
            display: block;
            background-size: 100%;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            display: block;
            width: rem-calc(24);
            height: rem-calc(24);
            background-image: url("data:image/svg+xml;charset=utf8, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' xml:space='preserve'%3E%3Cpath fill='%23000' d='M12,4c-0.3,0-0.5,0.2-0.5,0.5v7h-7C4.2,11.5,4,11.7,4,12s0.2,0.5,0.5,0.5h7v7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5 v-7h7c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-7v-7C12.5,4.2,12.3,4,12,4z'/%3E%3C/svg%3E");
        }

        &:hover,
        &:focus {
          background-color: $white;
        }
      }
    }

    .active .accordion-title:after {
        content: '' !important;
        background-image: url("data:image/svg+xml;charset=utf8, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' xml:space='preserve'%3E%3Cpath fill='%23000' d='M4.5,11.5C4.2,11.5,4,11.7,4,12s0.2,0.5,0.5,0.5h15c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H4.5z'/%3E%3C/svg%3E");
    }

    .accordion-content {
      display: none;
      padding: rem-calc(16);
      border: 0;
      border-bottom: 0 !important;
      background-color: $white;


      p {
        &.share {
          font-style: normal;
          color: $medium-gray;
          font-size: rem-calc(11);
        }
      }
    }

    :last-child>.accordion-content:last-child {
      border-bottom: rem-calc(1) solid color(neutral);
    }
  }

  //FOOTER ONLY MOBILE
  &.initialized {
    .accordion-title {
      padding: rem-calc(16);
      position: relative;
      border-bottom: 1px solid $border-gray;
      cursor: pointer;


      &::after {
        content: '+';
        font-weight: $global-weight-light;
        font-size: rem-calc(24);
        position: absolute;
        right: rem-calc(16);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .accordion-item {
      &.active {
        .accordion-title {
          &::after {
            content: '-';
          }
        }
      }
    }

    .accordion-content {
      border-bottom: 1px solid $border-gray;
      display: none;


      li {
        margin-bottom: 0;
        padding: rem-calc(16);

        &:not(:last-of-type) {
          border-bottom: 1px solid $border-gray;
        }
      }
    }
  }
}
// Foundation Accordion overrides;
.accordion-title:hover,
.accordion-title:focus {
  background-color: gray(8);
}
