$flag-icon-css-path: '../images';
@import 'flag-icon.scss';

.geoblockContainerBlur {
    position: fixed;
    width: 100%;
    z-index: 9999999;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 20px;

    background-color: rgba(255, 255, 255, .5);
    backdrop-filter: blur(30px);

    .geoblockContainer {
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        list-style: none;
        margin-left: auto;
        margin-right: auto;
        max-width: rem-calc(980);
        padding: rem-calc(16) 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .geoblock-copy {
        display: inline-block;
        word-break: initial;
        text-align: center;
        margin-right: 2%;

        @include breakpoint(medium down) {
            flex: 1 0 90%;
            margin-bottom: rem-calc(12);
        }
    }

    .geoblock-actions {
        display: inline-flex;
        flex: 0 0 auto;
    }
    .geoblock-close {
        font-size: rem-calc(20);
        line-height: rem-calc(20);
        margin-left: rem-calc(25);

        @include breakpoint(medium down) {
            position: absolute;
            top: rem-calc(17);
            right: rem-calc(17);
        }
    }

    .button-shop {
        margin: 0 auto;
    }
}
