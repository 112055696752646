.alert[role="alert"], .cart-alert[role="alert"] {
    padding: rem-calc(20);
    border: none;

    &.alert-danger {
        color: $alert;
        font-size: rem-calc(14);
        font-weight: 400;
        overflow-wrap: break-word;
    }

    &.alert-warning {
        background: rgba($warning, 0.06);
        color: darken($warning, 10%);
    }

    &.alert-notification {
        background: gray(7);
        color: gray(1);
        p{
        font-size: rem-calc(14);
        }
        @include breakpoint(small down) {
            margin-left: rem-calc(17);
            margin-right: rem-calc(17);
        }
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .error-messages & {
        padding: 0;
    }

    .js-cart-error-reserve &,
    .afterpay-error & {
        padding: rem-calc(16 0);

        @include breakpoint (medium down) {
            padding: rem-calc(0 0 24 0);
        }
    }
}

.not-available-banner {
    border: none;
    color: $alert;
    font-size: rem-calc(12);
    font-weight: 400;
    margin-top:rem-calc(16);
}

