@import "chosen-js/chosen";

select.small + .chosen-container .chosen-single {
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(8);
    span {
        font-size: rem-calc(14);
    }
}

.chosen-single {
    .chosen-container-single &,
    .chosen-container-active.chosen-with-drop & {
        background: $input-background;
        border-radius: $input-radius;
        border: none;
        border-bottom: 1px solid color(dark-gray);
        box-shadow: none;
        font-weight: $global-weight-normal;
        height: auto;
        padding: rem-calc(10 30 10 0);
        font-size: rem-calc(16);
        line-height: rem-calc(19);
        text-decoration: none;

        &:not(.no-hover):hover {
            text-decoration: none;
        }
    }

    .chosen-container-single & {
        div {
            width: rem-calc(24);
            height: rem-calc(24);
            margin-right: 0;
            top: 50%;
            margin-top: rem-calc(-12);
        }
    }

    .chosen-disabled & {
        cursor: $input-cursor-disabled;
    }
}

.chosen-container {
    width: 100% !important; // fix chosen bug
    font-size: rem-calc(16);

    .is-invalid-input + & {
        .chosen-single {
            border-bottom-color: $alert !important;
        }
    }

    .chosen-drop {
        border: $input-border;
        box-shadow: none;
        border-radius: $input-radius;
    }

    .chosen-results {
        padding: rem-calc(6 0);
        margin: 0;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: rem-calc(6);
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray(5);
        }

        li {
            color: $black;
            padding: rem-calc(10 16);
            margin: 0;
            font-size: rem-calc(16);

            &.highlighted {
                background: #F8F8F8;
                color: $black;
                font-weight: $global-weight-normal;
                text-decoration: none;
            }
        }
    }
}

// Chosen images override
.chosen-rtl .chosen-search input[type="text"],
.chosen-container-single .chosen-single abbr,
.chosen-container-single .chosen-single div b,
.chosen-container-single .chosen-search input[type="text"],
.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span {
    background-image: url('../../images/ico-arrow-down-new.svg') !important;
    background-size: rem-calc(16) !important;
    background-repeat: no-repeat !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
    .chosen-rtl .chosen-search input[type="text"],
    .chosen-container-single .chosen-single abbr,
    .chosen-container-single .chosen-single div b,
    .chosen-container-single .chosen-search input[type="text"],
    .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
    .chosen-container .chosen-results-scroll-down span,
    .chosen-container .chosen-results-scroll-up span {
      background-image: url('../../images/ico-arrow-down-new.svg') !important;
      background-size: rem-calc(16) !important;
      background-repeat: no-repeat !important;
    }
}

.chosen-container-single .chosen-single div b {
    background-position: center center;
    background-size: rem-calc(16);
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: center center;
    transform: rotate(180deg);
}

.chosen-container-single .chosen-single span {
    overflow: visible !important;
}
