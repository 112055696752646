.spacer {
    height: 2rem;

    &.verysmall {
        height: 0.5rem;
    }

    &.small {
        height: 1rem;
    }

    &.medium {
        height: 2rem;
    }

    &.large {
        height: 4rem;
    }
}

.divider {
    margin: 1rem 0;
    height: 1px;
    background-color: $light-gray;

    &.small {
        margin: 0.5rem 0;
    }

    &.medium {
        margin: 1rem 0;
    }

    &.large {
        margin: 2rem 0;
    }
}
