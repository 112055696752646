
// Layer login
.layer-login-modal {
    top: rem-calc(72);
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(40px);
    @include breakpoint (medium down) {
        top: rem-calc(62);
    }
    .tingle-modal-box{
        position: absolute;
        top: 0;
        right: 0;
        max-width: rem-calc(520);
        background: rgba(255, 255, 255, 0.75);
        backdrop-filter: blur(40px);
        height: 100%;
        max-height: 100%;

        @include breakpoint(medium down) {
            width: 100%;
        }
        .close{
            top: rem-calc(40);
            right: rem-calc(40);
            left: unset;
            @include breakpoint (medium down) {
                right: rem-calc(16);
                top: rem-calc(16);
                z-index: 0;
            }
            &:focus{
                outline: none;
            }
        }
    }
    .tingle-modal-box__content{
        padding: 0;
        .title-login-box {
            display: block;
            padding-right: rem-calc(24);
        }
        .reinsert-password {
            padding: 1rem 2rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            @include breakpoint(small down) {
                align-items: flex-start;
            }
        }
        .login-title{
            padding: rem-calc(40);
            line-height: rem-calc(22);
            font-size: rem-calc(16);
            text-align: left;
            font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
            text-transform: uppercase;
            font-weight: 600;


            @include breakpoint(medium down) {
                padding: rem-calc(24 56 24 16);
                margin-bottom: 0;
            }
        }

        .login-subtitle {
            margin-bottom: 0;
            font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
            padding: rem-calc(0 40 24);
            color: $black;
            font-size: rem-calc(20);
            font-weight: 400;

            @include breakpoint(medium down) {
                padding: rem-calc(0 16 24 16);
            }
        }

        .fix-padding{
            background: transparent;
            backdrop-filter: unset;
            padding-bottom: rem-calc(30);
            @include breakpoint(medium up){
                padding: rem-calc(0 40);
            }
            @include breakpoint (medium down) {
                padding: rem-calc(0 16);
            }
        }

        .policy-register-form .fix-padding {
            padding: 0;

            @include breakpoint(medium up){
                padding: 0;
            }
            @include breakpoint (medium down) {
                padding: 0;
            }
        }

        .login-forgot-password-save-access {
            margin-top: rem-calc(24);
            display: flex;
            align-items: center;
            justify-content: space-between;

            a#password-reset{
                font-size: rem-calc(12);
            }
            .login-save-access{
                margin-bottom: 0;
                .checkbox-input{
                    margin-right: 0;
                    &:before{
                        width: 15px;
                        height: 15px;
                    }
                    &:after{
                        top: rem-calc(5);
                        left: rem-calc(3)
                    }
                }
                .checkbox-label{
                    text-transform: uppercase;
                    font-size: rem-calc(10);
                    line-height: rem-calc(18);

                    @include breakpoint (medium down) {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .js-link-info-social-registration{
                margin-left: rem-calc(7);
                margin-top: rem-calc(-3);
            }
        }
        .login-popup-footer{
            margin-top: rem-calc(24);
            .new-customer-text{
                font-size: rem-calc(16);
                line-height: rem-calc(22);
                padding-bottom: rem-calc(32);
            }
            .button-group {
                padding-bottom: rem-calc(24);
                & a.register{
                    font-size: rem-calc(14);
                    font-weight: 600;
                }
            }
        }

        .login-form-error ,
        .login-form-error-generic,
        .wrong-opt-inserted,
        .expired-opt-inserted {
            color: $alert;
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(20);
            text-align: left;
            margin-bottom: 0;
            background-color: transparent;

            &.login-form-error-block, &.already-registered {
                padding-bottom: rem-calc(24);
            }
        }

        .bg-alert {
            background-color: transparent !important;
        }
    }
    .button-login{
        margin-top: rem-calc(24);
    }

    .button {
        font-size: rem-calc(14);
        padding: rem-calc(10 28);
        min-height: rem-calc(40);
        height: auto;
    }

    .label-inside {
        .form-error,
        .invalid-feedback {
            position: absolute;
        }
    }

    .gigyaLoginCell {
        margin-top: rem-calc(24);

        .login-register-gigya {
            display: none;
        }

        .social-login-account-accept a {
            text-decoration: none;
            border-bottom: 1px solid $dark-gray;
        }
    }

    .slider.round {
        display: inline-flex !important;
    }

    .remember-me-container .info-rememberme {
        @include breakpoint (medium down) {
            right: rem-calc(-10);
            left: unset;
            overflow: hidden;
        }
    }

    .datepicker-box {
        select {
            border-bottom: 1px solid $dark-gray;
            height: auto;
            padding: rem-calc(20 0 8);
        }
    }
}

.full-width{
    width: 100%;
}

.layer-password-modal {
    .tingle-modal-box{
        @include breakpoint (large up){
            max-width: 38vw;
        }
    }
}


.modal-registration-flow-custom {
    .registrationModal-box {
        .close-arrow {
            display: none;
        }

        &[data-registration-modal-stage="email"] {
            .registration-modal-phone,
            .registration-modal-personal-info,
            .registration-modal-waiting {
                display: none;
            }

            .close-arrow {
                display: block;
            }
        }
        &[data-registration-modal-stage="phone"]{
            .registration-modal-email,
            .registration-modal-personal-info,
            .registration-modal-waiting {
                display: none;
            }
        }
        &[data-registration-modal-stage="personal-info"]{
            .registration-modal-email,
            .registration-modal-phone,
            .registration-modal-waiting {
                display: none;
            }
        }
        &[data-registration-modal-stage="waiting-room"]{
            .registration-modal-email,
            .registration-modal-phone,
            .registration-modal-personal-info {
                display: none;
            }
        }
    }
}

.modal-loyalty-registration-flow-custom {
    .registrationModal-box {
        &[data-registration-modal-stage="content"] {
            .loyalty-registration-modal-registration,
            .loyalty-registration-modal-loyaltythankyoupage {
                display: none;
            }
        }
        &[data-registration-modal-stage="registration"]{
            .loyalty-registration-modal-content,
            .loyalty-registration-modal-loyaltythankyoupage {
                display: none;
            }
        }
        &[data-registration-modal-stage="loyalty-thankyou-page"] {
            .loyalty-registration-modal-registration,
            .loyalty-registration-modal-content {
                display: none;
            }
        }
    }
}

.datepicker {
    .prev, .next {
        position: static;
    }
}

.cc-registrationModal {
    top: 0;

    @include breakpoint (medium down) {
        top: 0;
    }

    .tingle-modal-box {
        background-color: $white;

        .close {
            @include breakpoint (medium down) {
                top: rem-calc(24);
                right: rem-calc(16);
            }
        }
    }

    .label-inside {
        .form-error,
        .invalid-feedback {
            position: static;
        }
    }

    .tingle-modal-box__content {
        padding: rem-calc(40);
        overflow-x: hidden;
        overflow-y: auto;

        .login-subtitle {
            padding: rem-calc(0 0 16);
        }

        @include breakpoint (medium down) {
            padding: rem-calc(24 16);
        }
    }

    .registrationModal-box-header {
        padding-bottom: rem-calc(40);

        @include breakpoint (medium down) {
            padding-bottom: rem-calc(32);
        }
    }

    .close-arrow-left {
        width: rem-calc(24);
        height: rem-calc(24);
    }

    .close-arrow {
        position: static;

        &:hover {
            color: $black;
        }
    }

    #reg-phone-error {
        display: flex;
        align-items: center;
        margin-bottom: rem-calc(24);
        position: relative;
        padding-left: rem-calc(32);

        &::before {
            content: "";
            display: block;
            width: rem-calc(20);
            height: rem-calc(20);
            padding: rem-calc(3);
            margin-right: rem-calc(8);
            background: url('../images/chat-red.svg') no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
    }

    .close-button {
        svg {
            width: rem-calc(24);
            height: rem-calc(24);
            padding: rem-calc(2);
        }
    }

    .info-icon {
        width: rem-calc(24);
        height: rem-calc(24);
    }

    .opposition-field-login-date,
    .opposition {
        .info-icon {
            margin-left: rem-calc(3);
            &:focus, &:hover {
                .tooltip {
                    display: block;
                    visibility: visible;
                    opacity: 1;
                }

                p {
                    color: $black;
                    margin-bottom: 0;
                    font-size: rem-calc(10);
                    line-height: rem-calc(18);
                }
            }
        }
    }

    .opposition {
        .info-icon {
            margin-left: 0;

            .tooltip {
                left: unset;
                right: rem-calc(-10);
            }
        }

        .icon-tooltip {
            width: rem-calc(24);
            height: rem-calc(24);

            svg {
                width: rem-calc(24);
                height: rem-calc(24);
            }
        }
    }

    .close-button,
    .cc-arrow-button,
    .close-arrow {
        cursor: pointer;
        color: $black;
    }

    .cell.opposition {
        display: flex;
        align-items: center;

        .boolean-field:not(:last-child) {
            padding-right: rem-calc(8);
        }

        &.hidden {
            display: none;
        }
    }

    .button-text {
        display: inline-block;
        padding-left: rem-calc(24);
        font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
        font-size: rem-calc(16);
        font-weight: 600;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(-0.32);
        text-transform: uppercase;
    }

    .login-paragraph {
        margin-bottom: rem-calc(24);
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(20);
    }

    #registration-form-email,
    #loyalty-form-cardPin {
        margin-bottom: rem-calc(24);
    }

    #register-loyalty {
        margin-top: rem-calc(40);
        padding-top: rem-calc(40);
        position: relative;

        @include breakpoint (medium down) {
            margin-top: rem-calc(32);
            padding-top: rem-calc(32);
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: rem-calc(-40);
            right: rem-calc(-40);
            height: rem-calc(1);
            background: #F2EDED;
        }
    }

    .registration-modal-phone {
        .login-subtitle {
            padding-bottom: rem-calc(40);

            @include breakpoint (medium down) {
                padding-bottom: rem-calc(24);
            }
        }

        .email-register  {
            margin-top: rem-calc(1);
            margin-bottom: rem-calc(40);

            @include breakpoint (medium down) {
                margin-bottom: rem-calc(32);
            }
        }
    }

    .phone-number-registration-field.right {
        margin-right: rem-calc(16);
    }

    .password-field-with-tooltip {
        width: 100%;

        .info-icon {
            position: absolute;
            top: rem-calc(24);
            right: rem-calc(4);

            .tooltip {
                display: block;
                left: unset;
                right: 0;
                top: rem-calc(10);
            }
        }

        .icon-info-black {
            width: rem-calc(24);
            height: rem-calc(24);
            border: 0;
            display: block;
            line-height: normal;
            font-size: rem-calc(16);
            vertical-align: center;
            text-align: center;
            border-radius: 0;
            cursor: pointer;
        }
    }

    .registration-modal-personal-info {
        .login-subtitle {
            padding-bottom: rem-calc(40);

            @include breakpoint (medium down) {
                padding-bottom: rem-calc(24);
            }
        }

        .required-label {
            text-align: right;
            margin-bottom: 0;
            width: 100%;
            color: map-get($brand-palette, dark-gray);
            font-size: rem-calc(10);
            line-height: rem-calc(16);
        }

        input[type="password"] {
            letter-spacing: rem-calc(8);
        }

        .login-forgot-password-save-access {
            position: relative;
            width: 100%;

            .remember-me-container,
            .boolean-field,
            .rememberme-wrapper {
                position: static;
            }

            .remember-me-container {
                @include breakpoint (medium down) {
                    margin-top: rem-calc(10);
                }

                .info-icon svg {
                    padding: rem-calc(2);
                }
            }

            .slider {
                position: absolute;
                right: 0;
                left: unset;
            }

            .info-icon {
                margin-top: rem-calc(-2);

                @include breakpoint (medium down) {
                    margin-top: 0;
                }
            }

            .checkbox-label {
                padding-left: 0;
            }

            .info-icon {
                .tooltip {
                    display: none;
                }

                &:hover, &:focus {
                    .tooltip {
                        display: block;
                    }
                }
            }
        }
    }

    .email-register {
        margin: rem-calc(8 0);
    }

    .switchToggle {
        input {
            opacity: 0;
        }
    }

    .policy-register-form {
        margin-top: rem-calc(40);
        padding-top: rem-calc(40);
        position: relative;

        @include breakpoint (medium down) {
            margin-top: rem-calc(32);
            padding-top: rem-calc(32);
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: rem-calc(-40);
            right: rem-calc(-40);
            height: rem-calc(1);
            background: #F2EDED;
        }

        .switchToggle {
            @include breakpoint (medium down) {
                width: 100%;

                .sliderToggle {
                    left: unset;
                }
            }
        }

        .cell {
            margin-bottom: 0;
        }

        .spacer-register-20 {
            margin-bottom: rem-calc(24);
        }

        .text-register-privacy-policy a {
            text-transform: unset;
            display: block;
            margin-top: rem-calc(4);
        }

        .fontLittle {
            font-size: rem-calc(12) !important;
        }

        .margin-privacy {
            margin-bottom: rem-calc(40);

            @include breakpoint (medium down) {
                margin-bottom: rem-calc(32);
            }
        }

        .sliderToggle {
            background-color: map-get($brand-palette, light-gray);
        }

        input:checked+.sliderToggle {
            background-color: #010001;
        }
    }

    .policy-promotion-loyalty {
        background-color: map-get($brand-palette, loyalty);
        padding: rem-calc(40);
        margin: rem-calc(0 -40);

        @include breakpoint (medium down) {
            padding: rem-calc(32 16);
            margin: rem-calc(0 -16);
        }

        .switch {
            @include breakpoint (medium down) {
                width: rem-calc(80);
                height: rem-calc(40);
            }
        }

        p, a, label, .boolean-field .checkbox-label, .boolean-field .radio-label, span {
            color: $white;
        }

        label > .loyalty-flag {
            margin-right: 0;
        }

        .layer-login-modal .switch, .login-form-container .switch {
            width: rem-calc(48);
        }
        .login-datepicker {
            margin-top: rem-calc(40);

            @include breakpoint (medium down) {
                margin-top: rem-calc(32);
            }

            input {
                margin-bottom: rem-calc(32);
                border-color: $white;

                &:focus {
                    border-color: $white;
                }

                &::placeholder {
                    color: $white;
                }

                @include breakpoint (medium down) {
                    margin-top: rem-calc(24);
                }
            }

            .input-label {
                font-size: rem-calc(14);
                color: $white;
                transform: none;
                left: 0;
                top: rem-calc(-40);
            }

            input,
            &.focused input {
                color: $white;
                border-color: $white;
            }
        }

        .icon-datepicker {
            position: absolute;
            right: rem-calc(4);
            top: calc(100% - 28px);
        }

        .boolean-field .checkbox-input {
            &:before {
                background-color: transparent;
                border-color: $white;
            }

            &:after {
                background-color: $white;
            }
        }

        .large-icon-width svg {
            margin-top: rem-calc(14);
        }

        .my-login-card-register {
            font-size: rem-calc(20);
            font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
            margin-bottom: rem-calc(4);
            font-weight: 400;

            @include breakpoint (medium down) {
                margin-left: rem-calc(8);
            }
        }

        .advantages-subtitle {
            font-size: rem-calc(14);
            margin-bottom: rem-calc(24);
            font-weight: 600;

            @include breakpoint (medium down) {
                margin-left: rem-calc(8);
            }
        }

        .layer-login-modal .switch, .login-form-container .switch {
            width: rem-calc(48);
        }
    }

    .text-register-condition-sales {
        & {
            margin: rem-calc(40 0 24);

            @include breakpoint (medium down) {
                margin: rem-calc(32 0 16);
            }
        }

        &, a {
            color: map-get($brand-palette, dark-gray);
            font-size: rem-calc(12);
            font-weight: 400;
            line-height: rem-calc(20);
        }

        a.uppercase {
            text-transform: unset;
        }
    }

    .registration-modal-waiting {
        .login-subtitle {
            display: flex;
            align-items: center;
            text-transform: unset;

            i {
                display: inline-block;
                margin-right: rem-calc(8);
                width: rem-calc(24);
                height: rem-calc(24);

                svg {
                    vertical-align: baseline;
                    padding: rem-calc(2);
                }
            }
        }

        .opt-para {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(20);
            margin-bottom: rem-calc(40);

            @include breakpoint (medium down) {
                margin-bottom: rem-calc(32);
            }
        }

        .opt-in-inputs {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: rem-calc(40);

            @include breakpoint (medium down) {
                margin-bottom: rem-calc(32);
            }

            input {
                padding: 0;
                display: inline-block;
                margin: 0 rem-calc(2);
                width: rem-calc(48);
                height: rem-calc(64);
                border-radius: rem-calc(5);
                border: 1px solid $black;
                background-color: $white;
                color: $black;
                text-align: center;
                font-size: rem-calc(24);
                font-weight: 400;
                letter-spacing: rem-calc(0.2);
                text-transform: uppercase;
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            &.invalid {
                input {
                    border-color: $alert;
                }
            }
        }

        .wrong-opt-inserted,
        .expired-opt-inserted {
            margin-top: rem-calc(-16);
            margin-bottom: rem-calc(40);
        }
    }

    .loyalty-flag-cell {
        position: relative;
        margin-top: rem-calc(-20);
    }

    .popover-loyalty-member-clz {
        background-color: $white;
        color: $black;
        padding: rem-calc(8);
        border: rem-calc(1) solid $black;
        border-radius: rem-calc(5);
        z-index: 1;
        position: absolute;
        min-width: rem-calc(200);
        right: 0;
        font-size: rem-calc(10);
        bottom: rem-calc(32);

        p {
            color: $black;
            font-size: rem-calc(10);
            margin-bottom: 0;
            line-height: rem-calc(18);
        }
    }



    .login-form-password {
        margin-bottom: rem-calc(8);
    }

    .loyalty-policy-short-marketing {
        margin-top: rem-calc(8);
        margin-bottom: rem-calc(8);
        display: inline-block;
    }

    .cc-ty {
        &__icon {
            text-align: center;
            margin: 0;

            .cc-icon {
                width: rem-calc(24);
                margin: 0 auto;
                font-size: 0;

                svg {
                    width: rem-calc(24);
                    height: rem-calc(24);
                    padding: rem-calc(2);
                }
            }
        }

        &__header {
            display: flex;
            align-items: center;
            margin: rem-calc(0 0 16);
        }

        &__title {
            display: block;
            text-align: center;
            margin: 0;
            font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
            font-size: rem-calc(20);
            font-weight: 400;
            text-transform: unset;
            padding-left: rem-calc(8);
        }

        &__text {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.04em;
            text-align: left;
            margin: 0;
        }

        .cc-registrationModal__formBtn {
            margin: rem-calc(32 0 0);

            @include breakpoint (large up) {
                margin: rem-calc(40 0 0);
            }
        }
    }

    .date-picker-wrapper {
        display: block;
        transition: all 0.3s ease-in-out;
        opacity: 1;
        max-height: 100%;

        &.hidden {
            opacity: 0;
            max-height: 1px;
        }
    }

    .registration-date-picker {
        margin-top: rem-calc(24);

        select {
            color: $white;
            border-bottom: 1px solid $white;
            background-image: url('../../images/ico-arrow-down-white.svg');
            background-position: right rem-calc(-20) bottom rem-calc(20);
            margin-bottom: rem-calc(24);

            @include breakpoint (large up) {
                margin-bottom: rem-calc(32);
            }

            option {
                color: $black;
            }
        }

        @include breakpoint (large up) {
            margin: rem-calc(32) rem-calc(-8) 0;

            &>.small-4 {
                width: auto;
                flex: 1;
                padding: rem-calc(0 8);
                margin: 0;
            }
        }

        .text-register-condition-sales {
            color: $white;
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(20);
            margin-top: 0;
            margin-bottom: rem-calc(16);
        }
    }
}

.cc-layerLogin {
    &__confirmRegister {
        .cc-titleWrp {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: rem-calc(16);

            .cc-icon {
                font-size: 0;
            }
        }

        &__title {
            font-weight: $global-weight-dark;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: 0;
            padding: rem-calc(0 0 0 16);
        }

        &__text {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: rem-calc(0 0 16);
            padding: 0;
        }
    }
}

.cc-privacyTooltipOpposition{
    &-tooltip{
        width: rem-calc(200);
        position: absolute;
        top: rem-calc(10);
        left: rem-calc(85);
        background: $white;
        padding: rem-calc(8);
        border: rem-calc(1) solid $black;
        border-radius: rem-calc(5);
        transform: translate(rem-calc(-8), calc(-100% - #{rem-calc(16)}));
        transition: opacity .25s ease;
        @include breakpoint (large up){
            left: rem-calc(135);
        }
        &_persistentLogin{
            font-size: rem-calc(10);
            font-weight: $global-weight-normal;
            letter-spacing: normal;
            line-height: rem-calc(18);
        }
        #tooltip-text{
            color: $black;
            margin: 0;
        }
    }
}

.wrapperModal.tingle-modal.registrationAddedModal {
    z-index: 1002;
}
