.ha-hero {

    .content-hero {
        position: relative;

        .content-img {
            .img {
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;

                &.img-desktop {
                    display: block;

                    @include breakpoint (medium down) {
                        display: none;
                    }
                }
                &.img-mobile {
                    display: none;

                    @include breakpoint (medium down) {
                        display: block;
                    }
                }
            }
        }
        .content-text {
            position: absolute;
            z-index: 1;
            width: 100%;
            left: 0;
            bottom: 22%;

            @include breakpoint (medium down) {
                position: relative;
                left: auto;
                bottom: auto;
            }

            .text-box {
                width: 40%;

                @include breakpoint (medium down) {
                    width: 100%;
                    padding-top: rem-calc(10);
                }

                &.text {
                    @include breakpoint (medium down) {
                        &-small {
                            &-left {
                                text-align: left;
                            }
                            &-right {
                                text-align: right;
                            }
                        }
                    }

                    @include breakpoint (large up) {
                        &-large {
                            &-left {
                                text-align: left;
                            }
                            &-right {
                                text-align: right;
                            }
                        }
                    }
                }
            }

            .subtitle {
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                font-weight: $global-weight-light;
                color: gray(1);
                padding: 0;
                padding-bottom: rem-calc(7);
                margin: 0;
                letter-spacing: rem-calc(0.9);

                @include breakpoint (medium down) {
                    font-size: rem-calc(12);
                    padding-bottom: rem-calc(5);
                }
            }

            .title {
                font-size: rem-calc(32);
                line-height: rem-calc(36);
                font-weight: $global-weight-normal;
                color: gray(1);
                padding: 0;
                padding-bottom: rem-calc(20);
                margin: 0;

                @include breakpoint (medium down) {
                    font-size: rem-calc(22);
                    line-height: rem-calc(26);
                }
            }

            .content-buttons {
                .button {
                    padding-left: rem-calc(15);
                    padding-right: rem-calc(15);
                    min-width: rem-calc(160);
                    border: 1px solid gray(1);

                    @include breakpoint (medium down) {
                        min-width: rem-calc(138);
                    }

                    &:first-child {
                        margin-right: rem-calc(6);
                    }
                }
            }
        }
    }

    &.slick-dotted {
        .slick-dots {
            position: absolute !important;
            bottom: rem-calc(30) !important;
            display: flex;
            flex-direction: column;
            top: 40vh;
            left: 90%;
            width: auto;
            @include breakpoint (medium down) {
                top: 25vh;
            }

            li {
                width: auto;
                height: auto;
                margin: rem-calc(5) 0;
                button {
                    height:rem-calc(12);
                    width:rem-calc(12);
                    padding:0;
                    &:before {
                        width: rem-calc(12);
                        height: rem-calc(12);
                        border-radius: rem-calc(12);
                        background: gray(9);
                        margin-top: 0;
                        opacity: 1;
                    }

                }
                &.slick-active {
                    button {
                        &:before {
                            width: rem-calc(12);
                            height: rem-calc(12);
                            border-radius: rem-calc(12);
                            background: $white;
                            margin-top: 0;
                        }
                    }
                }
            }


        }
    }

    &.ha-hero2 {
        .content-hero {
            .content-text {
                .grid-container {
                    overflow: hidden;
                }
                .text-box {
                    width: 50%;
                    float: right;
                    padding: rem-calc(20);
                    background: $white;

                    @include breakpoint (medium down) {
                        width: 100%;
                        float: none;
                        padding: 0;
                        padding-top: rem-calc(10);
                        background: transparent;

                        &-left {
                            padding-top: rem-calc(10);
                        }
                    }
                }
            }
        }
    }
}

.ha-editorial {
    background-color: #F9F9F9;
    padding: rem-calc(70 0);
    /*.editorial-container {
        width: 100%;
        max-width: rem-calc(1000);
        margin: 0 auto;
    }*/

    @include breakpoint (medium down) {
        padding: rem-calc(30 0);
    }
    .medium-33 {
        @include breakpoint (medium up) {
            width: calc(33.3333333% - 1.71429rem);
        }
    }
    .large-33 {

        @include breakpoint (medium down) {
            margin-bottom: rem-calc(70);

            &:last-child{
                margin-bottom: rem-calc(30);
            }
        }

        @include breakpoint (large up) {
            width: calc(33.3333333% - 2.57143rem);
        }
    }

    .item {

        .content-img {
            margin-bottom: rem-calc(30);

            img {
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;
            }
        }

        .category {
            display: block;
            font-size: rem-calc(16);
            font-weight: $global-weight-light;
            color: gray(1);
            padding: 0;
            margin: 0;
            letter-spacing: rem-calc(0.9);
            text-align: center;

            @include breakpoint (medium down) {
                font-size: rem-calc(14);
            }
        }

        .title {
            display: block;
            font-size: rem-calc(20);
            line-height: rem-calc(26);
            font-weight: 500;
            color: gray(1);
            padding: 0;
            padding-bottom: rem-calc(20);
            margin: 0;
            text-align: center;

            @include breakpoint (medium down) {
                padding-bottom: rem-calc(20);
            }
        }

        .content-button {
            text-align: center;

            .button {
                font-size: rem-calc(13);
                font-weight: 500;
                margin-bottom: 0;
                padding: rem-calc(12 55);
            }
        }
    }
}
html[lang="en-WS"]{
    .ha-editorial {
        background-color: white;
    }
}


.ha-mood {
    .medium-25 {
        @include breakpoint (medium up) {
            width: calc(25% - 2.57143rem);
        }
    }
    .large-25 {
        @include breakpoint (large up) {
            width: calc(25% - 2.57143rem);
        }
    }

    .section-title {
        display: block;
        text-align: center;
        font-size: rem-calc(32);
        line-height: rem-calc(46);
        font-weight: $global-weight-normal;
        color: $black;
        padding-bottom: rem-calc(14);
        margin: 0;

        @include breakpoint (medium down) {
            text-align: center;
            padding-bottom: rem-calc(25);
        }
    }

    .item {
        margin-bottom: 0;
        position: relative;
        display: block;

        .content-img {
            margin-bottom: 0;

            img {
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;
            }
        }

        .title {
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            padding: rem-calc(13);
            width: 100%;
            text-align: left;
            background: -moz-linear-gradient(top, rgba(38, 38, 38, 1) 0%, rgba(38, 38, 38, 0) 100%);
            background: -webkit-linear-gradient(top, rgba(38, 38, 38, 1) 0%, rgba(38, 38, 38, 0) 100%);
            background: linear-gradient(to bottom, rgba(38, 38, 38, 1) 0%, rgba(38, 38, 38, 0) 100%);
            -webkit-transition: all .3s ease-out;
            -moz-transition: all .3s ease-out;
            -o-transition: all .3s ease-out;
            -ms-transition: all .3s ease-out;
            transition: all .3s ease-out;

            .text {
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                text-transform: uppercase;
                font-size: rem-calc(16);
                line-height: rem-calc(23);
                font-weight: $global-weight-bold;
                color: $white;
                padding: 0;
                margin: 0;
                margin-right: rem-calc(3);
            }

            img {
                display: inline-block;
                vertical-align: middle;
                width: rem-calc(13);
                height: rem-calc(10);
            }
        }

        &:hover .title {
            padding-left: rem-calc(21);
        }
    }
}



.ha-slider-prodotti {
    position: relative;

    .loyalty-msg {
        margin-top: rem-calc(8);
    }

    .promo-message {
        margin-top: rem-calc(8);
    }

    &.cart-filmstrip {
        padding: rem-calc(35) 0 0 0;

        .slider-prodotti-container{
            .section-title{
                padding: rem-calc(0) !important;
                margin-bottom: rem-calc(32) !important;
                @include breakpoint (small down) {
                    margin-bottom: rem-calc(24) !important;
                }
            }
        }
    }

    &.with-background {
        background-color: #F6F4F6;
    }

    * {
        outline: none;
    }

    .img-background {
        position: absolute;
        z-index: 1;
        height: 100%;
        max-width: none;
        min-width: 100%;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .slider-prodotti-container {
        position: relative;
        z-index: 2;
        .slider-arrows-special .slick-prev {
            top: rem-calc(-45);
            right: rem-calc(45);
            left: inherit;
            width: rem-calc(25);
            @include breakpoint (medium down) {
                right: rem-calc(41);
                width: rem-calc(6);
                height: rem-calc(10);
                top: rem-calc(-40);
            }
            &::before {
                content: url('../images/slider-left.svg');
                @include breakpoint (medium down) {
                    content: url('../images/arrow-left-mobile.svg');
                }
            }
        }
        .slick-next {
            top: rem-calc(-45);
            right: rem-calc(15);
            width: rem-calc(25);
            @include breakpoint (medium down) {
                width: rem-calc(6);
                height: rem-calc(10);
                top: rem-calc(-40);
            }
            &::before {
                content: url('../images/slider-right.svg');
                @include breakpoint (medium down) {
                    content: url('../images/arrow-right-mobile.svg');
                }
            }
        }
    }
    &.complete {
        .section-title {
            color: #262626;
            display: inline-block;
            font-size: rem-calc(40);
            line-height: rem-calc(35);
            font-weight: 400;
            text-transform: unset;
            padding-bottom: rem-calc(0) !important;
            @include breakpoint (medium down) {
                text-align: left;
                font-size: rem-calc(13);
                line-height: rem-calc(20);
                font-weight: 500;
                padding-bottom: rem-calc(0) !important;
            }
        }
        .contnet-navs {
            .slider-nav-prev-desk {
                left: rem-calc(16);
                top: 40%;
                @include breakpoint (medium down) {
                    display: none;
                }
                width: rem-calc(24);
                height: rem-calc(24);
            }
            .slider-nav-next-desk {
                right: rem-calc(16);
                top: 40%;
                @include breakpoint (medium down) {
                    display: none;
                }
                width: rem-calc(24);
                height: rem-calc(24);
            }
            .slider-nav-next-mob {
                right: 0;
            }
            .slider-nav-prev-mob {
                right: rem-calc(20);
            }
        }

        .productDescription{
            margin-bottom: rem-calc(48);
        }
    }

    .section-title {
        display: block;
        text-align: left;
        font-size: rem-calc(32);
        line-height: rem-calc(46);
        font-weight: $global-weight-normal;
        color: $black;
        padding-bottom: rem-calc(14);
        margin: 0;

        @include breakpoint (medium down) {
            text-align: center;
            padding-bottom: rem-calc(25);
        }
    }

    .content-slider {
        margin-top: rem-calc(8) ;
        position: relative;

        .slider-nav {
            position: absolute;
            top: rem-calc(-45);
            right: rem-calc(15);
            display: block;
            z-index: 4;
            width: rem-calc(25);
            height: rem-calc(25);
            cursor: pointer;

            @include breakpoint (medium down) {
                width: rem-calc(6);
                height: rem-calc(10);
                top: rem-calc(-39);
            }

            &.slider-nav-prev {
                right: rem-calc(45);

                @include breakpoint (medium down) {
                    right: rem-calc(41);
                }
            }

            &.slider-nav-prev-desk,
            &.slider-nav-next-desk {
                display: block !important;

                @include breakpoint (medium down) {
                    display: none !important;
                }
            }

            &.slider-nav-prev-mob,
            &.slider-nav-next-mob {
                display: none !important;
            }

            &.slick-disabled {
                cursor: default;
                opacity: 0.5;
                display: none;
            }
        }
    }

    .slider {
        margin: rem-calc(0 -4);

        .item {
            position: relative;
            background: $white;

            .item-link {
                display: block;
                margin: 0;
                text-decoration: none;
            }

            .content-img {
                margin-bottom: 0;

                img {
                    display: block;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }
            }
            .product {
                .tile-body {
                    padding: rem-calc(16 10 0 10);
                    height: auto;
                    @include breakpoint (medium down){
                        padding-bottom: 0;
                    }
                    .productDescription-name{
                        margin-bottom: rem-calc(8);
                    }
                    .productDescription-priceRow{
                        @include breakpoint (medium down){
                            margin-bottom: 0;
                        }
                    }
                    .plp-tag, .new-message {
                        display: none;
                    }
                }
                .pdp-link {
                    height: rem-calc(19);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .price-container {
                    font-size: rem-calc(12) !important;
                    line-height: rem-calc(17);

                    .strike-through {
                        display: inline-block;
                        .value {
                            line-height: rem-calc(12);
                        }
                    }
                }
                .tile-colors {
                    .color-swatches {
                        .plp-swatch {
                            margin-top: rem-calc(10);
                            font-size: rem-calc(9);
                            letter-spacing: rem-calc(0.875);
                            @include breakpoint(medium down) {
                                margin-top: rem-calc(5);
                            }
                        }
                    }
                }
            }


            .product-name {
                display: block;
                text-align: left;
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                font-weight: $global-weight-light;
                color: $black;
                padding: 0;
                margin: 0;
                text-decoration: none !important;

                @include breakpoint (medium down) {
                    font-size: rem-calc(13);
                    line-height: rem-calc(16);
                }
            }

            .product-price {
                display: block;
                text-align: left;
                font-size: rem-calc(12);
                line-height: rem-calc(17);
                font-weight: $global-weight-bold;
                color: $black;
                padding: 0;
                padding-top: rem-calc(6);
                margin: 0;
            }
        }

        &:hover .wishlist {
            display: block;

            @include breakpoint (medium down) {
                display: none;
            }
        }

        .wishlist {
            display: none;
            background: rgba(255, 255, 255, 0.9);
            position: absolute;
            z-index: 3;
            bottom: rem-calc(100);
            left: rem-calc(12);
            width: calc(100% - 24px);
            overflow: hidden;
            text-align: left;
            padding: rem-calc(18) 0;

            .content-icon-label {
                display: block;
                float: left;
                font-size: 0;

                .icon-cart {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: rem-calc(18);
                    margin-right: rem-calc(12);
                    width: rem-calc(13);
                    height: rem-calc(14);
                }

                .label {
                    display: inline-block;
                    vertical-align: middle;
                    text-align: left;
                    font-size: rem-calc(14);
                    line-height: rem-calc(14);
                    text-transform: uppercase;
                    font-weight: $global-weight-bold;
                    color: $black;
                }
            }

            .icon-wishlist {
                display: block;
                float: right;
                width: rem-calc(13);
                height: rem-calc(14);
                margin-right: rem-calc(12);
            }
        }
    }
    .wishlist-heart-position {
        position: absolute;
        top: rem-calc(10);
        right: rem-calc(10);
        margin-right: 0;
        width: rem-calc(14);
        height: rem-calc(14);
    }
}

.prefooter-category-thanks .ha-category{
    .large-33{
        @include breakpoint (large up) {
            width: calc(33.3333333% - 0.57143rem);
        }
    }

    .item .title{
        width: 100%;
        margin-top: 0px;
    }
}

.ha-category {
    margin-top: rem-calc(57);

    .medium-33 {
        @include breakpoint (medium up) {
            width: calc(33.3333333% - 1.71429rem);
        }
    }
    .large-33 {
        @include breakpoint (large up) {
            width: calc(33.3333333% - 2.57143rem);
        }
    }

    .col-title {
        width: 25%;
        float: left;
        padding-right: rem-calc(40);
        direction: rtl;

        @include breakpoint (medium down) {
            width: 100%;
            float: none;
            display: none;
        }

        .title {
            display: block;
            text-align: left;
            font-size: rem-calc(40);
            line-height: 1;
            font-weight: $global-weight-normal;
            color: gray(1);
            margin: 0;
            padding: 0;
            text-transform: unset;

            @include breakpoint (medium down) {
                font-size: rem-calc(22);
            }
        }
    }

    .col-category {
        width: 75%;
        float: right;

        @include breakpoint (medium down) {
            width: 100%;
            float: none;
        }

        .hide-mobile {
            @include breakpoint (medium down) {
                display: none !important;
            }
        }
    }

    .item {
        display: block;
        margin-bottom: rem-calc(16);

        .content-img {
            margin-bottom: 0;
            position: relative;
            z-index: 1;

            img {
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;
            }
        }

        .title {
            position: relative;
            z-index: 2;
            margin-top: rem-calc(0);
            width: calc(100% - 20px);
            padding: rem-calc(16 2 16 0);
            background: $white;
            -webkit-transition: all .3s ease-out;
            -moz-transition: all .3s ease-out;
            -o-transition: all .3s ease-out;
            -ms-transition: all .3s ease-out;
            transition: all .3s ease-out;

            @include breakpoint (medium down) {
                width: calc(100% - 10px);
                padding: rem-calc(24 0);
            }

            .text {
                text-align: left;
                display: block;
                font-size: rem-calc(20);
                line-height: rem-calc(25);
                font-weight: $global-weight-normal;
                color: $black;
                padding: 0;
                margin: rem-calc(0 5 8 0);

                @include breakpoint (medium down) {
                    font-size: rem-calc(20);
                    line-height: rem-calc(19);
                }
            }

            img {
                display: none; // hide duplicated arrows
                vertical-align: middle;
                width: rem-calc(13);
                height: rem-calc(10);
                margin-top: rem-calc(2);
            }

            .cm-arrow-cta-button{
                font-size: rem-calc(16);
                font-family: 'SpeziaExtended';
                font-weight: 600;
                &::after{
                    content: url('../images/cta_arrow.svg');
                    width: rem-calc(50);
                    height: rem-calc(24);
                    margin-left: rem-calc(24);
                    transition: .25s linear;
                    filter: invert(1);
                }
                &:hover{
                    &::after{
                        transform: translateX(10px);
                    }
                }
            }
        }

        &.item-title-mobile {
            position: relative;

            .content-img{
                @include breakpoint (medium down) {
                    display: none;
                }
            }

            .title {
                opacity: 0;
                @include breakpoint (medium down) {
                    display: none;
                }
            }

            .title-mobile {
                display: block;
                text-align: left;
                font-size: rem-calc(20);
                line-height: rem-calc(26);
                font-weight: $global-weight-normal;
                color: gray(1);
                margin: 0;
                padding: 0;
                @media screen and (max-width: 340px) {
                    font-size: rem-calc(20);
                }
            }
        }

        &.item-scopri-tutti {
            position: relative;

            .content-text {
                position: absolute;
                width: 100%;
                z-index: 2;
                left: 0;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);

                .text-title {
                    text-align: center;
                    display: block;
                    font-size: rem-calc(32);
                    line-height: rem-calc(36);
                    font-weight: $global-weight-normal;
                    color: gray(1);
                    padding: 0;
                    padding-bottom: rem-calc(35);
                    margin: 0;

                    @include breakpoint (medium down) {
                        padding-bottom: rem-calc(20);
                        font-size: rem-calc(20);
                        line-height: rem-calc(25);
                    }
                }

                .cta {
                    text-align: center;
                    -webkit-transition: all .3s ease-out;
                    -moz-transition: all .3s ease-out;
                    -o-transition: all .3s ease-out;
                    -ms-transition: all .3s ease-out;
                    transition: all .3s ease-out;

                    .text {
                        text-align: left;
                        display: inline-block;
                        vertical-align: middle;
                        font-size: rem-calc(20);
                        line-height: rem-calc(25);
                        font-weight: $global-weight-normal;
                        color: $black;
                        padding: 0;
                        margin: 0;
                        margin-right: rem-calc(5);

                        @include breakpoint (medium down) {
                            font-size: rem-calc(16);
                            line-height: rem-calc(24);
                        }
                    }

                    img {
                        display: inline-block;
                        vertical-align: middle;
                        width: rem-calc(13);
                        height: rem-calc(10);
                        margin-top: rem-calc(2);
                    }
                }
            }

            &:hover {
                .content-text .cta {
                    padding-left: rem-calc(14);
                }
            }
        }
    }
}



.ha-newsletter {
    .container-newsletter {
        position: relative;
        overflow: hidden;
        background: gray(8);

        .content-img {
            display: block;
            margin: 0;
            padding: 0;

            .img {
                display: block;
                margin: 0;
                padding: 0;
                width: 100%;
            }

            @include breakpoint (medium down) {
                display: none;
            }
        }

        .content-text {
            position: absolute;
            width: 100%;
            max-width: rem-calc(480);
            z-index: 2;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background: $white;
            padding: rem-calc(40 15);
            text-align: center;

            @include breakpoint (medium down) {
                background: transparent;
                position: relative;
                top: auto;
                left: auto;
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
                transform: none;
                max-width: none;
                padding: rem-calc(50 15);
            }

            .title {
                display: block;
                text-align: center;
                font-size: rem-calc(32);
                line-height: rem-calc(36);
                font-weight: $global-weight-normal;
                color: gray(1);
                margin: 0;
                padding: 0;
                padding-bottom: rem-calc(13);
            }

            .text {
                display: block;
                text-align: center;
                font-size: rem-calc(18);
                line-height: rem-calc(26);
                font-weight: $global-weight-normal;
                color: gray(1);
                margin: 0 auto;
                padding: 0;
                padding-bottom: rem-calc(30);
                max-width: rem-calc(360);
            }

            .content-button {
                text-align: center;
            }
        }
    }
}

.prodRecommendationContainer {
    padding-right: rem-calc(40);
    padding-right: rem-calc(40);

    @include breakpoint (medium down) {
        padding: rem-calc(35 18);
        .recently-viewed-recommendation-title{
            font-weight: 500;
            font-size: rem-calc(14);
        }
    }
    @include breakpoint (small down) {
        padding: rem-calc(35 28);
    }
}


.complete-tile-recommendation,
.complete-tile {
    .arrows__wrapper {
        display: flex;
        gap: rem-calc(20);
        justify-content: flex-end;
        margin-bottom: rem-calc(32);
    }
    .einstein-carousel {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: rem-calc(20);
        justify-content: flex-start;
        overflow: hidden;

        .product {
            width: rem-calc(318);
        }
    }
}


.ha-other-consumers {
    .container-other-consumers {
        padding: rem-calc(30 15 20 15);

        .content-text {
            width: 100%;
            text-align: center;

            .title {
                display: block;
                text-align: center;
                font-size: rem-calc(32);
                line-height: rem-calc(36);
                font-weight: $global-weight-normal;
                color: gray(1);
                margin: 0;
                padding: 0;
                padding-bottom: rem-calc(2);
            }

            .text {
                display: block;
                text-align: center;
                font-size: rem-calc(18);
                line-height: rem-calc(26);
                font-weight: $global-weight-normal;
                color: gray(1);
                margin: 0 auto;
                padding: 0;
                padding-bottom: rem-calc(40);
                max-width: rem-calc(360);
            }

            .content-button {
                text-align: center;

                .button:nth-child(2) {
                    margin-left: rem-calc(11);
                }
            }
        }
    }
}

/*
.ha-prehome {
    .container-prehome {
        position: relative;

        .content-img {
            display: block;
            margin: 0;
            padding: 0;

            .img {
                display: block;
                margin: 0;
                padding: 0;
                width: 100%;

                &.img-desktop {
                    display: block;

                    @include breakpoint (medium down) {
                        display: none;
                    }
                }
                &.img-mobile {
                    display: none;

                    @include breakpoint (medium down) {
                        display: block;
                    }
                }
            }
        }

        .content-text {
            position: absolute;
            width: 100%;
            max-width: rem-calc(480);
            z-index: 2;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            text-align: center;
            padding: rem-calc(0 20);

            .title {
                display: block;
                text-align: center;
                font-size: rem-calc(34);
                line-height: rem-calc(40);
                font-weight: $global-weight-bold;
                color: $white;
                margin: 0;
                padding: 0;
                padding-bottom: rem-calc(15);

                @include breakpoint (medium down) {
                    font-size: rem-calc(32);
                    line-height: rem-calc(36);
                    padding-bottom: rem-calc(10);
                }
            }

            .subtitle {
                display: block;
                text-align: center;
                font-size: rem-calc(20);
                line-height: rem-calc(29);
                font-weight: $global-weight-dark;
                text-transform: none;
                letter-spacing: rem-calc(0.2);
                color: $white;
                margin: 0;
                padding: 0;
                padding-bottom: rem-calc(30);

                @include breakpoint (medium down) {
                    font-size: rem-calc(18);
                    line-height: rem-calc(26);
                    padding-bottom: rem-calc(10);
                }
            }

            .content-button {
                text-align: center;

                .button {
                    color: gray(1);
                    background: $white;
                    margin: rem-calc(0 5);
                    padding-left: rem-calc(15);
                    padding-right: rem-calc(15);
                    min-width: rem-calc(108);

                    @include breakpoint (medium down) {
                        display: block;
                        margin: rem-calc(15) auto;
                        max-width: rem-calc(180);
                    }
                }
            }
        }
    }
} */

.product-tile:hover .hover-info-bag-wishlist {
    @include breakpoint (large up) {
        display: inline-block;
    }
}

.hover-info-bag-wishlist {
    display: none;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    z-index: 3;
    bottom: rem-calc(15);
    left: rem-calc(12);
    width: calc(100% - 24px);
    overflow: hidden;
    text-align: left;
    padding: rem-calc(15 18);
    .wishlist-heart-on-right {
        float: right;
    }
    .add-to-bag {
        font-size: rem-calc(14);
        vertical-align: middle;
    }
}

.grid-100 {
    width: 100%;
}

.livestory-box{
    width: 100%;
    text-align: center;
    margin: rem-calc(20 0 40);
    padding: rem-calc(0 40px);

    h3{
        font-size: rem-calc(20);
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: rem-calc(3);
        margin: 0 auto;
        line-height: rem-calc(26);

        span{
            font-weight: $global-weight-bold;
        }
    }

    p{
        font-size: rem-calc(16);
        margin: rem-calc(10) auto rem-calc(20);
    }

    .button{
        font-size: rem-calc(13);
        font-weight: 500;
        margin-bottom: 0;
        padding: rem-calc(12 55);
    }
}

.youMayAlsoLikeContainer {
    @include breakpoint (large up) {
        margin-top: rem-calc(40);
    }

    .page[data-action="Cart-Show"] & {

        @include breakpoint (large up) {
            margin-top: rem-calc(80);
        }

        @include breakpoint (medium down) {
            margin-top: rem-calc(40);
        }

        .recommendation-full-wrapper {
            margin-bottom: rem-calc(32);
    
            .title {
                margin-left: rem-calc(40);
            }

            .arrows__wrapper {
                right: rem-calc(40);
            }

            .content-slider {
                margin-top: unset;

                .recommendation-carousel__cards {
                    gap: unset;
                    padding-right: rem-calc(40);
                    padding-left: rem-calc(40);

                    .product {
                        width: unset;

                        .productDescription {
                            margin-bottom: 0;
                        }
                    }

                    &::-webkit-scrollbar {
                        display: none;  // Chrome
                    }
                    scrollbar-width: none;  // Firefox
                    
                }
            }

            @include breakpoint (medium down) {
                margin-bottom: rem-calc(-8);

                .title {
                    margin-left: rem-calc(15);
                }

                .content-slider {

                    .recommendation-carousel__cards {
                        padding-right: rem-calc(16);
                        padding-left: rem-calc(16);
    
                        .cart-recommendation-quick-add {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .productDescription-color-label {
        display: none;
    }
}

.recommendation-full-wrapper {
    .loyalty-msg {
        margin-top: rem-calc(8);
    }

    .promo-message {
        margin-top: rem-calc(8);
    }
}
