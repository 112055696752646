$promoPlp : #4D824E;
.loyalty-msg {
    color: $loyalty;
    font-weight: $global-weight-bold;
    font-size: rem-calc(12);
    line-height: rem-calc(14);
    text-transform: uppercase;
    margin-right: rem-calc(3);
    @include breakpoint(large up) {
        margin-top: rem-calc(4);
    }
}

.promo-info-icon {
    width: rem-calc(11);
    height: rem-calc(11);
    display: inline-flex;
    border: 1px solid;
    text-align: center;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: $promoPlp;
    cursor: pointer;
    font-size: rem-calc(8);
    font-weight: 600;
    letter-spacing: 0;
    text-transform: lowercase;
    margin-bottom: rem-calc(2);
    line-height: 18px;
    span{
        color: $promoPlp;
    }
}
