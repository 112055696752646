html {
    overflow-x: hidden;
}

::selection {
    background-color: $black;
    color: $white;
}

.page {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    position: relative;

    .grid-container {
        flex-grow: 1;
        width: 100%;
    }

    &[data-action="Cart-Show"] {
        min-height: initial;
    }
}

/*
.black-top-banner {
    display: none;
    .grid-container {
        max-width: 84.773vw;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 auto !important;
    }
    // position: fixed;
    // top: rem-calc(90);
    // left: 0;
    // right: 0;
    // width: 100%;
    // z-index: 3;
    // @include breakpoint(small down) {
      //   top: rem-calc(60);
    // }
     .header-message-2{
        background: $black;
        color: $white;
        text-align: center;
        padding: rem-calc(8.1 0);
        min-height: rem-calc(35);
        height: auto;

         .close-button-2 {
            color: $white;
            position: absolute;
            right: calc((-15.227vw / 2) + 20px);
            top: 50%;
            margin-top: rem-calc(-2);
            transform: translateY(-50%);
            cursor: pointer;
            width: rem-calc(8);
        }
    
        a {
            color: $white;
        }
    
        p {
            width: 84.773vw !important;
            margin-bottom: 0;
            font-size: rem-calc(13);
            padding: rem-calc(0 60);
            line-height: rem-calc(19);
            @include breakpoint(small down) {
                padding: rem-calc(0 20);
                font-size: rem-calc(12);
        }
        }
        .cnt-sovra-slider {
            .cnt-sovra-frame {
                width: 100% !important;
            }
        }
    }}
*/

.promo-details-popup-modal {
    .body {
        .closeAction {
            margin-left: rem-calc(15);
            height: fit-content;
            cursor: pointer;
        }
        display: flex;
    }
    .actions {
        .button {
            margin-bottom: rem-calc(0);
        }
    }
    @media screen and (max-width: 522px)  {
        .tingle-modal-box {
            .tingle-modal-box__content {
                padding: rem-calc(16) rem-calc(16);
            }
        }
        .actions {
            margin-top: rem-calc(24);

                .registration {
                margin-bottom: rem-calc(12);
            }
        }
    }
    @media screen and (min-width: 523px)  {
        .tingle-modal-box {
            .tingle-modal-box__content {
                padding: rem-calc(40) rem-calc(40);
            }
        }
        .actions {
            display: flex;
            margin-top: rem-calc(40);

            .registration {
                margin-right: rem-calc(16);
            }
        }
    }
    @media screen and (max-width: 767px)  {
        .tingle-modal-box {
            width: calc(100% - 32px);
        }
    }
    @media screen and (min-width: 768px)  {
        .tingle-modal-box {
            width: rem-calc(520);
        }
    }
}
