/*$slide-transition: 350ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

.is-entering {
    animation: slidein $slide-transition;
}

.should-enter {
    transform: translateX(-100%);
}

.should-leave {
    transform: translateX(0%);
}

.is-leaving {
    animation: slideout $slide-transition;
}

@keyframes slidein {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes slideout {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}
*/

.dynamic-arrow {
    transition: all 0.2s ease-in-out 0.1s;
    &:hover {
        margin-left: rem-calc(-5)
    }
}
