.prefooter {
    
    .grid-container {
        min-width: 100%;
    }

    .content-cols {
        margin-left: -1.28571rem;
        margin-right: -1.28571rem;
        @include breakpoint (small down) {
            padding: rem-calc(20 0 30 0);
        }

        &:before,
        &:after {
            content: " ";
            display: table;
        }
        &:after {
            clear: both;
        }

        @include breakpoint (small down) {
            margin-left: -.85714rem;
            margin-right: -.85714rem;
        }

        .col {
            margin-left: 1.28571rem;
            margin-right: 1.28571rem;
            width: calc(25% - 2.57143rem);
            float: left;

            @include breakpoint (small down) {
                margin-left: .85714rem;
                margin-right: .85714rem;
                width: calc(50% - 1.71428rem);
            }

            &.col-special {
                @include breakpoint (small down) {
                    width: calc(100% - 1.71428rem);
                    margin-top: rem-calc(50);
                }
            }
        }
    }

    &.prefooter-world {
        .content-cols {
            .col {
                width: calc(33% - 2.57143rem);
            }
        }
    }

    .content-items {
        padding: rem-calc(33 0 45 0);
        overflow: hidden;

        @include breakpoint (small down) {
            padding: 0;
        }

        .item {
            display: block;
            text-decoration: none;
            color: gray(1);
            font-weight: $global-weight-regular;
            font-size: rem-calc(16);
            line-height: rem-calc(23);
            letter-spacing: rem-calc(0.2);
            margin-bottom: rem-calc(7);
            &:hover{
                text-decoration: underline;
            }

            &.item-title {
                font-weight: $global-weight-dark;
                font-size: rem-calc(18);
                line-height: rem-calc(26);
                margin-bottom: rem-calc(11);

                @include breakpoint (small down) {
                    margin-top: rem-calc(10);
                }
            }
        }

        .special-item {
            overflow: hidden;
            display: block;
            margin-bottom: rem-calc(29);

            @include breakpoint (small down) {
                width: calc(33.33333334%);
                float: left;
                padding: rem-calc(0 10);
                margin-bottom: rem-calc(20);
            }

            .content-icon {
                width: rem-calc(30);
                float: left;

                @include breakpoint (small down) {
                    width: 100%;
                    float: none;
                    margin-bottom: rem-calc(10);
                }

                .icon {
                    display: block;
                    width: rem-calc(20);

                    @include breakpoint (small down) {
                        margin: 0 auto;
                    }
                }
            }

            .content-text {
                width: calc(100% - 30px);
                float: right;

                @include breakpoint (small down) {
                    width: 100%;
                    float: none;
                }

                .title {
                    display: block;
                    font-weight: $global-weight-bold;
                    font-size: rem-calc(14);
                    line-height: rem-calc(16);
                    text-transform: uppercase;
                    padding-bottom: rem-calc(5);
                    margin-top: rem-calc(3);
                    letter-spacing: rem-calc(0.5);

                    @include breakpoint (small down) {
                        font-size: rem-calc(10);
                        line-height: rem-calc(14);
                        text-align: center;
                    }
                }

                .text {
                    display: block;
                    font-weight: $global-weight-regular;
                    font-size: rem-calc(12);
                    line-height: rem-calc(16);

                    @include breakpoint (small down) {
                        font-size: rem-calc(10);
                        line-height: rem-calc(14);
                        text-align: center;
                    }
                }
            }
        }
    }

}