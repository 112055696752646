@import "../setup/settings";

$slide-out-animation-timing: 0.4s cubic-bezier(0.85, 0.15, 0.15, 0.85);
$slide-out-animation: left $slide-out-animation-timing,
right $slide-out-animation-timing;

.menu {
    flex-direction: column; /* Necessario per sovrascrivere foundation */
}

.category-image {
    @include breakpoint(medium up) {
        display: none;
    }
    width: rem-calc(57);
    vertical-align: middle;
    height: rem-calc(82);
    object-fit: cover;
    margin-right: rem-calc(24);
    &.placeholder {
        border: 0;
    }
}

.category-content {
    height: rem-calc(60);
    width: rem-calc(188);
    line-height: rem-calc(60);
    text-align: center;
}

$menu-border-gray: #ECECEC;

// Mixins
@include breakpoint(medium down) {
    .navbar-toggleable-small {
        background-color: $white;
        position: fixed;
        right: -100%;
        top: 0;
        transition: $slide-out-animation;
        display: block;
        z-index: 1;

        &.in {
            right: 0;
        }
        /*
        .footer-items .hide {
            display: inline-block !important;
        }
        */
    }
}

.menu-toggleable-left {

    // Mobile
    @include breakpoint(medium down) {
        background-color: $white;
        z-index: 900;
        background: transparent;
        height: 100%;


        .dropdown {
            display: block;
        }
        .last-level {
            margin-left: 0;
        }

        .dropdown-item {

            .nav-link,
            .dropdown-link {
                padding: rem-calc(6) 0;
                font-size: rem-calc(16);
                color: #000;
            }

            @include breakpoint(small down) {
                &:last-child {
                    .dropdown-link {
                        border-bottom: none;
                    }
                }
            }
        }

        .level-3:first-child {
            @include breakpoint(medium down) {
                a {
                }
            }
        }
    }

    // Desktop
    .dropdown-menu {
        @include breakpoint(large up) {
            right: 0;
            top: 0;
            width: 100%;
            border: 0 none;
            transition: $slide-out-animation;
            overflow: hidden;
            padding-left: rem-calc(10);

            &.show {
                height: auto;
                padding-top: rem-calc(4);
                padding-bottom: rem-calc(4);
            }
        }
    }
}

.menu-secound-asset-image {
    @include breakpoint(large only) {
        display: none!important;
    }
}
/*
.go-back-static {
    display: inline-block;
    margin-left: -32px;
    margin-right: 9px
}
*/

@include breakpoint(medium down){
    .overflow-override-auto {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .menu-bottom {
        display: block;
    }

}

.menu-outer-container {
    height: inherit;

    @include breakpoint (large up) {
        display: flex;
        flex-direction: column;
        padding-right: rem-calc(40);
    }

    @include breakpoint (small down){
        max-height: 100%!important;
    }

    @include breakpoint (large down) {
        height: 100%;
        max-height: -webkit-fill-available;
        width: 100%;
        overflow-y: auto;
        /*
        .bottom-section-menu {
            a {
                font-size: rem-calc(14);
                font-weight: 500;
                line-height: rem-calc(17);
                display: flex;
                text-transform: uppercase;
                img {
                    width: 1.2rem;
                    margin-right: rem-calc(16);
                }
            }
        }
        */
        .promotion-asset {
            display: none;
        }
    }
    /*
    @include breakpoint (small down) {
        .bottom-section-menu {
            a {
                height: rem-calc(60);
                margin: rem-calc(19.7 0);
                background-color: black;
                padding: rem-calc(16 0 16 16);
                color: white;
            }
        }
    }
    */
}

/*
.line-menu-divider {
    border-color: #E6E6E6;
    width: 100%;
    max-width: unset;
}
*/


//mobile menu/submenu handling
@include breakpoint(small down){
    .menu-overflow-state-1 {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .menu-overflow-state-2 {
        overflow: hidden;
        .js-open-tab-menu:not(.active),
        .divider-menu,
        .category-menu-slots,
        .card.bottom-border-none.title-container,
        .js-open-tab-menu.active .underline-from-center{
            visibility: hidden;
        }
    }

    .menu-display-enable {
        display: block;
    }

    .menu-display-disable {
        display: none;
    }
}

.multilevel-dropdown {
    ul {
        list-style-type: none;
    }

    .dropdown-menu {
        border: 0;
        border-radius: 0;
        margin-left: 0;
        z-index: 1;

        @include breakpoint(medium down) {
            position: static;
        }
    }

    .dropdown-menu>.dropdown>.dropdown-menu {
        @include breakpoint(large up) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar>.close-menu .back {
        display: none;

        &.show {
            display: block;
        }
    }

    .close-menu .back {
        svg {
            height: rem-calc(14);
        }
    }

    .dropdown-item {
        margin-right: 3.5vw;
        margin-bottom: rem-calc(6);
        @include breakpoint(medium down) {
            padding-left: rem-calc(16);
            margin-right: rem-calc(25);
        }

        &.level-4 {
            margin-bottom: rem-calc(6);
        }
    }
}

//....................menu-initimissimi................
.hover-underline-menu {
    width: 100%;

    @include breakpoint(large up) {
        line-height: rem-calc(51);

        &::before,
        &::after {
            width: 100%;
            display: block;
            height: rem-calc(1);
            background: #E8E8E8;
            content: '';
            position: absolute;
            margin-left: 0;
            left: 0;
            margin-top: rem-calc(-1);
        }
    }

    @include breakpoint(small down) {
        background: rgba(255, 255, 255, 0.75);
        backdrop-filter: blur(40px);
    }

    @include breakpoint(small down) {
        & .active .tab-submenu {
            position: absolute;
            top: 0;
            left: 0;
            //height: 105%;
            //height: 100vh;
            z-index: 9999;
        }
    }

    .tab-submenu {
        width: 100%;
        min-height: rem-calc(391);
        display: none;

        @include breakpoint(small down) {
            left: -100%;
            transition: all .2s 0s;
        }

        &.active {
            display: block;
            background: rgba(255,255,255,0.75);
            backdrop-filter: blur(25px);
        }

        .content-tab {
            margin-left: auto;
            margin-right: auto;
            width: 100%;

            @include breakpoint(medium up) {
                padding-left: rem-calc(2);
                height: 100%;
                .hide-images-for-large {
                    .category-image {
                        display: none;
                    }
                }
            }
            
            .menu-carousel-promo-mobile-item {
                position: relative;
                height: rem-calc(190);
                margin: rem-calc(0) rem-calc(4);
            }

            .menu-carousel-promo-mobile-title {
                color: $white;
                font-family: $secondary-font;
                font-size: rem-calc(16);
                font-weight: 600;
                left: 8px;
                line-height: rem-calc(22.41);
                position: absolute;
                top: 8px;
            }

            .menu-banner-promo-large {
                height: inherit;
                max-width: none;

                &.wide-width {
                    @include breakpoint(large up) {
                        min-height: rem-calc(311);
                        width: auto;
                        max-width: rem-calc(750);
                    }
                }

                @include breakpoint(medium down) {
                    height: rem-calc(144);
                    margin: 0;
                }
                &-image{
                    width: 100% !important;
                    object-fit: cover;
                    height: 100%;
                    @media screen and (min-width: 1022px) and (max-width: 1367px) {
                        width: auto !important;
                    }
                }

                &.promotion-content{
                    @include breakpoint(medium down) {
                        height: unset;
                    }
                    .menu-section-link{
                        &-circles{
                            margin-bottom: rem-calc(18);
                            .icon{
                                background-color: white;
                                aspect-ratio: 1;
                                border-radius: 50%;
                                &:not(:last-child){
                                    opacity: 0.5;
                                }
                            }

                            .icon.white-circle {
                                opacity: 1 !important;
                            }
                        }
                        &-title{
                            @include breakpoint(medium down) {
                                font-size: rem-calc(16);
                                line-height: rem-calc(22);
                                margin-bottom: rem-calc(8);
                            }
                        }
                        &-description{
                            font-size: rem-calc(20);
                            line-height: rem-calc(26);
                            color: $white;
                            font-family: $secondary-font;
                            padding-right: 11vw;
                            margin-bottom: rem-calc(18);
                            @include breakpoint(medium down) {
                                font-size: rem-calc(16);
                                line-height: rem-calc(22);
                                margin-bottom: rem-calc(16);
                            }
                        }
                    }
                }

                .text-on-image{
                    width: 100%;
                    height: 100%;
                }

                &:not(.promotion-content){
                    .text-on-image{
                        position: absolute;
                        top: 0;
                    }
                }
                .p-16{
                    padding: rem-calc(16);
                }

                .right-content{
                    @include breakpoint(large up) {
                        margin-left: auto;
                        padding-left: rem-calc(5);
                        max-width: 16vw;
                        width: rem-calc(240);   
                        &-1,
                        &-2{
                            flex: 1 1 0px;
                            margin-left: auto;
                            max-width: 16vw;
                        }
                    }
                    @include breakpoint(medium down) {
                        flex-direction: row;
                        margin: rem-calc(0 -4);
                        &-1,
                        &-2{
                            min-height: rem-calc(144);
                            height: unset;
                            margin: rem-calc(0 4);
                            width: calc(50% - 8px);
                        }
                    }
                }
            }

            .large-width {
                @include breakpoint(large up) {
                    min-height: rem-calc(311);
                    width: auto;
                    max-width: rem-calc(548);
                    &.promotion-content{
                        width: rem-calc(548);
                    }
                }

                &.large-banner-image {
                    @include breakpoint(large up) {
                        max-width: none !important;
                    }
                }
            }

            .extra-large-width {
                @include breakpoint(large up) {
                    width: rem-calc(440);
                }
            }

            .menu-banner-promo-large-link {
                height: inherit;
                position: relative;
                @media screen and (min-width: 1022px) and (max-width: 1200px) {
                    display:none;
                }
            }

            .menu-banner-promo-large{
                &.promotion-content{
                    .menu-banner-promo-large-link{
                        @media screen and (min-width: 1022px) and (max-width: 1200px) {
                            display:block;
                        }
                    }
                }
            }


            .giftcard-section-container {
                height: inherit;
                .bottom-section-menu a {
                    font-size: rem-calc(16);
                    line-height: rem-calc(22);
                    font-weight: 600;
                    color: white;
                }
            }

            .menu-image-content-asset{
                padding: rem-calc(40 0);
                @include breakpoint(medium down) {
                    padding: rem-calc(16);
                    margin-top: rem-calc(12);
                    border-top: 1px solid $neutral;
                }
            }
            .menu-content-section {
                display: flex;
                flex-direction: column;
                //height: inherit;
                width: 16.10541727672035vw;
                //width: 100%;

                @include breakpoint(medium down) {
                    flex-direction: row;
                    flex-wrap: wrap-reverse;
                    height: auto;
                    width: 100%;
                }
            }

            .menu-section-link {
                flex: auto;
                //height: rem-calc(144);
                position: relative;
                padding: rem-calc(16);

                &:nth-child(2) {
                    margin-top: rem-calc(8);
                    @include breakpoint(medium down) {
                        margin-left: rem-calc(8);
                    }
                }

                &:nth-child(3) {
                    margin-top: rem-calc(8);
                }

                &:nth-child(4) {
                    @include breakpoint(medium down) {
                        margin-left: rem-calc(8);
                        margin-top: rem-calc(8);
                    }
                }
            }

            .menu-section-link-title {
                color: $white;
                display: block;
                font-family: $secondary-font;
                font-size: rem-calc(20);
                font-weight: 600;
                line-height: rem-calc(28);
                margin-bottom: rem-calc(18);
                @include breakpoint(medium down) {
                    font-size: rem-calc(16);
                    line-height: rem-calc(22);
                }
            }

            .menu-section-link-subtitle {
                color: $white;
                display: block;
                font-family: $secondary-font;
                font-size: rem-calc(14);
                font-weight: 600;
                line-height: rem-calc(20);

                width: fit-content;
                display: inline-grid;
                grid-template-columns: auto minmax(0, rem-calc(30));

                &::after {
                    transform: rotateY(180deg);
                    content: '';
                    background-image: url(../../images/ico-arrow-left-new.svg);
                    background-size: rem-calc(6 12);
                    background-repeat: no-repeat;
                    background-position: 0 center;
                    filter: invert(1);
                }
            }

            .menu-first-row-height {
                padding: 0;
                flex: 1 1 auto;
                @include breakpoint (medium up) {
                    height: inherit;
                    min-height: rem-calc(391);
                }
                .content-asset {
                    @include breakpoint (medium up) {
                        height: inherit;
                    }
                    img {
                        max-height: rem-calc(311);
                        width: unset;
                    }
                }

                @include breakpoint(medium down) {
                    padding: rem-calc(12 0);
                }
            }
            /*
            .menu-section-box {
                width: rem-calc(155);
                margin: rem-calc(25) auto;
            }
            */
            .list-item-menu {
                margin-left: 0;

                &:not(.first-category-column){
                    .dropdown-item a{
                        font-family: $body-font-family;
                        font-size: rem-calc(14);
                        line-height: rem-calc(20);
                        @include breakpoint(medium up) {
                            padding: rem-calc(6 0);
                        }
                        @include breakpoint(small down) {
                            font-size: rem-calc(16);
                            line-height: rem-calc(28);
                            padding: 0;
                        }
                        &.with-image{
                            @include breakpoint(small down) {
                                padding: rem-calc(4 0);
                            }
                        }
                    }
                }
                .list-item-submenu.on-mouseenter{
                    @include breakpoint(medium up) {
                        .dropdown-link{
                            color: #646464;
                            &:hover{
                                color: #000;
                            }
                        }
                    }
                }
                .view-all-label {
                    bottom: 0;
                    font-family: $secondary-font !important;
                    font-size: rem-calc(16) !important;
                    line-height: rem-calc(48);
                    font-weight: 600;
                    writing-mode: horizontal-tb;
                    padding-top: rem-calc(24);
                    color: $black !important;

                    @include breakpoint (medium down) {
                        padding: rem-calc(12 0 11 16);
                    }
                }

                @include breakpoint(medium up) {
                    padding: rem-calc(40 0);
                    width: 16.10541727672035vw;
                    margin-right: rem-calc(8);
                    &.first-category-column{
                        padding-left: rem-calc(40);
                        background-color: rgba(255, 255, 255, 0.1);
                        backdrop-filter: blur(20px);
                        width: 25vw;
                        margin-right: rem-calc(40);
                        display: flex;
                        @media screen and (min-width: 1022px) and (max-width: 1367px) {
                            background-color: rgba(255, 255, 255, 0.4);
                            backdrop-filter: blur(20px);
                        }
                    }
                }

                @include breakpoint(large up) {
                    .list-item-submenu {
                        width: 100%;
                        margin-left: 0;
                    }
                }

                @include breakpoint(small down) {
                    flex: 1 0 auto;
                    &:not(:last-of-type) {
                        border-bottom: 1px solid $neutral;
                        padding-bottom: rem-calc(13);
                        margin-bottom: rem-calc(13);
                    }
                }

                li {
                    &.view-all-items {
                        margin-top: rem-calc(15);
                        @include breakpoint(small down) {
                            margin-top: 0;
                        }

                        a {
                            @include breakpoint(small down) {
                                border-bottom: 0!important;
                                padding-left: rem-calc(55);
                                padding-top: rem-calc(8)!important;
                                margin-top: 0;
                            }
                        }
                    }

                    a {
                        font-family: $body-font-family;
                        font-size: rem-calc(16);
                        line-height: rem-calc(22);
                        @include breakpoint(small down) {
                            font-size: rem-calc(20);
                            line-height: rem-calc(26);
                            font-family: $secondary-font;
                            padding: rem-calc(12 0);
                            &.with-image{
                                padding: rem-calc(4 0);
                                justify-content: space-between;
                                svg{
                                    transform: rotate(180deg);
                                }
                            }
                            &.view-all-link{
                                font-size: rem-calc(16);
                                line-height: rem-calc(22);
                            }
                        }
                    }
                }
                .menu-section-title {
                    &, & a{
                        color: #000 !important;
                        font-family: $secondary-font;
                        font-size: rem-calc(14);
                        font-weight: 600;
                        line-height: rem-calc(20);
                        text-transform: uppercase;
                        margin-bottom: rem-calc(8);

                        @include breakpoint(medium down) {
                            line-height: rem-calc(20);
                            margin: rem-calc(11 0 0);
                            padding-bottom: rem-calc(20);
                        }
                    }
                    & a{
                        @include breakpoint(medium down) {
                            padding: 0;
                        }
                    }
                    &.menu-subcat {
                        @include breakpoint(medium down) {
                            padding-left: rem-calc(16);
                        }
                    }
                }
                &.first-category-column .dropdown-item{
                    margin-bottom: rem-calc(16);
                    .dropdown-link{
                        font-family: $secondary-font;
                        font-size: rem-calc(20);
                        line-height: rem-calc(26);
                        @include breakpoint(medium up) {
                            &.view-all-link{
                                padding: 0;
                            }
                        }
                        @include breakpoint(medium down) {
                            > span:nth-child(2)  {
                                flex: 2 0 0;
                            }
                            .arrow {
                                margin-left: auto;
                            }
                        }
                    }
                    &:not(:last-of-type){
                        .dropdown-link{
                            padding: rem-calc(0 0 16);
                            @include breakpoint(small down) {
                                padding: rem-calc(12 0);
                            }
                        }
                    }
                }
                li.level-3 {
                    a {
                        &:hover {
                            color: $black;
                        }
                        @include breakpoint(small down) {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
                li.level-4 {
                    a {
                        padding-bottom: rem-calc(15);

                        @include breakpoint (medium down) {
                            padding: rem-calc(16 57);
                            margin-bottom: rem-calc(20);
                        }
                        @include breakpoint(small down) {
                            display: flex;
                            padding: rem-calc(4) 0;
                            align-items: center;
                        }
                    }
                }
                li.section-title {
                    @include breakpoint (medium down) {
                        padding-bottom: 0;
                        margin-bottom: rem-calc(8);
                        margin-top: rem-calc(30);
                        font-size: rem-calc(12);
                        font-weight: bold;
                        border-bottom: 0;
                        text-transform: uppercase;
                    }
                }

                li.level-5 {
                    a {
                        line-height: rem-calc(17);
                        font-size: rem-calc(12);
                        color: #666;
                        @include breakpoint (large up) {
                            padding: rem-calc(1);
                        }

                        @include breakpoint(small down) {
                            color: #000;
                            font-size: rem-calc(16);
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }
        @include breakpoint(medium up) {
            left: 0;
            position: absolute;
            right: 0;
            z-index: 10;
        }
    }


    .menu-header-container {
        .menu-header,
        .menu-tab-out {
            display: flex;
            justify-content: space-around;
            margin-bottom: 0;
            line-height: inherit;
            @include breakpoint(large up) {
                position: absolute;
                width: 100%;
                margin: 0;
                justify-content: flex-start;
                padding: rem-calc(0 40);
                background: rgba(255,255,255,0.9);
                backdrop-filter: blur(20px);
                align-items: center;
                top:71px;
                left: 0;
            }
            @include breakpoint(small down) {
                display: block;
                flex-grow: 1;
                padding-top: rem-calc(12);
                margin-left: rem-calc(0);
            }
        }
        .menu-tab-out {
            padding-top: rem-calc(0);
        }

        .underline-from-center {
            font-family: $secondary-font;
            font-size: rem-calc(16);
            font-weight: 600;
            line-height: rem-calc(22);
            padding: rem-calc(17 0);
            text-decoration: none;
            letter-spacing: -0.02em;
            &:not(.text-loyalty){
                color: gray(2);
            }

            @include breakpoint (medium down) {
                font-size: rem-calc(20);
                line-height: rem-calc(26);
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 400;
                padding: rem-calc(12 0);
                &:not(.text-loyalty){
                    color: $black;
                }
            }

            @include breakpoint (small up) {
                position: relative;
                display: block;
            }

            &.menu-label-smaller {
                @include breakpoint(small down) {
                    width: 85%;
                    display: inline-block;
                    font-size: rem-calc(16);
                    line-height: rem-calc(22);
                    font-family: $body-font-family;
                }
            }
        }
        .divider-menu{
            height: rem-calc(1);
            background-color: $neutral;
            margin: rem-calc(12 0);
        }
        .account-label {
            font-size: rem-calc(14);
            font-weight: 600;
            line-height: rem-calc(20);
            text-transform: uppercase;
        }

        .account-icon {
            display: inline-block;
            height: rem-calc(16);
            margin-left: rem-calc(-4);
            margin-right: rem-calc(16);
            vertical-align: baseline;
            width: rem-calc(12);
        }

        .active {

            .tab-submenu,
            .content-tab {
                display: block !important;
                @include breakpoint(small down) {
                    height: 100%;
                }
            }
            .tab-submenu{
                @include breakpoint (large up) {
                    background: rgba(255,255,255,0.8);
                    backdrop-filter: blur(60px);
                }
            }
        }

        .menu-underline .underline-from-center,
        .active .underline-from-center {
            @include breakpoint (large up) {
                color: $black;
            }
        }
    }

    .js-open-tab-menu {
        .display-type {
            display: inline-flex;
            align-items: center;
            .main-header-login-logged-icon {
                margin-right: rem-calc(8);
                margin-top: 0;
                line-height: rem-calc(11);
            }
            .text-name {
                font-size: rem-calc(16);
                padding: rem-calc(12 0);
                line-height: rem-calc(22);
            }
        }
        &.clz--lover {
            align-items: center;
            padding: rem-calc(17 0 17 16);
            @include breakpoint (medium down){
                padding: rem-calc(10 0 10 16);
            }
            a {
                color: $loyalty;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .calzedonia-lover-link{
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }
        }
        @include breakpoint(small down) {
            padding-left: rem-calc(16);
            height: 100%;
            &.colored-background{
                padding: rem-calc(12 16);
                .underline-from-center{
                    padding: 0;
                }
            }
            /* .with-description{
                padding: 0;
            } */
            .mobile-category-description{
                padding-top: rem-calc(4);
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }
        }
        @include breakpoint(large up) {
           background-color: transparent!important;
           color: black!important;
           margin-right: rem-calc(40);
           line-height: 20px;
           flex: 0 0 auto;
        }
        @media screen and (min-width: 1022px) and (max-width: 1200px) {
            flex:0;
        }
    }
}

.colored-background-important {
    @include breakpoint (medium down){
        background-color: unset!important;
    }
}

//....................menu-initimissimi MOBILE................
/*
.menu-footer-divider {
    width: 100%;
    margin-top: rem-calc(25);
    margin-bottom: rem-calc(7);
}
*/

.close-button {
    top: rem-calc(15);
    right: rem-calc(20);
    margin-right: 0;
    z-index: 1000;
    position: fixed;

    &:hover {
        color: $white;
    }
    svg{
        width: rem-calc(16);
    }
}

.active-collection {
    width: 100%;
    margin: rem-calc(12 0 24);
    padding: rem-calc(16);
    background-color: #838ad9;
    color: $white;
    a {
        color: $white;
        text-decoration: underline;
    }
}

.green-calzedonia {
    width: 100%;
    margin: rem-calc(12 0 24);
    padding: rem-calc(16);
    background-color: #7aa78f;
    color: $white;
    text-transform: uppercase;
    font-size: rem-calc(14);
    font-weight: bold;
    a {
        font-weight: bold!important;
    }
}

.promotion-asset {
    width: 100%;
    margin: rem-calc(12 0 24);
    padding: rem-calc(20 118 18 32);
    background-image: linear-gradient(131deg, #ffeda3, #f0f9fa 51%);
    font-size: rem-calc(14);
    font-weight: 500;
    a {
        font-weight: bold!important;
    }
}

/*
.close-button-black {
    font-weight: $global-weight-light;
    font-size: rem-calc(28);
    line-height: 1.25;
    color: $white !important;
    top: rem-calc(14);
    right: rem-calc(20);
    margin-right: 0;
    z-index: 1000;
    position: fixed;

    &:hover {
        color: $white;
    }

    @include breakpoint(small down) {
        &.background-close {
            background-color: rgba(0, 0, 0, 0.7);
        }

        width: 15%;
        height: rem-calc(47);
        top: 0;
        left: 0;
        text-align: center;

        span {
            line-height: rem-calc(47);
        }
    }
}
*/

.overlay-menu-open {
    position: fixed;
    display: none;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
}

.menu-bordered-container {
    background: $white;

    @include breakpoint(small down) {
        border-bottom: rem-calc(1) solid $neutral;
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    @include breakpoint(medium up) {
        position: absolute;
        top: rem-calc(20);
        left: rem-calc(40);
    }
}

.menu-top-level {
    @include breakpoint(large up) {
        padding-top: rem-calc(6);
    }
    @include breakpoint(small down) {
        justify-content: flex-start;
        white-space: nowrap; // cz locale
    }
    display: flex;
    font-family: $secondary-font;
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 0;
    line-height: rem-calc(22.41);
    /*
    &.right-border li {
        border-right: rem-calc(1) solid $menu-border-gray;
    }*/
    /*
    .separator-vertical {
        color: #ededed;
        &:last-child {
            display: none;
        }
    }*/

    li {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
        text-align: center;
        text-transform: uppercase;
        padding: 0 rem-calc(14);

        html[lang="cs-CZ"] & {
            font-size: rem-calc(14);
        }

        &:first-child {
            padding-left: rem-calc(16);
            @include breakpoint (large up){
                padding-left: 0;
            }
        }

        @include breakpoint(small down) {
            padding: rem-calc(16 16 16 16);
        }

        .category {
            color: gray(2);
            font-weight: 600;
            &.active, &:hover {
                color: $black;

                position: relative;
            }
        }
    }
}

.menu-separator {
    background-color: #E6E6E6;
    height: rem-calc(2);
    width: rem-calc(40);
    margin: rem-calc(16 26 16);
}

.style-guide-menu {
    display: inline-flex;
    width: 100%;
    height: rem-calc(60);
    margin: rem-calc(34 0 40.5);
    padding: rem-calc(20 0 20 40);
    background-image: linear-gradient(124deg, #ffeda3, #f0f9fa 45%);
    color: #000;
    font-size: rem-calc(14);
    font-weight: 500;

    img {
        width: 1.2rem;
        margin-right: rem-calc(16);
    }
}

/*
.arrow-right-open {
    margin-right: rem-calc(18);
    display: none;
}
*/

.category-name {
    font-size: rem-calc(22);
    font-weight: $global-weight-dark;

    @include breakpoint(small down) {
        width: 100%;
        position: absolute;
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        z-index: 3;
        padding: rem-calc(17 20 17 24);
        background: $white;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        font-family: $secondary-font;

        .go-back {
            svg{
                margin-right: rem-calc(24);
                width: rem-calc(8);
            }
        }
        .menu-item-name{
            padding-right: rem-calc(20);
            line-height: rem-calc(20);
        }
    }
}

.overlay-menu-desktop {
    @include breakpoint (small down) {
        display: none !important;
    }
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(20px);
    z-index: 12;
}


.black-banner-woman {
    background: black;
    width: 100%;
    text-align: center;

}

.black-banner-woman h20 {
    color: white;
}

.page[data-action="Page-Show"],
.page[data-action="Cart-Show"],
.page[data-action="Wishlist-Show"],
.page[data-action="Giftcard-Balance"] {
    .black-banner-woman {
        display: none;
    }
}

.black-banner-man {
    background: black;
    width: 100%;
    text-align: center;

}

.black-banner-man h20 {
    color: white;
}

.page[data-action="Page-Show"],
.page[data-action="Cart-Show"],
.page[data-action="Wishlist-Show"],
.page[data-action="Giftcard-Balance"] {
    .black-banner-man {
        display: none;
    }
}

.black-banner-kids {
    background: black;
    width: 100%;
    text-align: center;

}

.black-banner-kids h20 {
    color: white;
}

/*
.page[data-action="Page-Show"],
.page[data-action="Cart-Show"],
.page[data-action="Wishlist-Show"],
.page[data-action="Giftcard-Balance"],
.page[data-action="CalzedoniaLover-Show"] {
    .black-top-banner {
        display: none;
    }
}
*/

// mini pop up styles

.newsletter-minipopup {
    position: fixed;
    bottom: rem-calc(90);
    right: rem-calc(60);
    max-width: rem-calc(250);
    background: $white;
    padding: rem-calc(15 15 0);
    z-index: 20;
    box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.4);
    @include breakpoint (medium down) {
        bottom: rem-calc(70);
        left: rem-calc(10);
        right: rem-calc(10);
        max-width: inherit;
        padding: rem-calc(30 30 0);
    }
    p {
        font-size: rem-calc(13);
        text-align: center;
    }
    .close-minipopup {
        position: absolute;
        top: rem-calc(3);
        right: rem-calc(5);
        font-size: rem-calc(20);
        @include breakpoint (medium down) {
            top: rem-calc(3);
            right: rem-calc(9);
            font-size: rem-calc(34);
        }
        color: currentColor;
    }
}

#style-guide-menu-asset {
    background-color: white;
}

html[lang="el-GR"]{
    .hover-underline-menu .tab-submenu .content-tab .list-item-menu:not(.first-category-column) .dropdown-item a{
        font-family: "Trebuchet MS",sans-serif !important;
    }
    .hover-underline-menu .tab-submenu .content-tab .list-item-menu li a{
        font-family: "Trebuchet MS",sans-serif !important;
    }
}

html[lang="ru-RU"]{
    .hover-underline-menu .tab-submenu .content-tab .list-item-menu:not(.first-category-column) .dropdown-item a{
        font-family: Helvetica,Arial,sans-serif !important;
    }
    .hover-underline-menu .tab-submenu .content-tab .list-item-menu li a{
        font-family: Helvetica,Arial,sans-serif !important;
    }
}
