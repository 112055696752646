$size: 30;
$halfSize: $size/2;
$backgroundColor: $neutral;

.scrollToTop {
    bottom: -100%;
    color: $black;
    cursor: pointer;
    position: fixed;
    right: rem-calc(53);
    transition: .3s bottom ease-in-out;
    z-index: 11;
    &.active {
        bottom: rem-calc(120);
    }
    &-container {
        @include flexGenerator(row, flex-end, center);
        height: rem-calc($size);
        position: relative;
        width: auto;
    }
    &-icon {
        @include flexGenerator(row, center, center);
        background-color: $backgroundColor;
        border-radius: rem-calc($halfSize);
        height: 100%;
        position: absolute;
        width: rem-calc($size);
        z-index: 1;
        & .scrollToTop_svg{
            height: rem-calc(24);
            width: rem-calc(24);
        }
    }
    &-text {
        background-color: $backgroundColor;
        border-bottom-left-radius: rem-calc($halfSize);
        border-top-left-radius: rem-calc($halfSize);
        font-family: $body-font-family-cursive;
        font-size: rem-calc(12);
        font-weight: 400;
        height: 100%;
        line-height: rem-calc($size);
        margin-right: rem-calc($halfSize);
        overflow: hidden;
        padding-right: rem-calc($halfSize);
        position: relative;
        transition: 0.3s all ease-in-out;
        white-space: nowrap;
        width: 0;
    }
    &:hover &-text {
        padding-left: rem-calc(10);
        padding-right: rem-calc($halfSize + 4);
        width: 100%;
    }
}
