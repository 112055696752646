.wrapper-max-width{
    //max-width: rem-calc(635);
    margin: 0 auto;

    h2.title-refund-login{
        font-size: rem-calc(30);
        line-height: rem-calc(43);
        padding-top:    rem-calc(47);
        padding-bottom:    rem-calc(30);
        text-align: center;
        font-weight: $global-weight-normal;
        margin: 0;
        @include breakpoint (small down){
            padding-top:    rem-calc(21);
            padding-bottom:    rem-calc(10);
            font-size: rem-calc(24);
        } 
    }
    h3{
        font-size: rem-calc(18);
        line-height: rem-calc(24);
        text-align: left;
        padding-bottom: rem-calc(20);
        margin: 0;
        @include breakpoint (small down){
            padding-bottom: rem-calc(30);
            text-align: center;
        } 
    }
    p{
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        text-align: left;
        padding-bottom: rem-calc(25);
        margin: 0;
        @include breakpoint (small down){
            padding-bottom: rem-calc(15);
            text-align: center;
        } 
    }

    .marb-30{
        margin-bottom: rem-calc(30);
    }

    .note-message-required{
        font-size: rem-calc(12);
        line-height: rem-calc(20);
        padding-bottom: rem-calc(10);
        margin: 0;
        @include breakpoint (small down){
            
            text-align: left;
        } 
    }
    .box-note-message-required{
        font-size: rem-calc(14);
        line-height: rem-calc(17);
        padding: rem-calc(20 45 20 25);
        background-color: gray(7);
        margin-bottom: rem-calc(10);
        width: 100%;
        img{
            padding-right: rem-calc(10);
        }
    }
    .input-width-img{
        position: relative;
        img{
            position: absolute;
            right: 0;
           top: rem-calc(20);
            
        }
    }
}
.password-field-with-tooltip {
    position: relative;
    width: 90%;
}

#iban {
    text-transform: uppercase;
}

.wrapper-max-height{
    height: 85vh;
   @include breakpoint (small down){
        height: 100vh;
    } 
    
    button{
        @include breakpoint (medium down){
            width: 100%;
            margin-bottom: rem-calc(55);
        }
    }

    .refund-confirm-error{
        margin-top: 5%;
        .marb-30{
            margin: 0 auto;
            margin-bottom: rem-calc(30); 
            max-width: rem-calc(375); 
            width: 100%;
        }
    }
}

.special-border-header {
    border-top: rem-calc(1) solid $border-gray;
}