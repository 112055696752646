.regulation-title {
    font-size: rem-calc(40);
    line-height: rem-calc(35);
    padding: rem-calc(40 0);
    border-bottom: rem-calc(1) solid #F2EDED;
    font-weight: 400;
    margin: 0;
    text-align: left!important;
    @include breakpoint (small down) {
        font-size: rem-calc(20);
        line-height: rem-calc(26);
        padding-top: rem-calc(24);
        padding-bottom: rem-calc(16);
        height: auto;
    }
}

.regulation-headings{
    // font-size: rem-calc(16);
    // line-height: rem-calc(22);
    padding: rem-calc(40 0 32);
    margin-bottom: 0;
    @include breakpoint (medium down) {
        padding: rem-calc(24 0 16);
    }
}

.regulation-list-style{
    margin-left: rem-calc(35);
    li{
        margin-bottom: rem-calc(15);
    }
}
.regulation-list-no-circle{
    list-style: none;
}

.loyalty-policy-marketing-popup{
    z-index: 1500;
}

.loyalty-style{

    .content-page-wrapper{
        @include breakpoint(medium down) {
            padding: rem-calc(0 20);
    }
        @include breakpoint(large up) {
            padding-right: 3%;
        }
    }
}

.close-button.close-button-loyalty.close-button-modal {
    right: rem-calc(13);
}
