$include-fonts: false;

@import "./setup/settings";
@import "foundation";

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-forms;
@include foundation-accordion;
@include foundation-button;
@include foundation-pagination;
@include foundation-typography;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@import "mixins/helpers";

@include extend-palette($brand-palette);
@include helpers();
// @include full-container();

.grid-container {
    @include breakpoint (medium up) {
        margin-left: xy-cell-size(1 of 12);
        margin-right: xy-cell-size(1 of 12);
        margin: 0 auto;
    }
}

@import "layout/layout";

@include brand-text($brand-palette);
@include brand-links($brand-palette);

@import "components/components";
@import "pages/content";
@import "plugins/slick";
@import "plugins/woosmap";
@import "plugins/foundation-datepicker";

html[lang="el-GR"] {
    body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .hero, .hero-2 {
        font-family: "Trebuchet MS",sans-serif !important;
    }
}
