.breadcrumb {
    @include breakpoint (large down) {
        display: none;
    }
    @include breakpoint (large up) {
        display: block;
    }
    list-style: none;
    margin-bottom: 0;

    &-item {
        display: inline;
    }

    span {
        display: inline-block;
        color: $primary;
    }
}
.breadcrumb-item {
    .breadcrumb-url, .breadcrumb-static {
        font-weight: 400;
        font-size: rem-calc(14);
        padding: 0;
        line-height: rem-calc(20);
    }
}
.breadcrumb-item-separator {
    padding: rem-calc(0 3);
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    .separator {
        width: rem-calc(4);
        height: rem-calc(7);
    }
}