// Custom Main container
.grid-container,
.grid-container.fluid {
    padding-left: 16px;
    padding-right: 16px;

    @include breakpoint (medium up) {
        padding-left: 40px;
        padding-right: 40px;
    }

    &--narrow {
        @include breakpoint (medium up) {
            padding-left: calc(#{xy-cell-size(1 of 12)} + #{map-get($grid-margin-gutters, small)});
            padding-right: calc(#{xy-cell-size(1 of 12)} + #{map-get($grid-margin-gutters, small)});
        }
    }

    .cell {
        .padding-right {
            padding-right: rem-calc(12);
        }
    }
}
.page {
    .grid-container {
        max-width: 100%;
    }
}
.main-header .grid-container {
    max-width: 100%;
}

footer {
    .grid-container {
        max-width: 100% !important;
    }
}

.page[data-action='Search-Show'] {
    .grid-container {
        padding: 0;
        @include breakpoint (large up) {
            padding-left: rem-calc(40);
            padding-right: rem-calc(40);
        }
    }
    .grid-container-sticky {
        width: 100%;
        padding-left: rem-calc(16);
        padding-right: rem-calc(16);
        @include breakpoint (large up) {
            padding-left: rem-calc(40);
            padding-right: rem-calc(40);
        }
    }
    .site-search {
        .grid-container {
            width: 100%;
            padding-left: calc(9% - 18px);
            padding-right: calc(9% - 18px);
            @include breakpoint (large up) {
                width: 100%;
                max-width: 100%;
                padding-left: calc(9% - 18px);
                padding-right: calc(9% - 18px);
                margin: 0;
            }
        }
    }
}
.page[data-action='Home-Show'] {
    .grid-container {
        padding: 0;
        @include breakpoint (large up) {
            padding-left: rem-calc(40);
            padding-right: rem-calc(40);
        }
    }
}
