strong,
.strong {
    font-weight: $global-weight-bold;
}

.dark {
    font-weight: $global-weight-dark;
}

// Header Modifiers
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4
h5,
.h5 {
    &.inline {
        display: inline;
    }
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none;
}

address {
    font-style: normal;
}

.font-normal{
    font-weight: 400;
}

p,
label,
input,
dl {
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    letter-spacing: rem-calc(0);

    &.verysmall {
        font-size: rem-calc(10);
    }

    &.small {
        font-size: rem-calc(12);
    }

    &.medium {
        font-size: rem-calc(16);
    }

    &.regular {
        font-size: rem-calc(14);
    }

    &.big {
        font-size: rem-calc(16);
    }

    &.huge {
        font-size: rem-calc(16);
    }

    &.light {
        font-weight: $global-weight-normal;
    }

    &.no-margin {
        margin: 0;
    }

    &.small-margin {
        margin-bottom: $paragraph-margin-bottom / 2;
    }
}

caption,
.caption {
    text-transform: uppercase;
    font-size: $label-font-size;
}

a {
    font-weight: $global-weight-normal;
    text-decoration: none;

    // Hover state
    &.force-hover {
        text-decoration: underline;
    }

    &:not([class^="link"])
    :not(.button)
    :not(.no-hover)
    :not(:disabled) {
        &:hover {
            text-decoration: none;
        }
    }
}

// generate colored links
@mixin brand-links($palette) {
    a {
        @each $color, $hex in $palette {
            &.text-#{$color} {
                color: $hex;

                // Hover state
                &:not(.no-hover):hover,
                &.force-hover {
                    border-color: $hex;
                }
            }
        }
    }
}

p {
    a {
        display: inline;
        border: none;
        text-decoration: underline;
        font-weight: $global-weight-semibold;
    }
}

.cc-terms-conditions {
    font-weight: $global-weight-normal;
    line-height: rem-calc(22);
    text-decoration: underline;
    margin-left: rem-calc(2);
}


.strike-through {
    text-decoration: line-through;
}

a.disabled,
.disabled > a {
    color: $light-gray;
    text-decoration: line-through;
    cursor: not-allowed;
}

// generate colored paragraphs
@mixin brand-text($palette) {
    div, p, span, h1, h2, h3, h4, h5, i, label, input {
        @each $color, $hex in $palette {
            &.text-#{$color} {
                color: $hex;
            }
        }
    }
}

/*
* # Extended Typography
*/
$typogray-levels: (
    'small': (
        '1': (
            'font-size': 60,
            'line-height': 60,
            'letter-spacing': -0.4,
            'text-transform': uppercase,
            'font-weight': $global-weight-bold),
        '2': (
            'font-size': 40,
            'line-height': 40,
            'letter-spacing': -0.4,
            'text-transform': uppercase,
            'font-weight': $global-weight-bold),
        '3': (
            'font-size': 20,
            'line-height': 24,
            'letter-spacing': -0.2,
            'font-weight': $global-weight-bold),
        '4': (
            'font-size': 16,
            'line-height': 20,
            'letter-spacing': -0.2,
            'font-weight': $global-weight-bold),
        '5': (
            'font-size': 14,
            'line-height': 17,
            'text-transform': uppercase,
            'font-weight': $global-weight-bold),
        '6': (
            'font-size': 14,
            'line-height': 17,
            'font-weight': $global-weight-bold)
    )
);

$needs-rem-calc: ('font-size', 'letter-spacing', 'line-height');

/*
 * Cycle through all properties of all the typography levels
 * to dynamically generate custom levels.
*/
@each $size, $breakpoints in $typogray-levels {
    @include breakpoint($size) {
        @each $level, $values in map-get($typogray-levels, $size) {
            .typo-level-#{$level},
            h#{$level},
            .h#{$level} {
                @each $property, $value in $values {
                    @if index($needs-rem-calc, $property) {
                        #{$property}: rem-calc($value);
                    } @else {
                        #{$property}: $value;
                    }
                }
            }
        }
    }
}

// Extend Hero/Subtitle classes from typo levels
.big-header-1 {
    @extend .h1;
    font-weight: $global-weight-normal;
}
.header-2 {
    @extend .h2;
    font-weight: $global-weight-normal;
}
.header-3 {
    @extend .h3;
    font-weight: $global-weight-normal;
}
.header-4 {
    @extend .h4;
    font-weight: $global-weight-normal;
}

@include breakpoint(medium up) {
    .inline-for-large {
        display: inline-block;
    }
}

.body-s {
    font-size: rem-calc(14);
}

.body-xs {
    font-size: rem-calc(12);
    line-height: rem-calc(18);
}

.caption {
    font-size: rem-calc(10);
    text-transform: none;
}

.icon-notification {
    font-size: rem-calc(8);
}

.font-secondary {
    font-family: $secondary-font;
}

.font-weight-regular {
    font-weight: $global-weight-normal;
}
.font-weight-semibold {
    font-weight: $global-weight-semibold;
}
