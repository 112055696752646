.menu-link {
    text-decoration: none;

    &:hover {
        font-weight: $global-weight-dark;
        text-decoration: none;
    }

    &--light {
        color: gray(3);

        &:hover {
            color: gray(3);
        }
    }

    img {
        display: inline-block;
        margin-right: rem-calc(16);
        vertical-align: middle;
        border-radius: 50%;
        border: 1px solid gray(7);
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }
}
