// MEDIA AVATAR images and text
.media {
    display: flex;
    align-items: center;
    background: $neutral;
    padding: rem-calc(16 16 16 20);
    margin-bottom: rem-calc(16);
    @include breakpoint(medium up) {
        margin-left: rem-calc(40);
        width: calc(100% - 40px);
        padding: rem-calc(24 24 24 27);
    }

    a{
        font-weight: 500;
        display: inline;
    }

    svg {
        width: rem-calc(21);
        height: rem-calc(21);
        margin: rem-calc(7 7 0 0);
    }

    .align-self-center {
        align-self: center !important;
    }
    .mr-7 {
        margin-right: rem-calc(7);
    }
    .media-body {
        margin-left: rem-calc(11);
        line-height: rem-calc(20);
    }
    .media-title {
        text-align: left;
        margin: 0;
    }
    span {
        margin-bottom: 0;
    }

}