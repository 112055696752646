@import "../setup/settings";

*{
    -webkit-tap-highlight-color: transparent;
}
.overlay-bubble-chat{
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
}

.bubble-element{
    position: fixed;
    bottom: rem-calc(40);
    z-index: 901;
    @include breakpoint (large up){
        right: rem-calc(60);
    }
    @include breakpoint (xlarge up){
        right: rem-calc(60);
    }
    @include breakpoint (medium down){
        right: rem-calc(10);
        bottom: rem-calc(20);
    }
    @include breakpoint(small down){
        bottom: rem-calc(20);
        left: 0;
        right: 0;
        .page[data-action='Cart-Show'] &{
            right: rem-calc(12);
            bottom: rem-calc(30);
        }
    }
    &.minipopup-interaction{
        bottom: rem-calc(40);
        z-index: 1001;
        @include breakpoint (medium down){
            bottom: rem-calc(57);
        }
    }

    .botton-bubble{
        float: right;
        background: $white;
        width: rem-calc(185);
        height: rem-calc(38);
        border: 1px solid $border-gray;
        border-radius: rem-calc(2);
        display: block;
        position: relative;
        box-shadow: rem-calc(0 3 7) rgba(0,0,0,0.2);
        display: flex;
        padding: rem-calc(5 10 5 20);
        align-items: center;
        @include breakpoint(small down){
            right: rem-calc(10);
        }
        img{
            width: rem-calc(30);
            padding-left: rem-calc(7);
            background: white;
            position: absolute;
            right: rem-calc(10);
        }
        .title{
            position: absolute;
            left: rem-calc(20);
        }
        &.expanded-form{
            @include breakpoint(large up){
                width: rem-calc(185) !important;
                .title{
                    opacity: 1 !important;
                    visibility: visible !important;
                    transition-property: visibility, opacity 0.7s !important;
                    transition-delay: 0.4s !important;
                }
            }
        }
        &.icon-only{
            width: rem-calc(45);
            padding-left: rem-calc(10);
            transition: ease-in-out all .6s;
            position: relative;
            .title{
                visibility: hidden;
                opacity: 0;
                transition: ease-in-out all 0.2s;
            }
            &:hover {
                @include breakpoint(large up){
                    width: rem-calc(185);
                    .title{
                        opacity: 1;
                        visibility: visible;
                        transition-property: visibility, opacity 0.7s;
                        transition-delay: 0.4s;
                    }
                }
            }
        }
    }
    .bubble-block{
        border: 1px solid $border-gray;
        display: none;
        padding: rem-calc(30 25 22 30);
        background: $white;
        min-width: rem-calc(330);
        position: relative;
        margin-bottom: rem-calc(5);
        box-shadow: rem-calc(2 2 10) rgba(0,0,0,0.1);
        @include breakpoint(medium up){
            margin-bottom: rem-calc(-45);
            margin-right: rem-calc(-10);
            z-index: 2;
            max-width: rem-calc(410);
        }
        @include breakpoint(small down){
            bottom: 0;
            position: fixed !important;
            width: 100%;
            margin-bottom: 0 !important;
            z-index: 2;
            padding: rem-calc(23 20 33 45);
        }
        &.show{
            display: block;
        }
        .inner-title{
            @include breakpoint(small down){
                margin-bottom: rem-calc(25);
            }
        }
        .bubble-service-list{
            margin-left: 0;
            list-style-type: none;
            margin-bottom: 0;
            li{
                padding: rem-calc(10 0);
                a{
                    &.disabled{
                        color: $black;
                        text-decoration: none;
                        opacity: 0.4;
                    }
                }
                .bubble-service-item{
                    font-size: rem-calc(13);
                }
            }
        }
        .close-button-bubble{
            position: absolute;
            top: rem-calc(15);
            right: rem-calc(20);
            width: rem-calc(24);
            span{
                font-size: rem-calc(36);
                font-weight: 300;
                float: right;
            }
            @include breakpoint(small down){
                top: rem-calc(4);
            }
        }

        .icon-bubble-service{
            img{
                width: rem-calc(18);
                margin-right:rem-calc(8);
            }
        }
        .call-us-text{
            margin: 0;
            font-size: rem-calc(12);
            margin-left: rem-calc(25);
            display: block;
            .info-call{
                font-size: rem-calc(11);
                line-height: rem-calc(16);
                display: inline-block;
                margin-top: rem-calc(3);
            }
        }
        .not-available-chat{
            margin: 0;
            margin-left: rem-calc(25);
            color: grey;
            font-weight: 300;
        }
        .available-chat{
            margin: 0;
            margin-left: rem-calc(25);
            font-weight: 300;
            color: #8ac492;
        }
        .loader {
            margin-left: rem-calc(25);
            margin-top: rem-calc(3);
            margin-right: rem-calc(9);
            border: 2px solid #f3f3f3; /* Light grey */
            border-top: 2px solid #000000; /* Blue */
            border-radius: 50%;
            width: 15px;
            height: 15px;
            animation: spin 2s linear infinite;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

        .availabilityContainer{
            text-align:center;
            display: flex;
        }
        .mobile-version-phone{
            background: $light-gray;
            padding:rem-calc(3);
            border: 0;
        }
    }

}

.page[data-action="Search-Show"] {
    .bubble-element {
        display: inherit;
    }
}

html[lang="ru-RU"] {
    @media screen and (max-width: 47.99875em) {
        .bubble-element {
            left: unset !important;
        }
        .bubble-element .bubble-block.show {
            left: 0 !important;
        }
    }
}

html[lang="ru-RU"] .page[data-action="Cart-Show"] {
    .bubble-element {
        display: none;
    }
}