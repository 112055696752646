body.tingle-enabled {
    position: absolute;
    width: 100vw;
    right: 0;
    left: 0;
    top: 0 !important;
}

html.html-tingle-enabled {
    overflow: hidden;
}

.modal {
    display: none;
}

.tingle-modal {
    z-index: 1050;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background: rgba(0,0,0,0.15);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    cursor: default;
    transition: opacity .35s cubic-bezier(0.165, 0.84, 0.44, 1);
    &.privacy-policy-marketing-popup {
        visibility: visible;
        opacity: 1;
        z-index: 2500;
    }
    &.loyalty-policy-marketing-popup {
        z-index: 2500;
    }
    &.multilanguage-popup { 
        z-index: 2500;
    }
    &.tingle-modal--visible {
        visibility: visible;
        opacity: 1;
        .tingle-modal-box__footer {
            bottom: 0;
        }
        .tingle-modal-box {
            transform: translateY(0);
            opacity: 1;
        }
    }
    &__close,
    &__closeLabel {
        display: none;
    }
    &--noClose {
        &.tingle-modal__close {
            display: none;
        }
    }
}

.tingle-modal-box {
    position: relative;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    max-width: rem-calc(830);
    max-height: 90vh;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(80px);
    cursor: auto;
    overflow-y: auto;
    transform: translateY(50px);
    opacity: 0;
    transition: transform .35s cubic-bezier(0.165, 0.84, 0.44, 1);
    &__content {
        padding: rem-calc(15 21);

        .loyalty {
            margin: rem-calc(25 19); // adds to parent's padding to requested 40px 40px 
            @include breakpoint(small down) {
                margin: rem-calc(9 -5); // adds to parent's padding to requested 24px 16px
            }
            
            .button {
                margin-top: rem-calc(24);
                margin-bottom: 0;
            }
        }
        
        button {
            margin-bottom: 0;
            margin-left: auto;
        }
        .button {
            margin-left: unset;
            width: 100%;
        }
        .close {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            // Fix for '.js-modal-promo-info/.js-custom-promo-msg' modals.
            &.promo-info {
                float: right;
                position: relative;
                margin-left: rem-calc(13);
            }
        }
    }
    .modal-header {
        position: relative;
        .modal-title {
            font-size: rem-calc(16);
            line-height: rem-calc(23);
            font-weight: $global-weight-medium;
        }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            font-size: rem-calc(28);
            cursor: pointer;
        }
    }

    .modal__closeIcon {
        position: absolute;
        top: rem-calc(13);
        right: rem-calc(13);
        font-size: 2em;
        color: black;
        cursor: pointer;
    }

    &__footer {
        width: auto;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #f5f5f5;
        cursor: auto;
        text-align: right;
        &:after {
            display: table;
            clear: both;
            content: "";
        }
        &--sticky {
            position: fixed;
            bottom: -200px; // TODO: find a better way
            z-index: 10001;
            opacity: 1;
            transition: bottom .35s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
    }

    &.resize-modal-loyalty {
            width: 60vw;
            @include breakpoint (small down) {
                width: 95%;
            }
    }
}

.padding-for-modal-40{
    padding: rem-calc(40);
}

.language {
    text-align: center;
    font-size: rem-calc(30);
    line-height: rem-calc(30);
}

.choice {
    text-align: center;
    font-size: rem-calc(12);
}

.center {
    margin: auto;
    width: 60%;
    padding: rem-calc(10);
    min-width: rem-calc(200);
}
.margin {
    padding-top: 5px;
}


a.transform-link {
    text-transform: uppercase;
    text-decoration: underline;
}

.loyalty-policy-marketing-popup, .privacy-policy-profiling-popup {
    .regulation-title, .form-request-title {
        font-size: rem-calc(16);
        font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
        font-weight: 600;
        line-height: rem-calc(22);
        text-transform: uppercase;
        border-bottom: 0;
        padding-top: 0;
        padding-bottom: rem-calc(18);
        padding-right: rem-calc(16);
    }

    .tingle-modal-box__content {
        padding: rem-calc(40);

        @include breakpoint (medium down) {
            padding: rem-calc(24 16);
        }
    }

    .close-button.close-button-loyalty.close-button-modal, .close-button {
        top: rem-calc(40);
        right: rem-calc(40);

        @include breakpoint (medium down) {
            top: rem-calc(34);
            right: rem-calc(16);
        }
    }

    h3, h4 {
        font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
        font-size: rem-calc(16);
    }

    p, li, p.dark {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(20);
        letter-spacing: 0.2px;
        margin-bottom: 0;
    }
}


