.info-icon {
    display: inline-block;
    position: relative;
    line-height: rem-calc(16);

    &-pos-absolute {
        position: absolute;
        top: rem-calc(14);
        right: rem-calc(10);
    }

    .icon {
        display: inline-block;
        color: $black;
        font-style: normal;
        height: rem-calc(16);
        width: rem-calc(16);
        line-height: rem-calc(16);
        font-size: rem-calc(8);
        vertical-align: center;
        text-align: center;
        border: rem-calc(1) solid;
        border-radius: 50%;
        cursor: pointer;

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }

    .tooltip-info-icon {
        &-black {
            background: $black;
            color: $white;
        }
        &-white {
            background: transparent;
            color: $black;
        }
        border: rem-calc(1) solid $black;
        display: inline-block;
        font-style: normal;
        height: rem-calc(16);
        width: rem-calc(16);
        line-height: rem-calc(16);
        font-size: rem-calc(8);
        vertical-align: middle;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
            z-index: 99999;
        }
    }

    .icon-info-gray {
        border: 1px solid #828282;
        display: inline-block;
        color: #828282;
        font-style: normal;
        height: rem-calc(16);
        width: rem-calc(16);
        line-height: rem-calc(16);
        font-size: rem-calc(8);
        vertical-align: top;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0;

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
            z-index: 99999;
        }
    }

    .icon-info-black {
        border: 1px solid $black;
        display: inline-block;
        color: $black;
        font-style: normal;
        height: rem-calc(16);
        width: rem-calc(16);
        line-height: rem-calc(16);
        font-size: rem-calc(8);
        vertical-align: top;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0;

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
            z-index: 99999;
        }
    }
    .cc-icon-info-register {
        border: 1px solid $black;
        display: inline-block;
        color: $black;
        font-style: normal;
        height: rem-calc(16);
        width: rem-calc(16);
        line-height: rem-calc(16);
        font-size: rem-calc(8);
        vertical-align: top;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0;
        opacity: 1;
        visibility: visible;
        z-index: 99999;
    }

    
    .tooltip {
        position: absolute;
        top: 0;
        left: 0;
        width: rem-calc(200);
        transform: translate(-8px, calc(-100% - 16px));
        background: $white;
        color: $black;
        padding: rem-calc(8);
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s ease;
        pointer-events: none;
        border: 1px solid $black;
        border-radius: rem-calc(5);
        font-size: rem-calc(10);
        line-height: rem-calc(12);
        
        &--center {
            transform: translate(-50%, calc(-100% - 16px));
        }
        
        &--right {
            transform: translate(calc(-100% + 24px), calc(-100% - 16px));
        }
        
        &.info-tooltip-shipping {
            top: rem-calc(70);
            left: rem-calc(50);
            @include breakpoint(medium down) {
                top: rem-calc(9);
                left: rem-calc(-94);
            }
            @include breakpoint(small down) {
                top: rem-calc(64);
                left: rem-calc(-220);
            }
            &.contact-phone {
                @include breakpoint(medium up) {
                    top: 0;
                    left: unset;
                    right: rem-calc(-20);
                }
            }
        }
        &.showTooltip {
            opacity: 1;
            visibility: visible;
        }
    }
}

