.localities-input-wrapper {
    width: 100%;

    .localities-input-container {
        display: block;
    }
}

.localities-container {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    font-family: $body-font-family;
    z-index: 1060;
    box-sizing: border-box;
    position: absolute;
    background-color: #fff;
    margin: -1px 0 0;
    padding: 0;
    list-style-type: none;
    border: 1px solid #d9d9d9;
    border-top: 0;

    &.localities-hidden {
        display: none;
    }

    .localities-item {
        height: auto;
        cursor: pointer;
        padding: 5px 5px 5px 29px;
        line-height: 22px;
        border-top: 1px solid #d9d9d9;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 11px;
        font-family: Arial, sans-serif;
    }
        

        .localities-icon {
            display: block;
            font-size: 16px;
            position: absolute;
            top: 6px;
            bottom: 0;
            left: 7px;
            width: 14px;
            height: 23px;
            background-color: transparent;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='20'%3E%3Cpath d='M7 0a6.995 6.995 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a6.995 6.995 0 0 0-7-7zM4.5 6.999a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z' fill='%239e9e9e'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
        }


        .localities-item-query {
            cursor: pointer;
            font-size: 13px;
        }

        .localities-item-selected .localities-icon,
        .localities-item:hover .localities-icon {
            filter: brightness(0);
        }


        .localities-item-selected,
        .localities-item:hover {
            background-color: #edf4f9;
            
        }

        .localities-matched {
            font-weight: 700;
        }
       
        

   
}