select.small {
    height: rem-calc(40);
    font-size: rem-calc(14);
    padding: rem-calc(8 27 8 10);
}
select + .chosen-container {
    margin-bottom: rem-calc(15);
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
    &::placeholder {
        color: color(light-gray);
    }

    &:disabled {
        background-color: $white;
    }

    &:focus {
        outline: rem-calc(1) solid $black;
    }

    &.height-text-area {
        min-height: rem-calc(180);
        resize: none;
    }

    &.small {
        height: rem-calc(40);
        font-size: rem-calc(16);
    }

    &.clear {
        border: none;
        border-bottom: 1px solid gray(2);
        padding: rem-calc(0 0 0 8);
    }

    &.has-round-icon {
        & + button {
            border-radius: 50%;
            width: rem-calc(26);
            height: rem-calc(26);
            line-height: rem-calc(26);
            top: 50%;
            transform: translateY(-50%);

            i {
                vertical-align: super;
            }
        }
    }

    &.search {
        padding-left: rem-calc(30);

        & + button {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

label {
    position: relative;

    &.label-has-button {
        button {
            text-align: center;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: auto;
            text-align: center;
            vertical-align: middle;
            background-color: gray(2);
            color: $white;
        }
    }
}

.input-label {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-weight: $global-weight-normal;
    font-size: rem-calc(16);
    line-height: rem-calc(19);
    letter-spacing: 0;
    color: color(dark-gray);
}

.label-inside {
    .input-label {
        position: absolute;
        left: 0;
        top: calc(100% - 34px);
        transition: all 0.2s ease-in-out;
        pointer-events: none;
    }

    &.focused {
        .input-label {
            font-size: rem-calc(12);
            color: color(black);
            transform: none;
            left: 0;
            top: 0;
            bottom: auto;
            pointer-events: auto;

            .credit-card-form & {
                margin-bottom: rem-calc(8);
                top: calc(100% - 48px);
            }
        }

        input {
            border-color: color(black);
        }
    }

    input {
        border-width: 0 0 1px 0 !important;
        height: rem-calc(54);
        padding: rem-calc(20 0 8 0);
        border-color: color(dark-gray);

        &:disabled {
            border-color: color(light-gray);
            & ~ .input-label {
                color: color(light-gray);
            }
        }

        &:focus {
            outline: none !important;
        }
    }
}

textarea {
    border: none;
    border-bottom: 1px solid color(dark-gray);
    padding: rem-calc(8 0);

    &::placeholder {
        color: color(dark-gray);
    }

    &:focus {
        border: none;
        border-bottom: 1px solid color(black);
        outline: none;
    }
}

.unknown-email-domain {
    display: none;
    font-size: rem-calc(14);
    color: $alert;
}
.form-error,
.invalid-feedback {
    display: none;
    margin-top: rem-calc(-8);
    margin-bottom: 1.5rem;
    margin-left: 0;
    font-size: rem-calc(12);
    font-weight: $global-weight-normal;
    color: $alert;
    margin-left: 0;
}

.is-invalid-input:not(:focus):not(.boolean-field),
input.is-invalid:not(:focus),
select.is-invalid:not(:focus),
textarea.is-invalid:not(:focus) {
    background-color: transparent;
    border-color: $alert;

    &::placeholder {
        color: gray(1);
    }

    &::-webkit-input-placeholder {
        color: gray(1);
    }

    &::-moz-placeholder {
        color: gray(1);
    }
}

select {
    &:focus {
        outline: rem-calc(2) solid $black;
    }

    option {

        &:checked,
        &:hover {
            box-shadow: 0 0 10px 100px $black inset;
        }
    }
}

label {
    &.inline {
        display: inline-block;
        margin-right: rem-calc(32);
    }
}

.is-invalid-label {
    select,
    input {
        border-color: $alert;
        margin-bottom: rem-calc(15);
    }
}

.parsley-errors-list {
    list-style-type: none;
    margin-left: 0;

    li {
        top: 0;
        margin-bottom: rem-calc(20);
        margin-left: 0;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        color: $alert;
    }
}

// Input Autocomplete
.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;

    input {
        margin-bottom: 0;
    }

    .parsley-errors-list {
        margin-top: $input-padding;
    }

    .autocomplete-items {
        position: relative;
        border: 1px solid $black;
        border-bottom: none;
        border-top: none;
        z-index: 101;
        /*position the autocomplete items to be the same width as the container:*/
        top: 100%;
        left: 0;
        right: 0;
    }

    .autocomplete-items div {
        padding: rem-calc(8);
        cursor: pointer;
        background-color: $white;
        border-bottom: 1px solid $black;
    }

    .autocomplete-items div:hover {
        /* When hovering an item */
        background-color: $lighter-gray;
    }

    .autocomplete-active {
        /* When navigating through the items using the arrow keys */
        background-color: $medium-gray !important;
        color: $white;
    }
}

.change-email-form-error {
    margin-top: -0.75rem;
    margin-bottom: 1.5rem;
    margin-left: 0;
    font-size: rem-calc(12);
    font-weight: $global-weight-normal;
    color: $alert;
}
