.quantity {
    &-circle {
        outline: none;
        display: inline-block;
        background: transparent;
        border: 1px solid $border-gray;
        color: $primary;
        appearance: none;
        width: rem-calc(24);
        height: rem-calc(24);
        line-height: rem-calc(24);
        border-radius: 50%;
        font-size: rem-calc(18);
        text-align: center;
        cursor: pointer;
        font-weight: $global-weight-normal;
        transition: $input-transition;

        &:hover,
        &:active {
            border-color: $primary;
        }
    }

    &-value {
        display: inline-block;
        margin: rem-calc(0 8);
    }

    &-button {
        color: black !important;
    }
}
