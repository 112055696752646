.wishlist-popover {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    min-width: rem-calc(33.44rem);
    max-width: rem-calc(33.44rem);
    min-height: rem-calc(6.25rem);
    display: none;

    &::before {
        left: auto;
        right: rem-calc(15px);
    }

    &::after {
        left: auto;
        right: rem-calc(16px);
    }

    &.show {
        display: block;

        @include breakpoint(small down) {
            display: none;
        }
    }

    & .product-info {
        padding-bottom: rem-calc(7);
        border-bottom: rem-calc(1) solid $dark-gray;
    }
}

/*
.shifted-text-top {
    position: relative;
    top: rem-calc(-5px);
}
*/

.promo {
    font-family: $secondary-font;
    font-size: rem-calc(12);
    font-weight: 600;
    line-height: rem-calc(18);
    padding-bottom: rem-calc(15);
    padding-left: rem-calc(16);
    padding-right: rem-calc(80);
    padding-top: rem-calc(15);
    text-align: left;
    text-transform: uppercase;
}

.minicart {
    .line-item {
        &-price-info {
            .value {
                font-weight: $global-weight-normal;
            }

            .strike-through~.sales {
                color: $sales;
                margin-left: rem-calc(8);

                &.loyalty1,
                &.loyalty2,
                &.loyalty3,
                &.loyalty4,
                &.loyaltysale1,
                &.loyaltysale2,
                &.loyaltysale3,
                &.loyaltysale4 {
                    color: $loyalty;
                }
            }
        }
    }

    @include breakpoint (small down) {
        &.open {
            position: fixed;
            display: block;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 3000;

            h6 {
                font-weight: $global-weight-dark;
            }
        }

    }

    h4 {
        font-size: 1rem;
    }

    .closeButton {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: rem-calc(40);
        margin: 0;
        width: rem-calc(24);
        @include breakpoint (medium down){
            right: rem-calc(24);
        }
    }


    .cart.approaching {
        &::before {
            border-bottom-color: transparent;
        }

        &::after {
            border-bottom-color: #F4F4F4;
        }

        .promo {
            background: #F4F4F4;
        }
    }

    .cart.promoapplicable,
    .cart.promoapplied {
        &::before {
            border-bottom-color: transparent;
        }
        &::after {
            border-bottom-color: #F2EDED;
        }
        .promo {
            background: #F2EDED;
            margin: 0;
            padding: rem-calc(20 80 20 40);
            @include breakpoint (medium down){
                padding: rem-calc(20 60 20 16);
            }
        }
        @include breakpoint (medium up){
            max-height: calc(100vh - 70px);
            display: flex;
            flex-direction: column;
            .minicart-popup-container{
                flex-shrink: 100000;
            }
        }
        
    }

    .cart {
        background: rgba(255, 255, 255, 1);
        backdrop-filter: blur(40px);
        box-shadow: $prototype-box-shadow;

        &:after,
        &:before {
            bottom: 100%;
            border: solid transparent;
            content: " ";
            display: none;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-bottom-color: $white;
            border-color: transparent;
            border-width: rem-calc(10);
            margin-left: rem-calc(-10);
            right: 18px;
        }

        &:before {
            right: 17px;
            border-color: transparent;
            border-bottom-color: $border-gray;
            border-width: rem-calc(11);
            margin-left: rem-calc(-11);
        }

        .banner-add-bonus {
            background-color: #FFD3CF;
        }
        /*
        .banner-no-add-bonus {
            background-color: #FFD3CF;
        }
        */
    }

    .wishlist-minicart {
        &:after {
            right: 58px;
        }

        &:before {
            right: 57px;
        }
    }
    /*
    .remove-btn {
        color: $medium-gray;
        float: right;
        background-color: $white;
        border: none;
        font-size: rem-calc(1.625em);
        margin-top: rem-calc(-0.313em);
        padding: 0;
    }
    */
    /*
    .product-summary {
        max-height: rem-calc(21.875em);
        overflow-y: auto;
        overflow-x: hidden;
    }
    */

    .price {
        font-weight: 500;
        color: $black !important;

        .strike-through {
            color: $light-gray;
            margin-left: rem-calc(8);

            &:hover {
                text-decoration: line-through;
            }
        }

        .strike-through~.sales {
            color: $primary;
            margin-left: rem-calc(8);

        }
    }

    .text-attributes-product-mini {
        margin: 0;
        margin-bottom: rem-calc(2);
        &.header-section{
            background: #F2EDED;
            padding: rem-calc(20 40);
            @include breakpoint (medium down){
                padding: rem-calc(20);
                &.mini-cart{
                    padding: rem-calc(24 40);
                }
            }
        }
    }

    .card-body {
        padding-top: rem-calc(9);
        padding-bottom: rem-calc(15);
        padding-right: 0;
        padding-left: 0;
        @include breakpoint (medium down){
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .js-giftkit-popover {
        button {
            position: absolute;
            top: rem-calc(15);
            right: rem-calc(40);
            width: rem-calc(15);
            height: rem-calc(15);
            @include breakpoint (medium down){
                top: rem-calc(13);
                right: rem-calc(20);
            }
        }
        .card-body {
            padding: 0;
            @include breakpoint (medium down){

            }
            &-title {
                padding: rem-calc(20 0 20 40);
                background-color: #F2EDED;
                display: flex;
                align-items: center;
                @include breakpoint (medium down) {
                    padding-left: rem-calc(16);
                }
            }
            .notification-check-add {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.2);
                margin: rem-calc(0 0 0 14);
            }
            .product-line-item {
                background: rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(40px);
                padding: rem-calc(24 0 24 40);
                @include breakpoint (medium down) {
                    padding-left: rem-calc(16);
                }
                .line-item-attributes {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                }
            }
        }
    }

    .price,
    .quantity-label {
        font-size: rem-calc(0.813em);

        span {
            font-size: rem-calc(14);
        }
    }

    .quantity {
        width: 100%;
    }

    .popover {
        position: absolute;
        top: rem-calc(71);
        left: auto;
        right: 0;
        display: none;
        z-index: 100;
        @include breakpoint (medium down){
            top: unset;
        }

        @include breakpoint (medium up) {
            min-width: rem-calc(30.44rem);
            max-width: rem-calc(30.44rem);
            min-height: rem-calc(4.25rem);

            &:before {
                left: auto;
                border-left: rem-calc(8) solid transparent;
                border-right: rem-calc(8) solid transparent;
                border-bottom: rem-calc(10) solid $white;

                position: relative;
                right: rem-calc(10);
                bottom: rem-calc(10);
            }
        }

        @include breakpoint (small down) {
            &.show {
                position: relative;
                display: block;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                min-height: rem-calc(20);
                height: 100vh;
                margin: 0;
                overflow-y: auto;
                top: 0;
                left: 0;
                right: 0;
                z-index: 3000;
                box-shadow: $shadow-default;
            }
        }

        &.show {
            display: block;
            @include breakpoint (medium down) {
                top: 0;
                height: 100dvh;
                backdrop-filter: blur(40px);
                .closeButton {
                    top: rem-calc(40);
                }
            }
        }

        .minicart-popup-container {
            max-height: calc(100vh - 37vh);
            @include breakpoint (medium down) {
                max-height: unset;
                height: 100vh;
            }
            
            overflow-y: auto;
            scroll-behavior: smooth;
            .double-currency-sales{
                font-size: rem-calc(12);
            }
            .price-second-currency-wrapper{
                margin-bottom: rem-calc(8);
                p{
                    margin: 0;
                }
                .double-currency-list.strike-through{
                    font-size: rem-calc(12);
                }
            }
            .container-title-arrows {
                padding: rem-calc(0 16);
            }
            &::-webkit-scrollbar{
                display: none;
            }

            .each-item-price {
                display: none !important;
            }
        }

        .card {
            padding-left: rem-calc(40);
            padding-right: rem-calc(40);
            background: none;
            margin-top: rem-calc(24);
            margin-bottom: rem-calc(25);
            @include breakpoint (medium down){
                margin-top: rem-calc(16);
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
            }
            &.wishlist-cart{
                margin-top: 0;
                margin-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                button{
                    position: absolute;
                    top: rem-calc(15);
                    right: rem-calc(40);
                    width: rem-calc(15);
                    height: rem-calc(15);
                    @include breakpoint (medium down){
                        top: rem-calc(18);
                        right: rem-calc(20);
                    }
                }
                .card-body{
                    padding: 0;
                    .miniwishlist-icon{
                        margin-right: rem-calc(14);
                    }
                    .miniwishlist-wrapper{
                        padding: rem-calc(24 40);
                        @include breakpoint (medium down){
                            padding: rem-calc(24 40);
                        }
                        .wishlist-double-price{
                            font-size: rem-calc(12);
                            font-weight: 400;
                        }
                        .miniwishlist-wrapper-image{
                                padding-right: rem-calc(16);
                        }
                    }
                }
            }
        }

        .checkout-continue {
            width: 100%;
            margin-top: rem-calc(24);
        }

        .font-normal {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
        }

        .sub-total {
            font-size: rem-calc(14);
            margin-bottom: rem-calc(8);
        }
    }

    .left-margin {
        left: rem-calc(1150);
    }
    /*
    .minicart-footer {
        @include breakpoint (medium up) {
            border-top: 1px solid $border-gray;
        }
    }
    */

    .estimated-total {
        margin-top: rem-calc(0.625em);
        padding: rem-calc(0 10);
    }

    .estimated-total-eu {
        padding: rem-calc(16 40 24 40);
        @include breakpoint (medium down) {
            padding: rem-calc(16);
        }
        
        position: sticky;
        bottom: 0;
        background: white;
        opacity: 95%;
    }

    .free-shipping-to-store-eu {
        img {
            height: rem-calc(15);
            margin: rem-calc(0 5 2 0);
        }
    }
    /*
    .button-grid-minicart {
        padding: rem-calc(0 10);
    }
    */
    /*
    .sub-total-label {
        font-size: rem-calc(1em);
        font-weight: 600;
    }
    */

    .line-item-divider {
        margin: rem-calc(0.625em) rem-calc(-0.625em) rem-calc(0.625em) rem-calc(-1.325em);
    }

    .line-item-name {
        width: 90%;
    }

    .checkoutButtons {
        a,
        button {
            width: 100%;
            min-width: 100%;
            margin: 0;
        }
    }
}
.card-minicart-quick {
    .line-item-price-info {
        margin: rem-calc(8) rem-calc(0) !important;
        span,
        .value {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
        }
        span.line-item-total-price-amount.dark.sales {
            &.loyaltysale1, &.loyaltysale2, &.loyaltysale3, &.loyaltysale4 {
                color: $loyalty;
            }
        }

        .strike-through {
            margin-right: rem-calc(4);
        }

        .value {
            font-weight: 600 !important;
            margin-right: 0;
        }
    }

    .text-attributes-product-mini {
        font-size: rem-calc(14);
        line-height: rem-calc(20);
    }
}
.free-shipping-text {
    align-items: center;
    display: flex;
    font-size: rem-calc(12);
    line-height: rem-calc(18);

    img {
        width: rem-calc(14);
        height: rem-calc(14);
        margin-right: rem-calc(5);
    }
}
.minicart {
    .cart {
        @include breakpoint (small down) {
            .only-border-bottom.lighter {
                border: none;
            }
            .estimated-total {
                display: none;
            }
        }
    }
}
.product-line-item {
    .minicart-card-image {
        height: rem-calc(136);
        margin-right: rem-calc(16);
        width: rem-calc(96);
    }
    &.product-line-item-eu {
        .line-item-attributes {
            font-size: rem-calc(14);
            &.line-item-product-name {
                font-family: $body-font-family;
                font-size: rem-calc(14);
                font-weight: 400;
                line-height: rem-calc(20);
            }
        }
    }
    .no-margin-bottom {
        margin-bottom: 0;
    }

    .line-item-product-info-taxt{
        font-size: rem-calc(12);
        margin: rem-calc(0 0 6);
        line-height: rem-calc(25);
        color: #4D4D4D;
    }
}
.no-border {
    border: none;
}
.miniCartCrossSellContainer {
    position: relative;

    padding: rem-calc(0 40 24 40);
    @include breakpoint (medium down) {
        padding: rem-calc(0 0 24 0);
    }

    &-header {
        padding-bottom: rem-calc(25);
        width: 100%;
        &-title {
            font-family: $secondary-font;
            font-size: rem-calc(16);
            font-weight: 400;
            line-height: rem-calc(22);
            letter-spacing: -0.02em;
        }
    }
    &-carousel {
        .product {
            padding: rem-calc(0 4);
            .productDescription {
                margin-bottom: 0;
                padding: rem-calc(18 4 0);
                @include flexGenerator(column);
                flex-grow: 0;
                &-name {
                    .pdp-link .link {
                        white-space: normal;
                        font-size: rem-calc(14);
                        line-height: rem-calc(20);
                    }

                    margin-bottom: rem-calc(8);
                }
                &-priceRow {
                    margin-bottom: rem-calc(15);
                    & + .cc-omnibusStack {
                        @include breakpoint (large up) {
                            margin-top: rem-calc(-8);
                        }
                    }
                    * {
                        line-height: rem-calc(18) !important;
                        letter-spacing: .6px !important;
                    }
                    .span-price-row-plp {
                        @include flexGenerator(row, flex-start, center);
                    }

                    .strike-through.list, .percentage {
                        @include breakpoint (small down) {
                            margin-left: rem-calc(4);
                            font-size: rem-calc(12);
                        }
                    }
                }

                .cc-omnibusStack {
                    &-sales {
                        display: none;
                    }
                    &-item {
                        &_regularPrice {
                            display: none;
                        }
                    }
                }
            }
        }
        .price-second-currency{
            font-size: rem-calc(12);
            font-weight: 400;
        }
        .arrow-minicart{
            margin-left: rem-calc(20);
        }
        .slick-arrow {
            top: rem-calc(-29);
            left: unset;
            @include fixWidth(16px);
            @include fixHeight(16px);
            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
            }
            &.slick-prev {
                right: rem-calc(31.6);
                &:before {
                    content: url('../images/arrow-left-new.svg');
                }
            }
            &.slick-next {
                right: 0;
                &:before {
                    content: url('../images/arrow-right-new.svg');
                }
            }
            &.slick-disabled{
                opacity: 0.5;
            }
        }

        .price-info-pdp-container {
            @include breakpoint (small down) {
                font-size: rem-calc(10);
                line-height: rem-calc(14);
            }
        }
    }
    +.estimated-total-eu {
        border-top: unset;
    }
    .quickview-icon {
        display: none !important;
    }
    .productDescription-promo {
        height: auto;
        line-height: rem-calc(12);
        font-size: rem-calc(9);
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: rem-calc(0.2);
        margin: 0;
        margin-bottom: rem-calc(5);
        color: #FF5959;
        @include breakpoint (large up) {
            font-size: rem-calc(10);
            line-height: rem-calc(14);
            margin-bottom: 0;
        }
    }
    .miniCartCrossSellContainer-carousel{
        margin: rem-calc(0 -4);
        width: calc(100% + 8px);
    }
    .prev-button, .next-button{
        cursor: pointer;
    }
    .next-button{
        margin-left: rem-calc(16);
    }
    .promo-animated-strip {
        display: none;
    }
}
.grand-total-double-currency{
    font-size: rem-calc(12);
    margin-bottom: rem-calc(8);
}
.grand-total-convertion-rate{
    font-size: rem-calc(12);
    margin-bottom: rem-calc(8);
}

.strike-through.list {
    color: $light-gray;
}

.cc-omnibusStack {
    display: flex;
    flex-direction: column;
    gap: rem-calc(4);
    margin: rem-calc(4 0);
    @include breakpoint (large up) {
        margin: rem-calc(4 0 8 0);
    }

    &-item {
       font-size: rem-calc(12);
       line-height: rem-calc(17);
       color: $dark-gray;
    }

    &-strikeThrough {
        text-decoration: line-through;
    }

    &-sales {
        color: $sales;
    }
}