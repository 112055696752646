@use "sass:math";

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    /* For Google Chrome */
    &::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    /* For Internet Explorer */
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

@mixin fill {
    width: 100%;
    height: 100%;
}

@mixin helpers() {
    .pos-relative {
        position: relative;
    }

    .hidden {
        display: none;
    }

    .no-margin {
        margin: 0 !important;
    }

    .no-margin-top {
        margin-top: 0 !important;
    }

    .no-padding {
        padding: 0 !important;
    }

    .cover {
        object-fit: cover;
        transform-origin: 50% 50%;
        width: 100%;
        height: 100%;
    }

    .visuallyhidden {
        position: absolute !important;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
        border: 0;
    }

    .small {
        &-text-center {
            @include breakpoint(small down) {
                text-align: center;
            }
        }

        &-align-center {
            @include breakpoint(small down) {
                justify-content: center;
            }
        }
    }

    .medium {
        &-text-center {
            @include breakpoint(medium down) {
                text-align: center;
            }
        }

        &-align-center {
            @include breakpoint(medium down) {
                justify-content: center;
            }
        }
    }

    // Standard SFRA
    .d-none {
        display: none !important;
    }
    .hide-order-discount, .hide-product-discount, .hide-shipping-discount {
        display: none;
    }
    .hide-cod-item {
        display: none;
    }
    .non-adjusted-price {
        display: none;
    }
}

@mixin triangle($size, $color) {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 $size $size;
    border-color: transparent transparent $color;
    margin-top: -$size/2;
    transition: .3s;
}

/// Generate border class helpers
///
/// @param {Color} $color - border color
@mixin borders($color, $break) {
    $positions: ('top',
            'left',
            'right',
            'bottom'
        );

    @each $pos in $positions {
        @include breakpoint($break) {
            .border-#{$pos} {
                border-#{$pos}: 1px solid $color;
            }
        }
        @each $breakpoint, $size in $breakpoints {
            .#{$breakpoint}-border-#{$pos} {
                @include breakpoint($breakpoint down) {
                    border-#{$pos}: 1px solid $color;
                }
            }
        }
    }
}

@mixin full-container() {
    @each $breakpoint, $size in $breakpoints {
        .#{$breakpoint}-full {
            @include breakpoint($breakpoint down) {
                padding-right: 0;
                padding-left: 0;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
}

@mixin flexGenerator($direction, $primary: flex-start, $secondary: stretch) {
    display: flex;
    flex: 1 0 auto;
    flex-direction: $direction;
    justify-content: $primary;
    align-items: $secondary;
    align-content: $secondary;
}
@mixin fixHeight($height) {
    height: $height;
    min-height: $height;
    max-height: $height;
}
@mixin fixWidth($width) {
    width: $width;
    min-width: $width;
    max-width: $width;
}
@mixin truncateRow($numRow:1) {
    overflow: hidden;
    text-overflow: ellipsis;
    @if $numRow>1 {
        display: -webkit-box;
        -webkit-line-clamp: $numRow;
        -webkit-box-orient: vertical;
    }
    @else {
        white-space: nowrap;
    }
}

.space-between {
    justify-content: space-between;
}

$spacing-multiplier: (0, 1, 4);
$spacing-breakpoint: ('m-', '', 'd-');
$space-unit: 0.4rem;

@each $multiply in $spacing-multiplier {
  $space: $space-unit * $multiply;
    /*
  .mb-#{$multiply} {
    margin-bottom: $space;
  }
  */
  .mt-#{$multiply} {
    margin-top: $space;
  }
  /*
  .ml-#{$multiply} {
    margin-left: $space;
  }
  */
  /*
  .mr-#{$multiply} {
    margin-right: $space;
  }
  */
  .pb-#{$multiply} {
    padding-bottom: $space;
  }
  /*
  .pt-#{$multiply} {
    padding-top: $space;
  }*/
  /*
  .pl-#{$multiply} {
    padding-left: $space;
  }*/
  /*
  .pr-#{$multiply} {
    padding-right: $space;
  }*/
  /*
  .my-#{$multiply} {
    margin-bottom: $space;
    margin-top: $space;
  }
  */
  /*
  .mx-#{$multiply} {
    margin-left: $space;
    margin-right: $space;
  }*/
  .py-#{$multiply} {
    padding-bottom: $space;
    padding-top: $space;
  }
  /*
  .px-#{$multiply} {
    padding-left: $space;
    padding-right: $space;
  }*/
  /*
  .my-auto{
    margin-top: auto;
    margin-bottom: auto;
  }
  */
  /*
  .mx-auto{
    margin-left: auto;
    margin-right: auto;
  }*/
  /*
  .py-auto{
    padding-top: auto;
    padding-bottom: auto;
  }*/
  /*
  .px-auto{
    padding-left: auto;
    padding-right: auto;
  }*/
}