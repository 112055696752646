.button-group {
    display: flex;
    flex-direction: row;

    > * {
        margin-right: rem-calc(16);

        &:last-child {
            margin-right: 0;
        }
    }

}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: $secondary-font;
    font-weight: $global-weight-semibold;
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    letter-spacing: rem-calc(0);
    color: color(white);
    text-decoration: none;
    position: relative;
    border: 1px solid color(black);
    border-radius: rem-calc(8);
    padding: rem-calc(4) rem-calc(24);
    height: rem-calc(40);
    transition: all 0.23 ease-in-out;

    &:not(:disabled):not(.disabled) {
        @media (hover: hover) {
            &:hover {
                text-decoration: none;
                color: color(white);
                background: rgba($black, 0.4);
                border-color: transparent;
            }
        }
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
        background: color(light-gray);
        border-color: color(light-gray);
        color: color(white);
        cursor: not-allowed;
    }

    &.white {
        color: color(black);
        border-color: color(white);
        background: color(white);

        &:not(:disabled):not(.disabled) {
            &:hover {
                text-decoration: none;
                color: color(black);
                background: rgba($white, 0.4);
                border-color: transparent;
            }
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:focus,
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus {
            background: color(light-gray);
            border-color: color(light-gray);
            color: color(white);
            cursor: not-allowed;
        }
    }

    &.hollow {
        color: color(black);
        background: transparent;

        &:not(:disabled):not(.disabled) {
            &:hover {
                text-decoration: none;
                color: color(white);
                background: rgba($black, 0.4);
                border-color: transparent;
            }
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:focus,
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus {
            background: color(light-gray);
            border-color: color(light-gray);
            color: color(white);
            cursor: not-allowed;
        }
    }

    &.hollow-white {
        color: color(white);
        background: transparent;
        border-color: color(white);

        &:not(:disabled):not(.disabled) {
            &:hover {
                text-decoration: none;
                color: color(black);
                background: rgba($white, 0.4);
                border-color: transparent;
            }
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:focus,
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus {
            background: color(light-gray);
            border-color: color(light-gray);
            color: color(white);
            cursor: not-allowed;
        }
    }

    &.arrow {
        font-size: rem-calc(14);
        padding-left: 0;
        padding-right: 0;
        background: transparent;
        border-color: transparent;
        color: color(black);

        i {
            margin-left: rem-calc(24);
            width: rem-calc(50);
        }

        @include breakpoint (medium up) {
            font-size: rem-calc(16);
        }

        &:not(:disabled):not(.disabled) {
            &:hover {
                text-decoration: none;
                color: color(dark-gray);
                background: transparent;
                border-color: transparent;
            }
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:focus,
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus {
            color: color(light-gray);
            background: transparent;
            border-color: transparent;
            cursor: not-allowed;
        }
    }

    &.arrow-white {
        font-size: rem-calc(14);
        padding-left: 0;
        padding-right: 0;
        background: transparent;
        border-color: transparent;
        color: color(white);

        i {
            margin-left: rem-calc(24);
            width: rem-calc(50);
        }

        @include breakpoint (medium up) {
            font-size: rem-calc(16);
        }

        &:not(:disabled):not(.disabled) {
            &:hover {
                text-decoration: none;
                color: color(white);
                background: transparent;
                border-color: transparent;
                opacity: 0.6;
            }
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:focus,
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus {
            color: color(light-gray);
            background: transparent;
            border-color: transparent;
            cursor: not-allowed;
        }
    }

    // Back buttons
    &-back {
        border: none;
        background: none;
        color: $black;
        border-radius: 0;
        font-size: rem-calc(16);
        text-transform: none;
        text-decoration: none;
        background-image: url('../images/chevron-left.svg');
        background-repeat: no-repeat;
        background-size: rem-calc(14);
        background-position: left center;

        &:hover,
        &:active,
        &:focus {
            background: none;
            border: none;
            background-image: url('../images/chevron-left.svg');
            background-repeat: no-repeat;
            background-size: rem-calc(14);
            background-position: left center;
            color: $black;
            text-decoration: none!important;
        }
    }

    &.extended {
        width: 100%;
        min-width: 100%;
    }

    &.expanded {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        padding-left: rem-calc(4);
        padding-right: rem-calc(4);
    }

    &.vertical {
        transform: rotate(90deg);
        margin-bottom: 0;
    }

    &.inline {
        transform: translateX(-100%);
        position: absolute;
        height: 100%;
        text-decoration: underline;
    }

    // Spans inside buttons
    span {
        font-weight: $global-weight-semibold;
    }

    // Icons inside buttons
    i {
        display: inline-block;
        margin-left: rem-calc(8);

        svg {
            margin: 0;
        }
    }

    &-tertiary{
        height: auto;
    }
}

// LINK CTAs - See UI Kit for examples
a.link {
    display: inline-block;
    position: relative;
    font-family: $secondary-font;
    font-weight: $global-weight-semibold;
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    letter-spacing: 0;
    color: color(black);
    text-decoration: none;
    text-transform: none;
    position: relative;
    transition: all 0.23 ease-in-out;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 1px;
        background-color: color(black);
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:focus {
            text-decoration: none;
            color: color(dark-gray);

            &::before {
                background-color: color(dark-gray);
            }
        }
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
        color: color(light-gray);
        cursor: not-allowed;
        text-decoration: none;

        &::before {
            background-color: color(light-gray);
        }
    }

    &.small {
        font-size: rem-calc(12);
    }

    &.white {
        color: color(white);

        &::before {
            background-color: color(white);
        }

        &:not(:disabled):not(.disabled) {
            &:hover,
            &:focus {
                text-decoration: none;
                color: color(white);
                opacity: 0.6;

                &::before {
                    background-color: color(white);
                }
            }
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:focus,
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus {
            color: color(light-gray);
            cursor: not-allowed;
            text-decoration: none;

            &::before {
                background-color: color(light-gray);
            }
        }
    }
}

#onetrust-consent-sdk #onetrust-banner-sdk.otCenterRounded .ot-sdk-container .ot-sdk-row #onetrust-button-group-parent #onetrust-button-group .banner-actions-container #onetrust-accept-btn-handler {
    font-family: $secondary-font !important;
    font-weight: $global-weight-semibold !important;
    line-height: rem-calc(20) !important;
    letter-spacing: rem-calc(0) !important;
    border-radius: rem-calc(8) !important;
    text-transform: initial !important;
}
