$spinner-line-default-size: 4px;
$spinner-default-size: 48px;
$spinner-default-dash-size: 12px;

$spinner-line-small-size: 2px;
$spinner-small-size: 16px;
$spinner-small-dash-size: 8px;

.veil {
    position: fixed;
    z-index: 4000;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background-color: rgba($black, 0.2);
    }

    .spinner {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

.thank-you-image-wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: rem-calc(50);
  
    
    .landing_page_loader_img {
      border: rem-calc(8) solid #dddddd;
      border-radius: 50%;
      border-top: rem-calc(8) solid #575757;
      width: rem-calc(60);
      height: rem-calc(60);
      -webkit-animation: landing_page_loader_spin 2s linear infinite;
      animation: landing_page_loader_spin 2s linear infinite;
    }
    
    @-webkit-keyframes landing_page_loader_spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes landing_page_loader_spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  
  }

.spinner {
    display: block;
    height: $spinner-default-size;
    width: $spinner-default-size;
    animation: spinner-anim 3s linear infinite;

    span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: $spinner-default-size;
        width: $spinner-default-size;
        clip: rect($spinner-default-dash-size, $spinner-default-size, $spinner-default-size, 0);
        animation: spinner-anim 1.5s linear infinite;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: $spinner-default-size;
            width: $spinner-default-size;
            border: $spinner-line-default-size solid $primary;
            border-radius: 50%;
        }
    }

    &.small {
        height: $spinner-small-size;
        width: $spinner-small-size;

        span {
            height: $spinner-small-size;
            width: $spinner-small-size;
            clip: rect($spinner-small-dash-size, $spinner-small-size, $spinner-small-size, 0);

            &:after {
                height: $spinner-small-size;
                width: $spinner-small-size;
            }
        }
    }
}

@keyframes spinner-anim {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
