
@import "slick/slick.scss";
@import "slick/slick-theme.scss";


slick-slide {
    min-height: inherit!important;
}

.slick-list {
    .slick-loading & {
        background: #fff url("../images/ajax-loader.gif") center center no-repeat;
    }
}
/*
.image-special-listing{
    @include breakpoint(medium up) {
    width:100%;
    padding-right:40px;
    }
    @include breakpoint(small down){
        padding-right: 20px;
    }
}
*/
/* .slick-dots {
    li {
        width: rem-calc(10);
        height: rem-calc(10);

        button:before {
            opacity: 1;
            content: '';
            width: rem-calc(10);
            height: rem-calc(10);
            border-radius: 50%;
            border: 1px solid $border-gray;
            background: transparent;
        }

        &.slick-active {
            button:before {
                opacity: 1;
                border-color: $black;
                background: $black;
            }
        }
    }
} */

/* Slick Basic orizontal */
.slick-dotted{
    .slick-dots{
        bottom: rem-calc(20);
        li{
            width:auto;
            height: auto;
            margin: 8px 0;
            button{
                height:rem-calc(3);
                padding:0;
                &:before{
                    content: '';
                    width: rem-calc(10);
                    height: rem-calc(10);
                    border-radius: rem-calc(10);
                    border: 0;
                    background: $dark-gray;
                    margin-top:rem-calc(1);
                }
                
            }
            &.slick-active{
                button{
                    &:before{
                        content: '';
                        width: rem-calc(10);
                        height: rem-calc(10);
                        border-radius: rem-calc(10);
                        border: 0;
                        background: $white;
                        margin-top: 0;
                    }
                }  
            }
        }
    }
}
/* Remove slick font family from nav and dots */
.slick-prev:before,
.slick-next:before,
.slick-dots li button:before {
    font-family: $body-font-family;
}

// SLICK CUSTOM FOR IMAGE VERTICAL MOBILE
@include breakpoint(small down) {
    .slick-vertical .slick-slide.slick-current.slick-active {
        position: static !important;
        left: 0!important;
        top: 0!important;
        z-index: 999!important;
    }

}