.privacy-policy-marketing-popup {
    z-index: 1500;

    .tingle-modal-box__content {
        padding: rem-calc(40);
    }

    .close-button-privacy {
        right: rem-calc(40);
        top: rem-calc(40);

        @include breakpoint(medium down) {
            top: rem-calc(24);
            right: rem-calc(16);
        }
    }

    .form-request-title {
        font-size: rem-calc(16);
        font-weight: 600;
        margin-bottom: rem-calc(16);
    }

    .intro-content p {
        margin-bottom: 0;
        font-size: rem-calc(14);

        a {
            font-weight: 400;
        }
    }

    strong, b {
        font-weight: 400;
    }


    @include breakpoint(small down) {
        .form-request-title {
            width: 85%;
            padding-right: rem-calc(21);
        }

        .tingle-modal-box__content {
            padding: rem-calc(24 16);
        }

        .form-request-title {
            margin-bottom: rem-calc(16);
        }
    }
}

.privacy-policy-profiling-popup {
    z-index: 1500;

    @include breakpoint(small down) {
        .form-request-title {
            width: 85%;
            padding-right: rem-calc(21);
        }
    }
}

.form-request-title {
    font-size: rem-calc(20);
    line-height: rem-calc(26);
    margin-bottom: rem-calc(24);
    font-weight: 400;

    @include breakpoint(medium down) {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
    }
}

.form-request-paragraph {
    font-size: rem-calc(14);
    margin-bottom: rem-calc(40);
}

.form-request-paragraph-no-margin {
    font-size: rem-calc(16);

    @include breakpoint(medium down) {
        font-size: rem-calc(14);
    }
}

.intro-content {
    text-align: justify;
}