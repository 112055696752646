// Loyalty modal
.cc-loyaltyModal {
    .registrationModal-box-header {
        padding-bottom: rem-calc(40);

        @include breakpoint (medium down) {
            padding-bottom: rem-calc(24);
            position: relative;

            .cc-arrow-button {
                display: none;
            }
        }

        @include breakpoint (large up) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .cc-arrow-button {
                margin-left: 0;
            }

            .close {
                position: static;
            }
        }
    }

    &__features {
        background-color: map-get($brand-palette, loyalty);
        padding: rem-calc(24 16);
        margin: rem-calc(0 -16 24);

        @include breakpoint (large up) {
            padding: rem-calc(40);
            margin: rem-calc(0 -40 40);
        }

        h2 {
            color: map-get($brand-palette, white);
            font-size: rem-calc(20);
            font-weight: 400;
            letter-spacing: rem-calc(0.4);
            margin-bottom: rem-calc(26);

            @include breakpoint (medium down) {
                line-height: rem-calc(26);
            }
        }

        ul {
            margin: 0;
            list-style-type: none;

            li {
                margin-bottom: rem-calc(20);
            }

            li:last-child {
                margin-bottom: 0;
            }
        }

        &__title {
            text-transform: unset;
        }

        &__item {
            display: flex;
            align-items: center;

            i {
                width: rem-calc(24);
                height: rem-calc(24);
                margin-right: rem-calc(8);
                vertical-align: middle;
                text-align: center;
            }

            img {
                display: block;
                width: rem-calc(24);
                height: rem-calc(24);
                margin-right: rem-calc(8);
                flex: 0 0 rem-calc(24)
            }

            svg {
                filter: invert(1);
                width: 80%;
                height: 80%;
            }

            span {
                color: map-get($brand-palette, white);
                font-size: rem-calc(14);
                font-weight: 400;
                line-height: rem-calc(20);
                margin-bottom: 0;
            }
        }
    }

    .font-16 {
        color: map-get($brand-palette, black);
        font-size: rem-calc(20);
        font-weight: 400;
        letter-spacing: rem-calc(-0.4);
        margin-bottom: rem-calc(16);
    }

    .associate-description {
        margin-bottom: rem-calc(32);

        @include breakpoint (large up) {
            margin-bottom: rem-calc(40);
        }
    }

    .login-datepicker {
        input {
            border: 0;
            margin-bottom: rem-calc(16);
            border-bottom: 1px solid  map-get($brand-palette, dark-gray);

            &:focus {
                border-color: map-get($brand-palette, dark-gray);
                outline: none;
            }

            &::placeholder {
                color: map-get($brand-palette, dark-gray);
            }

            @include breakpoint (large up) {
                margin-bottom: rem-calc(32);
            }
        }

        .input-label {
            font-size: rem-calc(14);
            color: map-get($brand-palette, dark-gray);
            transform: none;
            left: 0;
            top: rem-calc(-40);
        }

        input,
        &.focused input {
            color: map-get($brand-palette, dark-gray);
            border-color: map-get($brand-palette, dark-gray);
        }

        .icon-datepicker {
            position: absolute;
            right: 0.28571rem;
            top: rem-calc(23);
        }
    }

    .datepicker-box {
        .input-label {
            color: map-get($brand-palette, black);
            font-size: rem-calc(14);
            line-height: rem-calc(20);
        }
    }

    .calzedonia-card {
        margin-top: rem-calc(32);
    }

    .opposition.cell {
        margin-top: rem-calc(8);
    }

    .layer-footer {
        padding: rem-calc(32 16 0);
        border-top: 1px solid map-get($grays-palette, 4);
        margin: rem-calc(0 -16);

        @include breakpoint (large up) {
            border-top: 0;
            padding: rem-calc(16 16 0);
        }
    }

    .info-icon .icon-info-gray {
        color: map-get($brand-palette, black);
        border-color: map-get($brand-palette, black);
    }

    .boolean-field input[type="checkbox"]:disabled ~ .checkbox-label, .boolean-field input[type="checkbox"]:disabled ~ .radio-label, .boolean-field input[type="radio"]:disabled ~ .checkbox-label, .boolean-field input[type="radio"]:disabled ~ .radio-label {
        color: map-get($brand-palette, black);
    }

    .tingle-modal-box__content .login-form-error.login-form-error-block {
        padding-bottom: 0;
    }

    .cc-registrationModal #loyalty-form-cardCode, .cc-registrationModal #loyalty-form-cardPin {
        @include breakpoint (large up) {
            margin-bottom: rem-calc(32);
        }
    }

    .associate-card-title {
        font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
    }

    .loyalty-opposition {
        display: flex;

        .info-icon {
            margin-top: rem-calc(2);
        }

        .boolean-field {
            padding-right: rem-calc(4);
        }

        &.hidden {
            display: none;
        }

        .checkbox-label {
            pointer-events: none;

            .info-icon {
                pointer-events: all;
            }
        }
    }

    .datepicker-box {
        @include breakpoint (large up) {
            .grid-x {
                margin: 0 rem-calc(-8);
            }

            .grid-margin-x>.small-4 {
                width: auto;
                flex: 1;
                padding: rem-calc(0 8);
                margin: 0;
            }
        }

        select {
            background-position: right rem-calc(8) bottom rem-calc(10);
        }
    }

    .loyalty-opposition {
        .info-icon .tooltip {
            right: unset;
            left: 0;
        }
    }

    .tingle-modal-box .close {
        @include breakpoint (medium down) {
            top: rem-calc(10);
        }
    }
}


#registrationModal-box {
    &[data-registration-modal-stage="content"] {
        .cc-arrow-button {
            display: none;
        }
    }
}

.layer-login-modal .tingle-modal-box__content .loyalty-registration-modal-registration {
    .login-form-error {
        margin-bottom: rem-calc(16);
        margin-top: rem-calc(-16);
    }
}

.loyalty-registration-modal-content {
    .lover-become-member-text {
        margin-bottom: rem-calc(8);
    }

    .continue-shoping-button {
        margin: rem-calc(24) auto 0;
        display: block;
        font-size: rem-calc(16);
        font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
        font-weight: 600;
        line-height: rem-calc(22);
        padding: 0 0 rem-calc(1);
        border-bottom: rem-calc(1) solid $black;
        color: $black;
    }

    .text-register-privacy-policy {
        margin-bottom: rem-calc(24);
    }
}

.cc-privacyTooltipOpposition{
    &-tooltip{
        width: rem-calc(200);
        position: absolute;
        top: rem-calc(10);
        left: rem-calc(85);
        background: $white;
        padding: rem-calc(8);
        border: rem-calc(1) solid $black;
        border-radius: rem-calc(5);
        transform: translate(rem-calc(-8), calc(-100% - #{rem-calc(16)}));
        @include breakpoint (large up){
            left: rem-calc(135);
        }
        &_registerOpposition{
            font-size: rem-calc(10);
            font-weight: $global-weight-normal;
            letter-spacing: normal;
            line-height: rem-calc(18);
        }
        #tooltip-text{
            color: $black;
            margin: 0;
        }
    }
}
