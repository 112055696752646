.boolean-field {
    display: inline-flex;
    position: relative;
    margin-bottom: $global-margin;

    &:not(:last-child){
        padding-right: rem-calc(30);
    }
    &[data-is-size-offline='true']{
        &, & + br, & + .specialHr {
            display: none;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;

        &:disabled {
            cursor: not-allowed;

            ~ .checkbox-label,
            ~ .radio-label {
                cursor: not-allowed;
                color: color(light-gray);
            }

            ~ .checkbox-input {
                &:before {
                    border: 1px solid color(light-gray);
                    cursor: not-allowed;
                }

                &:after {
                    background: color(light-gray);
                }
            }
        }
    }

    .checkbox-label,
    .radio-label {
        color: $black;
        cursor: pointer;
    }

    .radio-label {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
    }

    .checkbox-label {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
    }

    .checkbox-input {
        display: inline-block;
        position: relative;
        padding-left: rem-calc(20);
        text-transform: none;
        margin-right: rem-calc(8);

        &:before,
        &:after {
            content: "";
            position: absolute;
        }

        &:before {
            top: 0;
            left: 0;
            width: rem-calc(20);
            height: rem-calc(20);
            border-radius: rem-calc(4);
            background-color: transparent;
            border: 1px solid color(black);
            transition: $input-transition;
            cursor: pointer;
            outline: none !important;
        }

        &:after {
            top: 0;
            left: rem-calc(0);
            width: rem-calc(20);
            height: rem-calc(20);
            background: color(black);
            background-size: 100%;
            background-position: center center;
            opacity: 0;
            transform: scale3d(0, 0, 0);
            transition: $input-transition;
            border-radius: rem-calc(4);
            cursor: pointer;
        }
    }

    .radio-input {
        display: inline-block;
        position: relative;
        padding-left: rem-calc(20);
        text-transform: none;
        margin-right: rem-calc(8);

        &:before,
        &:after {
            content: "";
            position: absolute;
        }

        &:before {
            top: 0;
            left: 0;
            width: rem-calc(20);
            height: rem-calc(20);
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid color(black);
            transition: all 0.2s;
            cursor: pointer;
        }

        &:after {
            top: 0;
            left: 0;
            border-radius: 50%;
            width: rem-calc(20);
            height: rem-calc(20);
            background-color: color(black);
            opacity: 0;
            transform: scale3d(0, 0, 0);
            transition: all 0.1s;
            cursor: pointer;
        }

        &.error-refund {
            &:before {
                border-color: color(alert);
            }

            &:after {
                background-color: color(alert);
            }
        }


        // Add squared modifier for checkbox-looking radios
        &--squared {
            &:after,
            &:before {
                border-radius: rem-calc(4);
            }
        }
    }

    input[type="checkbox"]:checked + .checkbox-input:after,
    input[type="checkbox"]:checked + .checkbox-input-round:after,
    input[type="radio"]:checked + .radio-input:after {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

    input[type="checkbox"]:focus ~ .checkbox-input:before {
        outline: rem-calc(2) solid $black;
    }

    &.is-invalid-input {
        background: transparent;

        input[type="checkbox"]:not(:focus) + .checkbox-input:before,
        input[type="radio"]:not(:focus) + .radio-input:before {
            border: 1px solid $alert;
        }
    }

    // &.is-valid-input {
    //     background: transparent;

    //     input[type="checkbox"] + .checkbox-input:before,
    //     input[type="radio"] + .radio-input:before {
    //         border: 2px solid $success;
    //     }

    //     input[type="checkbox"] + .checkbox-input:after,
    //     input[type="radio"] + .radio-input:after {
    //         background-color: $success;
    //     }
    // }
}

input[type="radio"]:disabled ~ .radio-label {
    opacity: 0.5;
}
input[type="radio"]:disabled ~ .radio-input {
    opacity: 0.5;
}


i {
    &.checkbox {
        width: rem-calc(16);
        height: rem-calc(16);
        background-color: transparent;
        border: 1px solid $medium-gray;
        border-radius: $input-radius;
        transition: $input-transition;
        position: relative;

        @include breakpoint(large up) {
            width: rem-calc(14);
            height: rem-calc(14);
        }

        &::after {
            position: absolute;
            content: '';
            display: block;
            top: rem-calc(2);
            left: rem-calc(2);
            width: rem-calc(10);
            height: rem-calc(10);
            background: url('../images/conferma.svg') no-repeat;
            background-size: rem-calc(10);
            background-position: center center;
            opacity: 0;
            transform: scale3d(0, 0, 0);
            transition: $input-transition;
            cursor: pointer;

            @include breakpoint(large up) {
                width: rem-calc(10);
                height: rem-calc(10);
                top: rem-calc(1);
                left: 0;
            }
        }

        &--checked {
            &::after {
                opacity: 1;
                transform: scale3d(1, 1, 1);
            }
        }
    }
}

input.newspromotions {
    @include breakpoint (medium down) {
        position: relative;
        left: rem-calc(10);
    }
}

input.newspromotions_targeted {
    @include breakpoint (medium down) {
        position: relative;
        left: rem-calc(10);
    }
}
