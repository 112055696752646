.selectable-swatches {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__item {
        margin-bottom: rem-calc(16);
        position: relative;
    }

    &__label {
        cursor: pointer;
    }

    &__circle {
        display: inline-block;
        vertical-align: middle;
        height: rem-calc(24);
        width: rem-calc(24);
        margin-right: rem-calc(15);
        margin-bottom: rem-calc(24);
        border-radius: 50%;
        position: relative;
        background-image: url('../images/bucketsprites.jpg');


        &.selected::after {
           @include breakpoint (small down) {
                content: '';
                width: rem-calc(25);
                height: rem-calc(25);
                border-radius: 50%;
                transform: scale(1.3);
                display: inherit;
                background-color: transparent;
           }
        }

        input[type="checkbox"]:checked + & {
            &::after {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
                content: '';
                display: block;
                position: absolute;
                top: rem-calc(-4);
                right: rem-calc(-4);
                width: rem-calc(15);
                height: rem-calc(15);
                background-color: $black;
                background-image: url('../images/conferma-white.svg');
                background-size: rem-calc(8);
                background-repeat: no-repeat;
                background-position: center center;
                border: 1px solid $white;
                border-radius: 50%;
                z-index: 1;
                transition: all .2s cubic-bezier(.25,.1,.25,1);
            }
        }

        &.arancione, &.orange {
            background-position: 0 -224px;
        }
        &.violet {
            background-position: -338px -224px;
        }
        &.yellow {
            background-position: -169px -113px;
        }
        &.metallic {
            background-position: -224px -112px;
        }
        &.brown {
            background-position: -224px -59px;
        }
        &.burgundy {
            background-position: -57px -112px;
        }
        &.light_blue {
            background-position: -57px -57px;
        }
        &.green {
            background-position: -338px -168px;
        }
        &.white__avorio_{
            background-position: 0 -57px;
        }
        &.light_grey, &.pale_grey {
            background-position: -57px -169px;
        }
        &.dark_grey{
            background-position: 195px -169px;
        }
        &.black{
            background-position: 195px -225px;
        }
        &.blue{
            background-position: 0 -113px;
        }
        &.grey{
            background-position: 251px -169px;
        }
        &.white{
            background-position: -113px -57px;
        }
        &.nude{
            background-position: 0 0;
        }
        &.brown{
            /* background-position: -139px -57px; */
        }
        &.print{
            background-position: 27px 0;
        }
        &.red{
            background-position: 83px -225px;
        }
        &.multicolor{
            background-position: 139px -169px;
        }
        &.stripes{
            background-position: 83px 0;
        }
        &.floral{
            background-position: 195px -57px;
        }
        &.pink{
            background-position: 83px -57px;
        }
    }

    &__label {
        span:nth-of-type(2) {
            vertical-align: super;
            font-size: rem-calc(12);
        }

        span:nth-of-type(3) {
            color: gray(3);
            font-size: rem-calc(10);
            display: inline-block;
            margin-left: rem-calc(50);
            transform: translateY(rem-calc(-20));
        }
    }
}
/*
$swatch-colors: (
    'black': $black,
    'red': $sales,
    'summer': $summer,
    'dark_grey': $dark-gray
);
*/

// @each $color, $hex in $swatch-colors {
    /* Swatch Colors */
    // .#{$color} .selectable-swatches__circle {
        // background-color: $hex;

        // &::after {
            // background-color: $black;
        // }
    // }
// }
