.theme-condition-giftcard{
    h2 {
        font-size: rem-calc(30);
        padding-bottom: rem-calc(15);
        font-weight: 600;
        margin: 0;
        text-align: left!important;
        width: 100%;
        height: rem-calc(56);
        border-bottom: rem-calc(1) solid $medium-gray;
        padding-left: 11%;
        background-image: url('../images/ico-arrow-left.svg');  
        background-size: rem-calc(20);
        background-position: rem-calc(0 8); 
        background-repeat: no-repeat;
        margin-bottom: rem-calc(58);
        
    }
    .wrapper{
        margin-left: 11%;
        @include breakpoint (small down) {
        margin-left: 0;
        }
        
        h4{
            font-size: rem-calc(18);
            padding-bottom: rem-calc(15);
            font-weight: 600;
            margin: 0;
        }
        p{
            font-size: rem-calc(14);
            padding-bottom: rem-calc(30);
            margin: 0;
        }
        ul{
            &.list{
            margin-left: rem-calc(40);
            }
        }
    }

   
}
