.prefooter-category-thanks{
    position: relative;
    @include breakpoint (medium up){
        padding: rem-calc(0 40);
    }
    .ha-category{
        @include breakpoint (medium up){
            padding-top: rem-calc(73);
            margin-top: 0;
        }
        .cm-arrow-cta-button{
            font-size: rem-calc(16);
            font-family: 'SpeziaExtended';
            font-weight: 600;
            &::after{
                content: url('../images/cta_arrow.svg');
                width: rem-calc(50);
                height: rem-calc(24);
                margin-left: rem-calc(24);
                transition: .25s linear;
                filter: invert(1);
            }
            &:hover{
                &::after{
                    transform: translateX(10px);
                }
            }
        }
    }
    .title-boxes-category{
        font-size: rem-calc(22);
        margin-bottom: rem-calc(40);
    }

    .img-categories-collection{
        max-width: rem-calc(375);
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    .link-center{
        width: 100%;
        position: absolute;
        top: 50%;
        margin-top: rem-calc(-12);
        display: block;
        text-align: center;
        font-size: rem-calc(25);
        color: $white;
        font-weight: 600;
        letter-spacing: rem-calc(1.5);
    }

    .link-text-category{
        position: relative;
    }

}