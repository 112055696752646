@include breakpoint(medium down) {
    .first-for-medium-down {
        order: -1;
    }
}
.page[data-action='Home-Show'], .page[data-action='Search-Show'] {
    .main-header {
        .grid-container {
            @include breakpoint (medium down) {
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
            }
        }
    }
}

.main-header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;
    z-index: 1000;

    nav {
        background: white !important;
        width: 100%;
        .top-row {
            flex-flow: row;
            padding: rem-calc(10 0);
            align-items: center;
            @include breakpoint(medium down) {
                align-items: center;
            }
            .logo-width {
                width: fit-content;
                margin: auto;
            }
            .logo {
                width: rem-calc(216);
            }
        }

        @include breakpoint(medium down) {
            border-bottom: rem-calc(1) solid #ECECEC;
        }
    }

    & .dynamic-arrow {
        vertical-align: middle;
    }
}

.site-search form {
    display: flex;
    align-items: center;
    
    @include breakpoint(medium down) {
        display: block;
        top: rem-calc(0);
        padding-left: rem-calc(16);
    }
}

.main-header {
    &-search {
        display: inline-block;
        margin-right: rem-calc(8);
        @include breakpoint(large up) {
            margin-right: rem-calc(24);
            margin-top: rem-calc(-5);
            display: flex;
            align-items: center;
            width: rem-calc(126);
        }
    }

    &-login {
        display: inline-block;
        @include breakpoint(medium down) {
            display: flex;
            align-items: center;
            margin-right: rem-calc(16);
            &__dropdown {
                &-content {
                    display: none;
                }
            }
        }

        &.first-level {
            margin-right: rem-calc(0);
            &.wishlist-notification {
                margin-top: rem-calc(0);
            }
        }

        @include breakpoint(large up) {
            margin-right: rem-calc(24);
            position: relative;
            &.first-level {
                margin-top: rem-calc(0);
            }
            &__dropdown {
                &-content {
                    top: rem-calc(-1);
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
                    background: rgba(255, 255, 255, 0.75);
                    backdrop-filter: blur(80px);
                    visibility: hidden;
                    opacity: 0;
                    width: rem-calc(420);
                    position: absolute;
                    z-index: 999;
                    border: rem-calc(1) solid #F2EDED;
                    margin-top: rem-calc(48);
                    margin-left: rem-calc(-300);
                    box-shadow: 0 rem-calc(2) rem-calc(6) rgba($black, 0.1);
                    transition: all .4s ease;
                    right: rem-calc(-145);

                    .lover-color {
                        color: $loyalty;
                    }

                    &.loyalty{
                        width: rem-calc(320);
                        right: rem-calc(-70);
                        top: rem-calc(46);
                        .text-info-score{
                            font-size: rem-calc(13);
                        }
                    }

                    .profile-grey-header{
                        background-color: #F2EDED;
                        padding: rem-calc(17.5 40);
                        align-items: center;
                        a{
                            text-transform: uppercase;
                            font-family: $secondary-font;
                            font-size: rem-calc(12);
                            line-height: rem-calc(20);
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            img{
                                max-width: rem-calc(15);
                                display: inline-block;
                                margin-left: rem-calc(10);
                            }
                            &.logout{
                                font-size: rem-calc(14);
                                text-transform: none;
                            }
                        }
                    }
                    .loyalty-box{
                        &.not-logged-in a,
                        &.not-logged-in svg,
                        &.not-logged-in img{
                          opacity: 0.5;
                        }
                        ul{
                            width: 100%;
                            li{
                                align-items: center;
                                display: flex;
                                padding: rem-calc(18 0 18 43);
                                border-bottom: 1px solid #ffffff;
                                width: 100%;
                                text-align: left;
                                position: relative;
                                &:last-child{
                                    border-bottom: 0;
                                }
                                .pl-2{
                                    padding-left: rem-calc(27);
                                    a{
                                        display: block;
                                    }
                                    .line-divider{
                                        color: grey;
                                        padding: rem-calc(0 5);
                                    }
                                    .text-loyalty{
                                        font-weight: 600;
                                    }
                                }
                                /*
                                span.regular-font{
                                    font-size: rem-calc(14);
                                    font-weight: 400;
                                }
                                */
                                /*
                                a.width-100{
                                    width: 100%;
                                    font-weight: 400 !important;
                                    margin-bottom: rem-calc(3);
                                    // &.mb-mini{
                                    //     padding-bottom: rem-calc(5);
                                    // }
                                }*/
                                .calzedonia-lover-link{
                                    font-size: rem-calc(12);
                                    line-height: rem-calc(18);
                                    display: inherit;
                                    text-transform: none;
                                    opacity: 1;
                                    margin-bottom: rem-calc(5);
                                    a{
                                        display: block;
                                        margin-top: rem-calc(2);
                                        font-weight: 600 !important;
                                    }
                                }
                                img, svg{
                                    width: rem-calc(18);
                                    max-height: rem-calc(18);
                                    margin: 0;
                                }

                                .cc-notification {
                                    display: block;
                                    width: rem-calc(4);
                                    height: rem-calc(4);
                                    border-radius: 50%;
                                    background: $loyalty;
                                    position: absolute;
                                    top: rem-calc(16);
                                    left: rem-calc(58);
                                }
                            }
                        }
                    }
                }
                &:hover,
                &.hover {
                    > div {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-wishlist {
        @include breakpoint(large up) {
            margin-right: rem-calc(24);
        }
    }
    .service-menu-container {
        @include breakpoint(medium down) {
            width: 25%;
        }
    }

    .navbar-toggler {
        color: currentColor;
        margin-right: rem-calc(17);
        margin-top: rem-calc(6);
    }

    .minicart {
        @include breakpoint(medium down) {
            display: flex;
            align-items: center;
            margin-right: rem-calc(8);
        }
    }
}

.minicart-link {
    @include breakpoint (large up){
        .minicart-link-svg{
            margin-right: 0;
        }
    }
}

.minicart-total .minicart-link .minicart-link-svg, .main-header-wishlist svg, .analytic-header-icon.js-layer-login svg {
    width: rem-calc(24);
    height: rem-calc(24);
}

.search-toggler i, .navbar-toggler i{
    @include breakpoint(small down) {
        display: flex;
        width: rem-calc(24);
        height: rem-calc(24);
        justify-content: center;
    }
}

.search-toggler, .search-toggler * {
    color: black;
}

.minicart-total{
    position: relative;
}

.minicart-quantity,
.wishlist-quantity {
    color: $white;
    background: $black;
    border-radius: rem-calc(12);
    height: rem-calc(12);
    width: rem-calc(12);
    display: inline-block;
    position: absolute;
    text-align: center;
    font-size: rem-calc(8);
    line-height: rem-calc(11.2);
    padding: rem-calc(1);

    @include breakpoint (medium only) {
        line-height: rem-calc(12.6);
    }
}

.wishlist-quantity{
    right: rem-calc(0);
    top: rem-calc(12);
}

.minicart-quantity {
    right: 0;
    top: rem-calc(14);
}

@include breakpoint(medium down) {
    .wishlist-quantity {
        right: 26px !important;
        bottom: 20px !important;
    }
}

@include breakpoint(medium down) {
    .search-toggler {
        order: 1;
        svg{
            color: black;
        }
    }
}

.notFoundPage{
    padding-top: rem-calc(40);
    @include breakpoint (medium down) {
        padding-top: rem-calc(24);
    }
    .page-title-error {
        font-size: rem-calc(40);
        line-height: rem-calc(35);
        margin-bottom: rem-calc(8);
        text-transform: none;
        font-weight: 400;
        @include breakpoint (medium down) {
            font-size: rem-calc(16);
            line-height: rem-calc(26);
            font-weight: 400;
            margin-bottom: rem-calc(13);
        }
    }
    .error-message {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(32);
    }
}

.login-form-error:empty{
    margin: 0;
}

.social-login-form-error{
    margin: rem-calc(24 0 2);
    &, &-mail{
        background: #F5F5F5;
        padding: rem-calc(8 24 8 8);
        color: #262626;
        font-size: rem-calc(14);
        line-height: rem-calc(20);
    }
}
.gigyaLoginCell{
    .login-register-gigya{
        font-size: rem-calc(16);
        line-height: rem-calc(22);
        margin: rem-calc(40 0 16 0);
        text-align: center;
        @include breakpoint (medium down){
            margin-top: 24px;
        }
    }
    .social-login-account-accept{
        &, a{
            color: #646464;
            font-weight: 400;
        }
        font-size: rem-calc(10);
        line-height: rem-calc(18);
        margin: rem-calc(24 0 16 0);
    }
}
.socialLoginContainer {
    margin: 0 auto;
    tr{
        background-color: transparent !important;
        &:hover{
            background-color: transparent !important;
        }
    }
    table[align="center"],
    td[align="center"]{
        width: 100%;
    }
    td[align="right"],
    td[align="left"]{
        display: none;
    }
}

.layer-password-modal{
    .tingle-modal-box{
        @include breakpoint (medium down){
            max-width: 100vw;
            max-height: 100vh;
            width: 100%;
            height: 100%;
        }
    }
    .tingle-modal-box__content{
        text-align: center;
        padding: rem-calc(40);
        @include breakpoint(medium down) {
            padding: rem-calc(25 16);
        }
        .modal-content{
            width: 100%;
            position: unset;
            background-color: transparent;
            text-align: left;
        }
        .modal-title{
            font-family: $secondary-font;
            font-size: rem-calc(16);
            font-weight: 600;
            line-height: rem-calc(22.41);
            margin-bottom: rem-calc(8);
            text-transform: uppercase;

            @include breakpoint (medium down){
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                margin-bottom: rem-calc(16);
            }
            &.social-request-password-title{
                @include breakpoint(medium down) {
                    padding: rem-calc(0 40);
                }
            }
        }
        .modal-description{
            font-size: rem-calc(14);
            line-height: rem-calc(20);
            margin-bottom: rem-calc(28);
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(40);
            }
        }
        #reset-password-email{
            margin-bottom: rem-calc(24);
        }
        .close{
            z-index: 99999;
            top: rem-calc(40);
            right: rem-calc(40);
            font-size: unset;
            @include breakpoint(medium down) {
                top: rem-calc(25);
                right: rem-calc(16);
            }
        }
        .ico-center-send-mail{
            margin-bottom: rem-calc(8);
            svg{
                height: rem-calc(20);
                width: rem-calc(27);
            }
        }
    }
}

.info-rememberme.for-modal{
    background-color: #E6E6E6!important;
    max-width: rem-calc(280);
    color: black!important;
    filter: drop-shadow(1px 2px 3px #ccc);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    @include breakpoint (medium up){
        left: rem-calc(20)!important;
    }
    @include breakpoint (medium down){
        left: rem-calc(-102)!important;
        width: rem-calc(230)!important;
    }
    .title-persistent-login{
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        margin-bottom: rem-calc(9);
    }
    .subtitle-persistent-login{
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        margin-bottom: 0;
    }
    .close-button-info-tooltip{
        position: absolute;
        right: rem-calc(12);
        cursor: pointer;
        filter: invert(1);
    }
    .cookie-policy-tooltip{
        font-size: rem-calc(12);
        text-decoration: underline;
        margin-left: rem-calc(3);
    }
}

.button-login{
    margin-bottom: rem-calc(24);
}

.password-reset.for-modal{
    display: flex;
    margin-top: rem-calc(16);
}
.layer-login-modal, .login-form-container {
    .remember-me-container {
        position: relative;
        display: flex;
        .popover-remember-me {
            &.tooltip{
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                padding: rem-calc(16);
            }
            position: absolute;
            top: 0;
            transform: translate(-8px, calc(-100% - 16px));
            background: $white;
            color: $black;
            transition: opacity .25s ease;
            border: 1px solid $black;
            border-radius: rem-calc(5);
            left: 0;
            width: rem-calc(280);
            .tooltip-title{
                font-size: rem-calc(14);
                line-height: rem-calc(18);
                margin-bottom: rem-calc(9);
            }
            .tooltip-subtitle{
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                margin-bottom: 0;
            }
            .cookie-policy-tooltip{
                font-size: rem-calc(12);
                font-weight: 800;
                text-decoration: underline;
                margin-left: rem-calc(3);
              }
        }
        .popover-remember-me.for-modal{
            left: 0;
            width: rem-calc(280);
            @include breakpoint (medium down){
                left: 0;
                width: rem-calc(253);
            }
        }
        .info-icon {
            margin-left: rem-calc(12);
            display: flex;
            align-items: center;
            margin-top: rem-calc(-10);

            @include breakpoint (medium down){
                margin-top: 0;
            }

            &.no-margin-top {
                margin-top: 0;
            }

            .icon {
                line-height: rem-calc(15);
            }
            .tooltip{
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                padding: rem-calc(16);
                .title-persistent-login{
                    font-size: rem-calc(14);
                    line-height: rem-calc(18);
                    margin-bottom: rem-calc(9);
                }
                .subtitle-persistent-login{
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    margin-bottom: 0;
                }
                .close-button-info-tooltip{
                    position: absolute;
                    right: rem-calc(12);
                    cursor: pointer;
                    filter: invert(1);
                  }
                  .cookie-policy-tooltip{
                    font-size: rem-calc(12);
                    text-decoration: underline;
                    margin-left: rem-calc(3);
                  }
            }
            .icon-info-black{
                line-height: rem-calc(15);
            }
        }
        .checkbox-label{
            font-size: rem-calc(12);
            line-height: rem-calc(16);
            font-weight: 400;
            position: relative;
            top: rem-calc(1);
            padding-left: rem-calc(8);
            cursor: unset;
        }
    }
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-flex;
        width: rem-calc(48);
        height: rem-calc(23);
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: map-get($brand-palette, light-gray);
        transition: .4s;
    }

    .slider:before {
        top: rem-calc(4);
        left: rem-calc(4);
        position: absolute;
        content: "";
        height: rem-calc(16);
        width: rem-calc(16);
        background-color: $white;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: black;
    }

    input:checked + .slider:before {
        transform: translateX(rem-calc(18));
        background-color: $white;
        left: rem-calc(9);

        @include breakpoint (medium down) {
            transform: translateX(rem-calc(35));
        }
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: rem-calc(16);
        width: rem-calc(48);
        height: rem-calc(24);

        @include breakpoint (medium down) {
            border-radius: rem-calc(20);
            width: rem-calc(80);
            height: rem-calc(40);
        }
    }

    .slider.round:before {
        border-radius: 50%;

        @include breakpoint (medium down) {
            width: rem-calc(30);
            height: rem-calc(30);
            top: rem-calc(5);
            left: rem-calc(5);
        }
    }
}

.search-icon {
    margin-right: rem-calc(12);
}

.main-header-login__dropdown{
    width: rem-calc(24);
    height: rem-calc(24);
    display: flex;
    align-items: center;
    position: relative;

    .cc-notification {
        display: block;
        width: rem-calc(6);
        height: rem-calc(6);
        border-radius: 50%;
        background: $loyalty;
        position: absolute;
        top: rem-calc(2);
        left: rem-calc(13);
    }
}

.main-header-login-logged-icon {
    background-color: $black;
    border-radius: rem-calc(18);
    color: $white;
    font-size: rem-calc(10);
    font-weight: 600;
    height: rem-calc(18);
    line-height: rem-calc(12);
    padding: rem-calc(3 4 4);
    text-align: center;
    text-transform: uppercase;
    width: rem-calc(18);
    position: relative;
    @include breakpoint (medium down) {
        margin-top: rem-calc(0);
        line-height: rem-calc(0);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem-calc(4);
    }

    @include breakpoint (medium only) {
        padding: rem-calc(5);
    }
}
.dc-conversion-text{
    padding: rem-calc(40);
    background: #F8F8F8;
    margin: 0;
    margin-top: rem-calc(40);
    font-weight: 400;
    font-size: rem-calc(20);
    line-height: rem-calc(26);
    margin-bottom: rem-calc(-48);
    @include breakpoint (medium down){
        padding: rem-calc(40 16);
    }
}

.top-header-placement{
    display: none;
}
.page[data-action="Home-Show"],.page[data-action='Search-Show']{
    .top-header-placement{
        display: block;
        z-index: 20;
    }
}
img[src*="realtimeanalytics.yext.com"] {
    position: absolute;
  }

.text-center {
text-align: -webkit-center;
}

.home-link {
display: table-row;
}

.discount-percentage {
    color: #FD2969;
}

.strike-through.list {
    .value {
        color: $light-gray;
    }
}

.productItem-plpOnly {
    @include breakpoint (small down) {
        .price {
            span.value {
                font-size: rem-calc(14);
            }
            span.value {
                margin: rem-calc(0 2);
            }
        }
    }
}

.privacyFlagEnable {
    .tingle-modal-box {
        @include breakpoint (small down) {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            height: -webkit-fill-available !important;
            max-height: unset;
            max-width: unset;
            width: 100vw;
            overflow: hidden !important;
        }
        @include breakpoint (medium up) {
            min-height: rem-calc(298);
            width: rem-calc(520);
        }

        .tingle-modal-box__content {
            padding: rem-calc(40);

            @include breakpoint (small down) {
                padding: rem-calc(24 16 16 16);
                height: 100vh;
            }

            .closePrivacyPopUP {
                position: absolute;
                top: rem-calc(44);
                right: rem-calc(44);
                @include breakpoint (small down) {
                    top: rem-calc(28);
                    right: rem-calc(20);
                }
            }

            .title {
                font-size: rem-calc(16);
                font-weight: 600;
                line-height: rem-calc(22);
                margin-bottom: rem-calc(16);
                max-width: rem-calc(279);
                text-transform: uppercase;

                @include breakpoint (medium up) {
                    max-width: rem-calc(350);
                }
            }

            .privacyFlag-popup-text {
                font-size: rem-calc(14);
                font-weight: 400;
                line-height: rem-calc(20);
                @include breakpoint (large up) {
                    max-width: rem-calc(376);
                }
            }

            .boolean-field {
                input[type="checkbox"] {
                    height: rem-calc(20);
                    width: rem-calc(50);
                    z-index: 1;

                    @include breakpoint (medium up) {
                        left: unset;
                        right: 0;
                    }
                }
            }

            .sliderToggle {
                @include breakpoint (medium up) {
                    left: unset;
                }

                @include breakpoint (small down) {
                    left: rem-calc(8);
                }
            }
        }

        .buttons-modifyConsent {
            bottom: rem-calc(40);
            position: absolute;
            width: rem-calc(438);

            @include breakpoint (small down) {
                margin-top: rem-calc(56);
                position: unset;
                width: 100%;
                position: relative;
                z-index: 2;
                button{
                    margin-right: 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    position: fixed;
                    bottom: 18px;
                    left: 16px;
                    right: 16px;
                    width: 91.8%;
                }
            }
        }

        .buttons-preference-form {
            bottom: rem-calc(40);
            position: absolute;
            width: rem-calc(438);

            @include breakpoint (small down) {
                margin-top: rem-calc(14);
                position: relative;
                z-index: 2;
                width: 100%;
            }
        }

        .buttons-continueConsent {
            bottom: rem-calc(40);
            position: absolute;
            width: rem-calc(438);

            @include breakpoint (small down) {
                margin-top: rem-calc(56);
                position: relative;
                z-index: 2;
                width: 100%;
            }
        }

        .editPrivacyPolicyForm {
            margin-top: rem-calc(16);

            .newsletter-pref {
                margin-top: rem-calc(35);
                @include breakpoint (small down) {
                    margin-top: rem-calc(32);
                }
            }

            .profiling-pref {
                margin-top: rem-calc(16);
                @include breakpoint (small down) {
                    margin-top: rem-calc(10);
                }

                .preferences-text {
                    @include breakpoint (medium up) {
                        margin-bottom: rem-calc(96);
                    }
                }
            }

            .preferences-text {
                font-size: rem-calc(12);
                font-weight: 400;
                line-height: rem-calc(18);

                &.disabled {
                    color: $light-gray;
                }

                .privacy-policy-short-profiling,
                .privacy-policy-short-marketing {
                    font-weight: 400;
                    text-decoration: underline;
                    text-transform: uppercase;
                }
            }
        }

        .privacy-response-ok {
            .privacyFlag-popup-text {
                font-size: rem-calc(16);
                font-weight: 600;
                line-height: rem-calc(22);
                margin-bottom: rem-calc(16);
                text-transform: uppercase;
                @include breakpoint (small down){
                    position: relative;
                    z-index: 2;
                }
            }
            .success {
                color: $success-notification;
                font-size: rem-calc(12);
                font-weight: 400;
                line-height: rem-calc(18);

                i {
                    margin-right: rem-calc(12);
                }
            }
        }
        .button{
            &.activable-after-change{
              width: 100%;
              @include breakpoint (small down){
                position: fixed;
                bottom: rem-calc(16);
                width: 91.8% !important;
                left: 16px;
                right: 16px;
                margin-right: 0;
              }
            }
        }
    }

    .continue-consent{
        width: 100%;
          @include breakpoint (small down){
            position: fixed;
            bottom: rem-calc(18);
            width: 91.8% !important;
            left: 16px;
            right: 16px;
            margin-right: 0;
          }
      }

    .buttons-preference-form{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

    .buttons-preference-form,
  .buttons-modify-consent,
  .buttons-continueConsent{
      @include breakpoint (small down){
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          z-index: 2;
      }
  }

    .privacy-popup-shadow{
        @include breakpoint (small down){
            display: flex;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-pack: center;
            justify-content: center;
            position: fixed;
            bottom: 0px;
            left: 0;
            background: #fff;
            width: 100%;
            height: rem-calc(75);
            z-index: 1;
        }
    }
}

.send-email-btn {
    @include breakpoint (medium down) {
        position: absolute;
        bottom: rem-calc(24);
        left: rem-calc(16);
        right: rem-calc(16);
    }
}