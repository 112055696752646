body.suggestions-is-open {
    form[name="simpleSearch"] {
        position: relative;

        @include breakpoint(small down) {
            background: $more-light-gray;
        }

        @include breakpoint(medium up) {
            position: relative;

            .search-field {
                background-color: transparent;
                border: none;
                padding-top: rem-calc(8) !important;
                .input-label {
                    text-transform: lowercase;
                }
            }

            .search-close {
                display: block;
                cursor: pointer;
            }
        }
    }

    form[name="simpleSearch"]/*,
    .site-search__icon*/ {
        z-index: 4;
        width: rem-calc(318);
        padding-bottom: rem-calc(7);
        align-items: baseline;
        border-bottom: solid rem-calc(1) $black;
    }
}

.search-is-open {
    @include breakpoint(medium down) {
        position: fixed;
        .main-header {
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: stretch;

            .header-search {
                flex: 1;

                .site-search,
                .suggestions-wrapper,
                .suggestions {
                    height: 100%;
                    background-color: transparent;
                }
            }
            form[name="simpleSearch"] {
                display: flex;
                z-index: 4;
                width: calc(100% - 32px);
                padding-bottom: rem-calc(7);
                align-items: baseline;
                padding-left: 0;
                margin: rem-calc(0 16);
                border-bottom: solid rem-calc(1) $black;
            }
            /*
            .search-toggler {
                color: $primary;
            }
            */
        }
    }
}

.mobile-search {
    @include breakpoint(medium down) {
        width: 100%;
    }
}


.site-search {
    @include breakpoint(medium up) {
        display: flex !important;
        align-items: center;
        &:not(.add-fixed-position) {
            top: rem-calc(4);
        }
    }
    position: relative;
    display: inline-block;
    z-index: 2;

    .search-icon {
        position: relative;
        top: rem-calc(-4);
    }
    input[type='search'] {
        height: 100%;
        margin-bottom: 0;
        border: none;
        padding: rem-calc(2);
        cursor: pointer;

        @include breakpoint(medium down) {
            padding: rem-calc(0);
            max-width: 100%;
        }

        &::placeholder {
            color: #646482;
        }

        // Hide WebKit cancel search button
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }

        @include breakpoint(large up) {
            &:focus {
                border: none;
                outline: none; // TODO check accessibility issue

                &+.search-icon {
                    color: $primary;
                }
            }
        }
        &.cc-search-padding {
            padding: 0;
        }
    }

    .search-close {
        top: rem-calc(-32);
        right: 0;
        position: absolute;
        font-size: rem-calc(29);
        width: rem-calc(28);
        text-align: right;
        @include breakpoint (large up) {
            top: rem-calc(32);
            right: rem-calc(35);
        }

        @include breakpoint(medium up) {
            position: fixed;
            display: none;
        }
    }

    &.add-fixed-position {
        top: rem-calc(96);
        position: fixed;
        right: rem-calc(40);

        @include breakpoint(medium down) {
            right: 0;
            top: rem-calc(50);
            position: fixed;
        }
        .label-inside {
            width: 100%;
        }
        .search-field {
            .label-inside.focused .input-label {
                text-transform: lowercase;
            }
        }
    }
}

@include breakpoint(medium down) {
    .header-search {
        .site-search {
            display: none;
            input {
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                font-weight: $global-weight-normal;
                margin: 0;
                width: 100%;
                @include breakpoint (medium down) {
                    width: 92%;
                    background-color: transparent;
                }
            }
        }
    }
}

.suggestions-wrapper {
    position: relative;
    z-index: 1;
    background-color: $white;

    @include breakpoint(large up) {
        position: fixed;
        top: 0; // adjust via JS based on header
        right: 0;
        bottom: 0;
        box-shadow: 0 rem-calc(1) rem-calc(2) rgba($black, 0.12);
        display: none;
        width: rem-calc(398);
        height: 100%;
    }
}

.suggestions-curtain {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
    pointer-events: none;
    @include breakpoint (medium down) {
        background-color: $white;
    }

    body.suggestions-is-open & {
        @include breakpoint(medium up) {
            display: block;
            pointer-events: all;
        }
    }

    body.search-is-open & {
        @include breakpoint(small down) {
            display: block;
            pointer-events: all;

        }
    }
}

.suggestions {
    display: block;
    background-color: $white;
    z-index: 3;
    overflow: scroll;

    margin-top: rem-calc(176);
    @include breakpoint(medium down) {
        margin-top: 0;
    }

    &::-webkit-scrollbar {
        width: rem-calc(5);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $medium-gray;
        border-radius: rem-calc(5/2);
    }

    @include breakpoint(large up) {
        background-color: transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        overflow: auto;
    }

    a {
        font-size: rem-calc(16);
        font-weight: $global-weight-normal;
        line-height: $global-lineheight;

        @include breakpoint(medium down) {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
        }
    }

    .view-all {
        font-size: rem-calc(16);
        text-underline-offset: rem-calc(2);
        display: inline-block;
        text-transform: none;
        font-weight: $global-weight-dark;
        @include breakpoint (medium down) {
            padding-right: rem-calc(16);
            font-size: rem-calc(14);
            line-height: rem-calc(20);
        }
    }
    /*
    .header {
        font-size: rem-calc(12);
        color: gray(3);
        font-weight: $global-weight-bold;
        padding: rem-calc(16 0 8 0);
        text-transform: uppercase;
        border-top: 1px solid $border-gray;

        @include breakpoint(medium down) {
            font-size: rem-calc(12);
            color: gray(3);
            font-weight: $global-weight-dark;
            text-transform: uppercase;
        }

        &:first-child {
            @include breakpoint(medium down) {
                border-top: 0;
            }
        }
    }
    */

    .terms-container {
        padding-bottom: rem-calc(34);

        @include breakpoint(medium down) {
            padding-bottom: rem-calc(38);
        }
        .term {
            padding-bottom: rem-calc(10);

            a {
                font-size: rem-calc(16);
                font-weight: $global-weight-normal;
                line-height: $global-lineheight;
                @include breakpoint(medium down) {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                }
            }
        }
    }

    .items {
        padding-bottom: rem-calc(24);
        display: flex;
        flex-direction: column;
        gap: rem-calc(8);

        @include breakpoint(medium down) {
            gap: rem-calc(10);
        }

        .auto {
            margin-right: 0;
        }
    }

    .item {
        // Distribute 10px margin
        &:not(:first-child) {
            margin-top: rem-calc(5);
        }

        &:not(:last-child) {
            margin-bottom: rem-calc(5);
        }

        img {
            position: relative;
            top: initial;
            right: initial;
            transform: initial;
            width: rem-calc(45);
            height: auto;
        }

        .name {
            text-overflow: ellipsis;
            // white-space: nowrap;
        }

        // Item types
        &--product {
            .name {
                margin: 0;
                padding-left: rem-calc(24);
                padding-top: rem-calc(20);
            }
        }
    }
    /*
    .category-parent {
        color: $dark-gray;
        font-size: 0.875em;
    }
    */
}

/*
.more-below {
    background: $dark-gray;
    border-radius: 1.25em;
    bottom: 1.875em;
    display: none;
    height: 2.5em;
    position: fixed;
    right: 1.875em;
    width: 2.5em;

    i.fa-long-arrow-down {
        border-radius: 50%;
        color: $white;
        display: table-caption;
        height: 0.75em;
        font-size: 1.5rem;
        left: 0.57em;
        line-height: 0.8em;
        position: absolute;
        top: 0.4em;
        width: 0.8em;
    }
}
*/

.newHeader {
    &.firstHeaderMobile{
        border: 0;
        @include breakpoint (medium down) {
            padding-top: rem-calc(48);
        }
    }
    font-family: $secondary-font;
    padding-bottom: rem-calc(20);
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    font-weight: 600;
    text-transform: uppercase;
    align-items: baseline;
    @include breakpoint (medium down) {
        font-size: rem-calc(12);
        line-height: rem-calc(16);
    }
}

/*
.newHeader .firstHeaderMobile {
    @include breakpoint(medium down) {
        border-top: 1px solid white;
    }
}
*/
/*
.top {
    top: rem-calc(6);
}
*/
.search-padding {
    padding-left: rem-calc(40);
    padding-right: rem-calc(40);
    @include breakpoint (medium down) {
        padding-left: rem-calc(16);
        padding-right: rem-calc(16);
    }
}
.searchForm {
    .label-inside.focused {
        .input-label{
            display: none!important;
        }
    }
    .label-inside .input-label {
        padding-top: 0;
        margin-top: rem-calc(8);
    }
}
.not-found{
    padding: rem-calc(0 0 20 40);
    font-weight: 600;
    @include breakpoint(medium down){
        padding: rem-calc(48 0 20 16);
        font-size: rem-calc(12);
    }
    &-text {
        padding: rem-calc(0 24 44 40);
        font-size: rem-calc(16);
        font-weight: $global-weight-normal;
        line-height: $global-lineheight;
        @include breakpoint(medium down){
            padding: rem-calc(0 16 48 16);
            font-size: rem-calc(14);
            line-height: rem-calc(20);
        }
    }
}

.page-home-gender, .search-is-open {
    .main-header {
        .header-search:not(.headerSearchWorld) {
            @include breakpoint (medium down) {
                position: fixed !important;
                top: rem-calc(50) !important;
            }
        }
    }
}