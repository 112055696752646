.calzedonialover-use-your-coupon{
    .text-loyalty{
        color: #8d9cd0 !important;
    }
    .title{
        margin: rem-calc(50 0);
        @include breakpoint(small down){
            margin: rem-calc(25 0);
        }
    }
    .box-background{
        background-color: #F9F9F9;
        padding: rem-calc(40 70);
        font-size: rem-calc(16);
        margin-bottom: rem-calc(30);
        .line-height-fix,
        li{
            line-height: rem-calc(22);
            margin-bottom: rem-calc(13);
            font-size: rem-calc(14);
            &:last-child{
                margin-bottom: 0;
            }
        }
        @include breakpoint(small down){
            padding: rem-calc(40 30);
            margin-bottom: rem-calc(20);
            li{
                margin-bottom: rem-calc(20);
            }
        }
    }
    .legal-notes,
    .stop-communications-text{
        color: #999999;
        font-size: rem-calc(12);
        margin-bottom: rem-calc(30);
        .text-dark-grey{
            color: #666;
        }
    }
    .stop-communications-text{
        padding-bottom: rem-calc(40);
        border-bottom: 1px solid #CCCCCC;
        @include breakpoint(small down){
            margin-top: rem-calc(20);
        }
        .or-margin{
            margin: rem-calc(17 0 23);
            display: block;
        }
        .call-number,
        .or-margin,
        .send-email-btn{
            @include breakpoint(small down){
                width: 100%;
                text-align: center;
                display: block;
            }
        }
    }
    .cta-margin{
        margin-bottom: rem-calc(30);
        @include breakpoint(small down){
            margin-bottom: rem-calc(20);
        }
    }
}